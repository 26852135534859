.main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 20px 7vh 0px 7vh;
  width: 70%;
}

.welcome-container {
  height: 9rem;
  background-color: #daeeff;
  width: 100%;
  margin: 0px 0px 14px 0px;
  padding: 20px 0px 11px 30px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.use-cases-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 7rem;
  background-color: #daeeff;
  width: 100%;
  border-radius: 5px;
  margin: 16px 0px 20px 0px;
  padding: 19px 10vh 20px 10vh;
  position: relative;
}

.use-case {
  height: 5rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0px 15px 0px 15px;
  padding: 10px 35px 10px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20rem;
  &.selected {
    background-color: #1c629e;
    color: #fff;
  }
}

.arrow-usecase-btn {
  height: 57px;
  width: 57px;
  border-radius: 28px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #daeeff;
  position: absolute;
  &.left {
    left: -30px;
  }
  &.right {
    right: -30px;
  }
}
.arrow-usecase-btn:hover {
  box-shadow: 1px 2px 2px #888888;
}

.template-list {
  display: flex;
  flex-direction: row;
}

.template {
  display: flex;
  flex-direction: column;
  width: 18rem;
  background-color: #daeeff;
  border-radius: 10px;
  padding: 16px 20px 16px 20px;
  margin: 0px 30px 0px 0px;
}

.template-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

@media screen and (max-width: 768px) {
  .template-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
