.weather_icon {
  width: 2.5rem;
  height: 2.5rem;
}
.weatherdata-container {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  gap: 5px;
  background: linear-gradient(180.17deg, #1939ab 0.15%, #568fc0 99.23%);
  padding: 0.625rem;
  border-radius: 1rem;
  overflow-y: auto;
}
.weatherdata-container::-webkit-scrollbar {
  display: none;
}
.inv-status-weather {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border-radius: 0.125rem;
}
.inv-status-weather-paper {
  width: 80% !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.weather-day {
  display: flex;
  gap: 0.3125rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.center_div-nogap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.weather-grid {
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  background: #ffffff33;
  border: 0.0625rem solid #ffffff33;
  padding: 0.625rem;
  justify-content: space-around;
}
.weather-paper {
  background: transparent !important;
  display: flex;
  box-shadow: none !important;
  align-items: center;
  justify-content: space-between;
}
.weather-title {
  margin-top: 1.25rem;
  background: transparent;
  border: none !important;
}
.weather-title-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  gap: 0.625rem;
}
.weather-data-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff33 !important;
  border-radius: 0.635rem;
  height: 9rem;
}
.grid-paper-weather {
  width: 100% !important;
  gap: 0.3125rem;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0.9rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.space-btn-gap {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: space-between;
}
.weather-cloud{
  width: 7rem;
}

.minMaxStyle {
  background-color: #f1f1f7;
  border-left: 0.15rem solid #00529a;
  padding: 1rem;
  border-radius: 0.8rem;
}
.center_minMax_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 0.7rem !important;
  border: 0.0625rem solid #d8d8d8;
  height: 100%;
  position: relative;
}
.center_minMax_paper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding: 2vh;
  border-radius: 0.7rem !important;

  background-color: transparent !important;
  box-shadow: none !important;
}

.weatherHead {
  font-family: "Raleway";
  color: #00529a;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}
//EnergyData.scss
.energy_data_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
// OperationalEfficiency.scss 
.operational-efficiency-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-start_div{
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.chart-container {
  margin-bottom: 15px;
}

.metrics {
  display: flex;
  justify-content: space-between;
}

.metric {
  display: flex;
  align-items: baseline;
}

.color-box {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.orange {
  background-color: #FDA737;
}

.blue {
  background-color: #00529A;
}

.green {
  background-color: #AFD926;
}
.custom-tooltip-chart .apexcharts-tooltip-title {
  display: none !important;
}
@media screen and (max-width: 899px) {
  .plant-info {
    padding: 0.5rem 3rem !important;
  }
}
@media screen and (max-width: 992px) {
  .font_14px {
    font-size: 14px !important;
  }
  .font_14px{
    font-size: 14px !important;
  }
  .font_8px{
    font-size: 8px !important;
  }

.energy_data_container {
  margin-top: 0.8rem !important;
}
#search-input_smb {
  padding: 0.5rem !important;
}
.search-box_smb{
  width: auto !important;
}
.weatherdata-container {
  display: none !important;
}
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .Poppins_16px_rem{
    font-size: 0.6rem;
  }
  .Poppins_16px_white{
    font-size: 0.6rem;

  }
  .raleway_16px_rem {
    font-size: 0.7rem;
  }
  .Poppins_32px_rem {
    font-size: 1rem;
  }
  // .Poppins_20px_rem {
  //   font-size: 0.8rem;
  // }
  // .weather_icon {
  //   width: 2rem !important;
  //   height: 2rem !important;
  // }
  .Poppins_12px_rem {
    font-size: 0.5rem;
  }
 
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .Poppins_16px_rem, .Poppins_16px_white,
  .raleway_16px_rem {
    font-size: 0.7rem;
  }
  .Poppins_32px_rem {
    font-size: 1.4rem;
  }
  #abt_value {
    font-size: 0.8rem;
  }
  // .weather_icon {
  //   width: 2rem !important;
  //   height: 2rem !important;
  // }
  .weather-title-grid{
    gap: 0.5rem !important;
  }
 
}


//prpgresrect class
.progress_battey {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0f62fe;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none;
}
.battery_front{
  background-color: #FDA737;
  height: 2.5rem;
  width: 1rem;
  border-radius: 0.425rem;
}
.battery_front_g{
background: linear-gradient(90deg, #58AC34 0%, #333333 100%);

  height: 4.5rem;
  width: 0.8rem;
  border-radius: 0.425rem;
}
.progress_battey_g {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none;
}