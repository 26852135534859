#exc-linegraph .Dropdown-control {
  border-radius: 1.2vw !important;
  border: 2px solid #6e8192 !important;
  padding: 0.37vw 1.46vw 0.37vw 0.52vw !important;
  cursor: pointer !important;
}
#exc-linegraph-no-padding .css-13cymwt-control{
  border-radius: 1.2vw !important;
  border: 1px solid #6e8192 !important;
  padding: 0rem !important;
  cursor: pointer !important;
  min-height: fit-content !important;
}
#exc-linegraph .Dropdown-arrow {
  border-color: #6e8192 transparent transparent !important;
  top: 50% !important;
}

#exc-linegraph .css-1xc3v61-indicatorContainer {
  color: #6e8192 !important;
}
#exc-linegraph .css-3w2yfm-ValueContainer{
  height: 5vh !important;
  overflow: auto !important;
    width: 14vw !important;
}
#exc-linegraph .Dropdown-arrow:hover{
  color:black !important;
}
/* #unixChart .apexcharts-xaxis-texts-g {
  transform: translate(41px, -7px);
} */
