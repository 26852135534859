.case-container{
    display: flex;
    gap: 3vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 21vh;
}
.vectorAnime-two {
    position: absolute;
    right: 7vw;
    top: 0;
    height: 47vh;
}
.draw-line{
    top: 16vh;
    right: 4vw;
    width: 46vw; 
}
.three-section{
    padding: 2.5vw;
}
.each-section{
    margin-top: 10vh;
}
.casestudy-grid-two{
    padding: 0vw 2.4vw;
    margin-bottom: 9vh;
}
.cards-subcontainer{
    display: flex;
    flex-direction: column;
    gap: 4vw;
    margin-bottom: 8vh;
}
.case-img{
    border-radius: 3vw;
}
.casecard-body{
    padding: 2vw;
    gap: 2vw;
    border-radius: 2vw 2vw 14vw 2vw;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center; 
}
@media screen and (max-width: 992px) {
    #card-body{
        flex-direction: column-reverse !important;
    }
    #percent-width-align{
        text-align: left !important;
        width: 97% !important;
    }
    #percent-width{
        width: 97% !important;
    }
    .casecard-one, .casecard-two, .casecard-three{
        width: 100% !important;
    }
    .casecard-body{
        flex-direction: column !important;
        border-radius: 26px !important;
    }
}
