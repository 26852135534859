.weather-day-month {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.0625rem solid #d8d8d8;
  padding: 0.8rem;
  border-radius: 0.2rem;
  width: fit-content;
}
.smb-one-div {
  border-radius: 0.625rem !important; // Apply custom border-radius
  padding: 1rem;
  border: 0.0625rem solid #d8d8d8;
  box-shadow: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1vw;
}
.smb-paper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: transparent;
  box-shadow: none !important;
  height: 100%;
  border-radius: 0.75rem !important;
  justify-content: space-between;
}
.smb_grid_sub {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0.0625rem solid #d8d8d8;
  background-color: white;
  height: 100%;
  border-radius: 0.75rem;
  padding: 2rem !important;

}
.smb_paper_sub {
  width: 100% !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0.75rem !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.smb-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fffbe6;
  border: 0.0625rem solid #d8d8d8;
  gap: 0.625rem;
 height: 100%;
 width: 100%;
 padding: 1rem;
  border-radius: 0.75rem !important;
}
.smb-paper_two {
  width: 100% !important;
  padding: 1rem !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.search-box_smb {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#search-input_smb {
  background-color: transparent !important;
  width: 100% !important ;
  border: 0.0625rem solid #d8d8d8 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 2.3rem !important;
}
.grid-container-smb{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  gap: 1rem;
}

.smb-names_div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  border-radius: 1rem;
  padding: 0.5rem;
}

