.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .main-container {
    display: flex;
    align-items: center;
  }
  
  .left-container {
    flex: 1;
    margin-left: 9.5rem;
  }
  
  .right-container {
    flex: 1;
    padding: 20px 30px;
  }
  .forgotPasswordText {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 37px;
  }
  .paragraph {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .backToLogin {
    margin-top: 30px;
  }
  .signUptoplogo {
    position: absolute;
    margin-right: 65rem;
    margin-bottom: 32rem;
  }
  .pass_logo{
    width: 400px;
  }
 
  @media screen and (max-width: 991px){
    .formContainer{
      display: block !important;
    }
    .signUpsmartlogo {
      margin-bottom: auto !important;
      position: relative !important;
      margin-right: auto !important;
  }
  .main-container {
    flex-direction: column;
  }
  .left-container {
    margin-left: 0px !important;
    margin-top: 82px !important;
  }
  .pass_logo{
    width:283px !important;
  }
  .verify-img{
    width: 265px !important;
  }
  }