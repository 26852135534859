.button-container {
    display: flex;
    padding: 12px 12px 12px 12px;
    background-color: #1C629E;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: 15rem;
}
