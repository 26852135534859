.dashboard-main {
  font-family: "Poppins";
  width: 100vw;
  height: 100vh;
  overflow-y: auto;

}
.boxconatiner {
  width: 250px;
  height: 250px;
  border-radius: 8px;
  border: 1.5px solid #1c629e;
  padding: 45px 30px;
}

.boxconatiner:hover {
    width: 250px;
    height: 250px;
    border-radius: 8px;
    border: 1.5px solid #1c629e;
    padding: 45px 30px;
    background-color: #1c629e;
  }
.imagesize {
  color: black;

  width: 150px;
  height: 150px;
}
.button-container {
  display: flex;
  padding: 15px;
  background-color: #1c629e;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 30rem;
}
.smartmainlogo {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}