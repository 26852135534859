.file-upload {
  cursor: pointer !important;
  font-family: "Poppins" !important;
  background-color: #f4f4f4 !important;
  border-radius: 0.5rem !important;
  padding: 1rem 2rem !important;
  text-transform: capitalize !important;
  font-size: 0.75rem !important;
  box-shadow: none !important;
  width: fit-content !important;
  font-weight: 700 !important;
  color: black !important;
}
.file-upload-w {
  gap: 0.5rem !important;
  cursor: pointer !important;
  font-family: "Poppins" !important;
  background-color: white !important;
  border-radius: 0.5rem !important;
  padding: 1rem 2rem !important;
  text-transform: capitalize !important;
  font-size: 0.85rem !important;
  box-shadow: none !important;
  width: fit-content !important;
  font-weight: 500 !important;
  color: black !important;
}

.file_div {
  min-height: 70vh;
  height: 100%;
}
.pdfurl {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
}
#justify-start {
  justify-content: flex-start !important;
}
.pdfurl::-webkit-scrollbar {
  display: none !important;
}
#flex_row {
  flex-direction: row !important;
}
.react-pdf__Page__canvas {
  max-width: 100% !important;
}

//////
.height_80 {
  height: 80vh !important;
}
.svg_upload {
  padding: 2rem 4rem;
  background-color: white;
  border-radius: 0.7rem;
}
.svg_upload_name {
  border: 1px solid var(--strokeColor-lightGrey, #e7e7e7);
}
#padding_4 {
  padding: 4rem 0rem !important;
}
.btn_next {
  justify-content: flex-end !important;
  padding: 1rem 3rem 1rem 0rem;
}
.width_pdflist {
  width: 75%;
  overflow-x: auto;
  justify-content: flex-start !important;
}
.width_pdflist::-webkit-scrollbar {
  display: none !important;
}
.width_pdfdata {
  white-space: nowrap;
  cursor: pointer;
  padding: 1rem !important;
}
.hei_auto {
  height: auto !important;
}
.width_pdfdata::-webkit-scrollbar {
  display: none !important;
}
#flex_row {
  flex-direction: row !important;
}

.div_listing {
  padding: 0.4rem 0.5rem;
}
.l_border {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.r_border {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
/* Add this CSS to your stylesheet */
.pagination-button {
  border: none;
  padding: 0.4rem 0.6rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
  background-color: transparent;
}

.pagination-button.active {
  color: #00529a;
  font-weight: 600;
  background-color: #f4f4f4;
}
.table-scrollbar-pdf {
  border: none !important;
  border-radius: 0.75rem !important;
  box-shadow: none !important;
}
.scroll_pdf {
  max-height: 75vh !important;
  overflow-y: auto;
}
.scroll_pdf::-webkit-scrollbar {
  display: none !important;
}
.table-scrollbar-pdf .css-1a5x853-MuiTableCell-root {
  background-color: transparent !important;
}

.table_icpon {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}
#border-b {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
#file-b .css-2z1l6h-MuiTableCell-root{
  padding: 15px 4px !important;
}


#file-b .css-kcyrr4 {
  padding: 15px 4px !important;
}
.table-scrollbar-pdf .css-ophefc {
  background-color: transparent !important;
}

#file_drops .css-1u9des2-indicatorSeparator{
  display: none !important;
}
#file_drops{

 .css-t3ipsp-control,.css-tj5bde-Svg,.Dropdown-control, .css-13cymwt-control,
.css-t3ipsp-control:hover {
  fill:black !important;
  border-color: #A6C5FA !important;
  box-shadow: none !important;
  border-radius: 0.7vw !important;
  background-color:  #A6C5FA !important;

  border: 1px solid #A6C5FA !important;
}

}


#border-b .css-2z1l6h-MuiTableCell-root {
  border-bottom: none !important;
}
.grid_view {
  background-color: white;
  padding: 1rem;
}
.customPopoverPaper-p {
  border-radius: 0.625rem !important; // Apply custom border-radius
  box-shadow: none !important;
  padding: 0.625rem 0rem !important;
  gap: 0.625rem;
}
.file_bg {
  padding: 1rem;
  background-color: #f0f0f3;
  box-shadow: 0.89px 0.89px 5.09px 0px #00000008 inset;
  border-radius: 0.4rem;
  height: 10rem;
  box-shadow: -0.89px -0.89px 5.09px 0px #00000008 inset;
}
.view_more {
  color: #0b57d0;
}
.img-check{
  padding: 14px 5px !important;
}
.file_uploading{
  right: 0;
    position: absolute;
    width: 50% !important;
    bottom: 1%;
    z-index: 1;
}
.down_hor_i{
  padding: 0.1rem 0.6rem;
  border-radius: 0.625rem;
  background-color: white;
  width: fit-content;
}
.down_hor_is{
  padding: 0.6rem 1rem;
  border-radius: 1.7rem;
  background-color: white;
  width: 22vw;
}
.search_case_pdf {
  padding: 1.1rem 2rem;
  background: #fda737 !important;
}
.padding_top {
  padding: 1rem 0rem !important;
}
#search_pad {
  padding: 0rem !important;
  border: none !important;
  border-radius: 0.629rem !important;
  width: 80% !important;
}
#search_pad #search-input {
  padding: 1rem 0rem 1rem 1rem !important;
}
.file-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
}
#scrollname {
  width: 17vw;
  overflow-x: scroll;
  white-space: nowrap;
  cursor: pointer;
}

#scrollname::-webkit-scrollbar {
  display: none !important;
}
.search_pdf_div {
  border: 1px solid #f2f3f4;
  box-shadow: 0px 1px 1px 1px #00529a;
  border-radius: 1.25rem;
  padding: 0.625rem;
}

.search-div-pdfchat {
  align-items: center;
  justify-content: space-around;
  display: flex;
  gap: 1.5rem;
  width: 80%;
}
///image upload
.image_btn{
  background-color: white !important;
  border-radius: 0.5rem !important;
  padding: 1rem !important;
  text-transform: capitalize !important;
  font-size: 0.85rem !important;
  width: fit-content !important;
  font-weight: 500 !important;
  color: black !important;
  box-shadow: 0px 0px 12px 0px #00529A !important;

}

.img-thumb{
  padding: 0%;

}
.folder_one {

box-shadow: 0px -5.5px 7.33px -1.66px #0000000A;

backdrop-filter: blur(5.500000953674316px);

  width: 100%;
  padding: 5px;
  text-align: center;
}
.folder_tab, .folder {

  margin: 0 auto;
  background: #E5E4EA;

}
.folder_tab {
  height: 2vh;
  margin-right: 50%;
  border-radius: 5px 15px 0 0;
}
.folder {
  height: 15vh;
  display: flex;
  align-items: flex-end;
  border-radius: 0 5px 5px 5px;
}

.fol_div{

  background: #f4f4f4;
padding: 1rem;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 8px 0px #00000040;

}
.img_70{
  width: 70%;
}
.fol_c{
  background: #FDA737;
  border-radius: 0.125rem;
  padding: 0.3rem;
  width: fit-content;

}
.pop_name{
  position: absolute;
  top: 50%;
}
@media screen and (max-width: 900px) {

  #padding_4{
    padding: 0rem !important;
  }
  .display_none{
    display: none;
  }
  .file-upload-w {
    font-size: 16px !important;
    border-radius: 22px !important;
    padding: 10px 30px !important;
  }
  .react-pdf__Page__canvas{
    height:auto !important;

  }
  .down_hor_is{
    width:auto !important;

  }
  .image_btn{
    font-size: 16px !important;
    border-radius: 22px !important;
    padding: 10px 16px !important;
  }
  .img_70{
    width: 100% !important;
  }
  #drop_img{
    justify-content: space-between !important;
    align-items: flex-start !important;
    width: 100%;
  }
  #main_drop_div{
    align-items: stretch !important;
    height: 100%;

  }
}