  //static code css
  .inverter_container{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .dotted-line-container {
    width: 100%;
    overflow: hidden;
  }
  
  .dotted-line {
    width: 200%; /* Double the width to ensure no gap */
    height: 2px;
    background: transparent;
    border-bottom: 2px dotted black;
    animation: move 4s linear infinite; /* Adjust duration as needed */
    animation-delay: -4s; /* Negative delay to start immediately after previous animation */
  }

  .react-flow__node-input.selectable.selected{
    box-shadow: 0 0 0 0.5px transparent !important;
  }
  .react-flow__node-default.selectable.selected, .react-flow__node-default.selectable:focus{
    box-shadow: 0 0 0 0.5px transparent !important;
  }
  .react-flow__panel.right{
    display: none !important;
  }
 .layout_tooltip{
  display: flex;
  align-items: center;
  justify-content: flex-start;
 }
 .plant_view_tooltip{
    display: flex;
    flex-direction: column;
    gap:0.7rem;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #F4F4F4 !important;
    border: 1px solid #2F67F8 !important;
 }
 .layout_tooltip p {
  margin-left: 0.3125rem;
}
.status_container{
  display: flex;
  gap:1rem;
  align-items: center;
  justify-content: center;
}
.inv_status_color {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}