* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  height: 100vh;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 0;
  align-items: center;
  padding: 0;
}
.signUpsmartlogo {
  margin-bottom: 515px;
  position: absolute;
  margin-right: 864px;
}

.login-form {
  width: 50%;
  justify-content: center;
  display: flex;
}
.formcontainer {
  width: 23rem;
  padding: 20px 0px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d5e2ee;
  box-shadow: 0px 4px 13px rgba(136, 136, 136, 0.25);
  border-radius: 10px;
}

.background {
  flex: 1;
  background-color: #1c629e;
}
.up-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
}
.firstname-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  width: 131px;
  height: 45.32px;
  background: #ffffff;
  border-radius: 8.46505px;
  border: none;
  padding: 20px 20px;
}
.input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Poppins";
  align-items: center;
  padding: 10.1581px 175.226px 10.1581px 20.3161px;
  width: 277.54px;
  height: 45.32px;
  padding: 20px 20px;
  border: none;
  background: #ffffff;
  border-radius: 8.46505px;
}
.forgot-pass {
  width: 121px;
  height: 19px;
  margin-left: 195px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.6976px;
  line-height: 19px;
  color: #000000;
  opacity: 0.3;
}
.signup-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.46505px 16.9301px;
  width: 277px;
  height: 45.32px;
  margin-top: 6px;
  background: #1c629e;
  border-radius: 8.46505px;
  border: none;
  cursor: pointer;
}

.btn-text {
  height: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.9301px;
  line-height: 25px;
  color: #ffffff;
}
.login-optionOne {
  width: auto;
  text-align: center;
  height: 19px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.6976px;
  line-height: 19px;
  color: #000000;
  margin-top: -0.5rem;
}
#privacy_sign{
  height: auto !important;
  width: 277.54px !important;
  text-align: left;
  margin-top: 0px !important;


}
.register-new {
  height: 19px;
  width: auto;
  text-align: center;
  padding-bottom: 10px;
  margin-top: 9px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.6976px;
  line-height: 19px;
  color: #000000;
}
.register-link {
  color: #1c629e;
  text-decoration: none;
}
.sec-title {
  width: 50%;
  margin-top: 6.42%;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding-top: 27px;
}
.sub-title {
  margin-top: -0.26%;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 133.52%;
  padding: 32px 144px;
  color: rgba(255, 255, 255, 0.75);
}
.img1 {
  height: 208px;
}
.half-screen {
  width: 50%;
  height: 100vh;
  padding: 37px;
  background-color: #1c629e;
}
.block {
  margin: 0;
  padding: 0;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 46px;
}

.error {
  border-color: red;
}

.signup-form-container{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.first-last {
  display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    justify-content: center;
}

.lastname-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Poppins";
  align-items: center;
  width: 131px;
  height: 45.32px;
  margin-left: 15px;
  padding: 20px 20px;
  border: none;
  background: #ffffff;
  border-radius: 8.46505px;
}

.first {
  display: block;
}
.last {
  display: block;
}

//privacy policy
.privacydata{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 3rem 5rem;
  gap: 2rem;

}
.privacy_text{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  text-align: justify;
  flex-direction: column;
}
.list_italic li{
font-family: "Raleway";
font-weight: 400;
font-size: 1.25rem;
color:  #000000;

margin-left: 1rem;


}
.pri_para{
  margin-bottom: 1vh;
}
#font_italic li{
  font-style: italic;

}

#privacybanner{
  top:50% !important;
  
}

.mailinfo{
color: #2f67f8;
}