.profile-container {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  overflow-y: auto;
}
.top-bar-profile {
  width: 100%;
  max-width: 100%;
  height: 167px;
  background-color: #bac1f4;
display: flex;  
    padding: 1.5rem 2rem;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
}
.horizontal-line {
  border: none;
  height: 0.6px;
  background-color: #000000;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  width: 13rem;
}
.username-container {
  display: table-column-group !important;
  flex-direction: column;
  color: #000000;
  margin: 0.625rem;
}
.username {
  font-weight: 700;
  font-family: "poppins";
  font-size: 16px;
  text-transform: capitalize;
}
.css-omnl36 {
  height: 40px !important;
}
.item-container-profile {
  margin: 0.625rem;
  max-width: 100%;
  width: 82%;
  display:table-column-group !important;
  border-radius: 0.625rem;
  margin-top: -39px;
  height: fit-content;
  box-shadow: 0px 4px 0.625rem 0px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  padding: 1.5rem;
}
.horizontal-line2 {
  height: 0.6px;
  border:0.0625rem solid #bac1f4;
  margin: 0.625rem 0rem;
}

.icon-images{
  height: 1.125rem;
  width: 1.125rem;

}

.subscription-container {
  
  width: inherit;
  
}
.subscribe-now {
  width: 229px;
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 4px 0.625rem 0px rgba(0, 0, 0, 0.25);
  display: flex;
  margin-left:0.625rem;
  margin-top: -39px;
    flex-direction: column;
    justify-content: space-between;
}

.transaction-history {
  width: auto;
  overflow: auto;
  margin-right: 0.625rem;
  margin-top: 9px;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.plant-container {
  width: 100%;
}
.details-conatiner {
  margin-left: 0.625rem;
  max-width: 100%;
  width: 92%;
  border-radius: 0.625rem;
  margin-right: 0.625rem;
  margin-top: -39px;
  box-shadow: 0px 4px 0.625rem 0px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
  padding: 1.5rem;
  background-color: #ffffff;
}
.plant-location {
  max-width: 100%;
  width: 92%;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 4px 0.625rem 0px rgba(0, 0, 0, 0.25);
  margin-left: 0.625rem;
  margin-top: 12px;
  padding: 17px;
  margin-bottom: 12px;
}
.help-support {
  margin-left: 28px;
  max-width: 100%;
  width: 94%;
  border-radius: 0.625rem;
  margin-right: 28px;
  margin-top: -39px;
  height: fit-content;
  box-shadow: 0px 4px 0.625rem 0px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  padding: 1.5rem;
}
.help-container {
 width: 100%;
}
.padding_pro{
padding: 0.5rem 0rem;
}

.help_div{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.contact-team {
  margin-top: 12px !important;
  margin-bottom: 17px;
}

.email-link:hover {
  text-decoration: underline; 
  cursor: pointer;
}

//
.flex-start_profile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0rem;
  margin-left: -0.5rem;
}
.account_overview {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.logout-btn {
  background-color: #FFFFFF !important;
  color: #000000 !important;
  border-radius: 0.5rem !important;
  text-transform: none !important;
}
.user-plan-details{
  position: relative;
  height: 85vh;
  max-height: 100%;
  display: flex;
  justify-content: space-between;
  width: 16.125rem !important;
  margin: -2.5rem 0.625rem  1.25rem !important;
  z-index: 2;
}
.user-main-container{
  display: flex;
}
.btn-list{
  max-height:  2.375rem !important;
  margin: 0.375rem 0rem !important;
  border-radius: 0.625rem !important;
}.center_div_profile{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem;
}
.gap-manager{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding:0.8rem 0rem !important;
  box-shadow:none !important;
  width: 100% !important;
  gap: 7rem;
}
.subscription-block{
display: flex;
width: 100%;
}
.contact-email{
  display: flex;
  gap:2rem;
}

@media screen and (max-width: 1054px) {
.subscription-block{
  flex-direction: column;
 

}
.icon-images{
  height: 18px;
  width: 18px;
}
.subscribe-now{
  margin: 0.625rem;
}
.user-plan-details{
  height: auto !important;
  width: auto !important;
}
.user-main-container{
  flex-direction: column;
}
.item-container-profile {
  width: auto !important;
  margin-top: auto;
  margin-bottom: 15px;
  height: auto !important;
}
.username-container {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
}
.horizontal-line {
  display: none;
}
.subscription-container {
  margin-top: auto !important;
  width: auto;
}
 .details-conatiner, .help-support {
  margin-top: auto !important;
}
#help-id{
  margin-top: 1rem !important;

}
.btn-list{
  height: auto !important;
}
.help-support, .contact-team{
  width: auto !important;
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}
.transaction-history {
  width: auto;
}
}
@media screen and (max-width: 500px){
  .user-variable{
    display: none;
  }
  .contact-email{
    flex-direction: column;
  }
  .gap-manager{
    gap:0.625rem !important;
  }
}