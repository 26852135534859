.powerdata-box {
  background-color: #f1f1f7;
  border-left: 0.23vw solid #00529a;
  padding: 0.5vw;
  border-radius: 0.9vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.tab_panel {
  width: 100%;
  padding: 0.5rem 0rem 0rem 0rem !important;
}
.btnSlides{
  padding: 1rem !important;
  background-color: white !important;
  border: 0.0625rem solid #D8D8D8 !important;
  border-radius: 0.75rem !important;
  width: 100%;
  
}
.height-energy {
  height: 7rem !important;
}
.MuiTabs-indicator {
  height: 5px !important;
  border-radius: 10px !important;
}
.tracker_status_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17rem;
}
.motor_status_container {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 17rem;
  position: relative;
}
.tracker_status_img {
  width: 12.5rem;
}

.motor_status_img {
  width: 8rem;
  position: absolute;
}
.timestamp_input_container {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
}
.emoji_button {
  border-radius: 1.25rem;
  padding: 0.5rem 1.4rem;
  display: flex;
  align-self: flex-end;
}

.tracker_control_container {
  background-image: url("https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_control_img.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 8rem;
  flex-wrap: wrap;
  align-items: center;
  padding: 4rem;
  justify-content: flex-start;
}

.circle {
  position: relative;
  width: 13rem;
  height: 13rem;
  border-radius: 50%;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sun-angle {
  text-align: center;
  box-shadow: 0rem 3.43px 17.15px 0rem #fda737;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.degrees .degree {
  position: absolute;
  font-size: 1em;
  color: white;
}

.degree.top {
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

.degree.right {
  right: -3rem;
  top: 50%;
  transform: translateY(-50%);
}

.degree.bottom {
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

.degree.left {
  left: -4rem;
  top: 50%;
  transform: translateY(-50%);
}
.control_center {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 1.25rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 60%;
}
.control_buttons {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5019607843);
  border: 1px solid rgba(255, 255, 255, 0.2509803922);
  border-radius: 1.1875rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.control-btn-img {
  background-color: rgba(255, 255, 255, 0.2);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.control_img {
  width: 2rem;
}
.control_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 74%;
  height: 100%;
}
.save_button {
  padding: 0.8rem 1.2rem;
  background-color: #2f67f8;
  border-radius: 0.625rem;
  margin-top: 1rem;
}
//old code scss SolarTracker.scss
.cubeone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  gap: 10px;
  border-radius: 3.125rem 3.125rem 0rem 0rem;
  background-color: transparent;
  padding: 2rem 2rem 0rem 2rem;
}
.MuiTabs-scroller,
.MuiTabs-fixed,
.css-jpln7h-MuiTabs-scroller {
  overflow: auto !important;
}
.tracker-one-div {
  border-radius: 0.625rem !important;
  padding: 1rem;
  border: 0.0625rem solid #d8d8d8;
  box-shadow: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 6rem;
}
.tracker-one-div_padding {
  padding: 1rem 2rem !important;
}

.trackerPading {
  padding-top: 0rem !important;
}
.trackers_box {
  border-top: 1vh solid #f4f4f4 !important;
  background-color: white !important;
  position: sticky !important;
  // top: 9vh !important;
  z-index: 1 !important;
}
.scrolling-tracker {
  padding-bottom: 2rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  border: none;
  flex-direction: column;
  gap: 0.625rem;
  max-height: 100%;
  overflow: auto;
}
.scrolling-tracker::-webkit-scrollbar {
  display: none !important;
}
.tracker-container {
  width: 100% !important;
  background-color: transparent !important; /* 'bgcolor' should be 'background-color' */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0rem !important;
  gap: 2rem;
}
.checklist_grid_sub {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  padding: 1rem !important;
  border-radius: 1.25rem !important;
  border: 0.0625rem solid #d8d8d8 !important;
}
.tracker_weather_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 0.7rem;
  right: 0;
  position: fixed !important;
  z-index: 1;
}
.checklist_paper_sub {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 1.25rem !important;
  gap: 1vh;
  background-color: transparent;
  box-shadow: none !important;
}
.request_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.tracker_time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}
.updated_time_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
}
.tracker_paper_container {
  background-color: white !important;
  width: 100% !important; /* Note the space removed in '!important' */
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: flex-start;
  box-shadow: none !important;
  border: 0.0625rem solid #c3bbbb;
  border-radius: 3.125rem 3.125rem 0.75rem 0.75rem !important;
}
.location_paper {
  background-color: transparent !important;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: none !important;
}
.timestamp {
  display: flex;
  flex-direction: column !important;
  width: 60%;
  gap: 0.625rem;
  align-items: flex-start;
}
.abt-grid-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  gap: 0.4rem;
}
.checklist_grid {
  width: 100% !important;
  background-color: white !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0rem !important;
  gap: 1rem;
  padding: 1rem !important;
  border-radius: 1.25rem !important;
}

.textfield_tracker {
  width: 100% !important;
  border: 0.0625rem solid #d8d8d8 !important;
  border-radius: 0.625rem !important;
  background-color: transparent !important;
}

.textfield_tracker .MuiInputBase-root {
  padding-right: 0 !important;
}

.cube_row {
  display: flex;
  flex-direction: row !important;
  gap: 0.625rem;
}
.stop_tracker {
  color: red;
  position: absolute;
  top: 0% !important;
  margin-top: 0.3rem !important;
}
.a-center_j-flex-start {
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
}
.a-center_j-flex-end {
  display: flex;
  align-items: center !important;
  justify-content: flex-end !important;
}
.arrow-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5625rem;
  padding: 0.28125rem 0.84375rem;
}
//trackers setting-tab
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  width: auto !important;
}
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: black !important;
}

//CircularSlider css
.circular_slider {
  z-index: 1;
}
#slider-value {
  color: black;
  position: absolute;
  top: 0;
  margin-top: 42%;
  margin-left: 33%;
}
.custom-circular-slider svg {
  width: 80px !important;
  height: 80px !important;
}

@media screen and (max-width: 992px) {
  .tracker_paper_container {
    border-radius: 1rem !important;
  }
  .cubeone {
    padding: 1rem 1rem 0rem 1rem !important;
  }
  .cubeone .raleway_24px_rem {
    font-size: 1rem !important;
  }
  .tracker-container {
    gap: 0.5rem !important;
  }
  // .tracker_control_container{
  //   height: auto !important;
  //   padding: 1rem !important;
  //   gap: 3rem !important;
  // }
  .control_container {
    display: grid !important;
    grid-template-columns: repeat(2, 2fr) !important;
  }
  .control_center {
    width: auto !important;
  }
}

@media screen and (max-width: 1200px) {
  .tracker_weather_grid {
    position: relative !important;
  }
}
@media screen and (max-width: 1340px) {
  .tracker_control_container {
    height: auto !important;
    padding: 1rem !important;
    gap: 3rem !important;
  }
}
