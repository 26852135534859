.icon-box {
  font-size: 20px;
  margin-right: 12px;
  color: #000000;
  display: flex;
}

.custom-drawer {
  .css-h4y409-MuiList-root {
    padding-top: 0px;
  }
  .css-10hburv-MuiTypography-root {
    font-family: "Poppins-SemiBold";
    font-size: 16px;
  }
  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background-color: #1c629e;
    color: #fff;
  }
  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
    display: flex;
    padding: 8px;
  }
}

.sidebar-divider {
  margin: 30px 10px 0px 0px !important;
}

.sidebar-selected {
  background-color: #1c629e !important;
}

.logout {
  display: flex;
  flex: auto;
  align-items: end;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
}
.logout p{
  margin-left:10px;
}
.logout:hover{
   background-color: #b5c2ce;
}

.maintoolBar {
  display: flex;
  justify-content: space-between;
}
.imgClass {
  padding-top: 10px;
}
.userDetails {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  background-color: #daeeff;
  height: 80px;
  width: 252px;
  padding: 10px 0px;
  font-size: 15px;
  font-family: "Poppins";
  position: absolute;
  left: 80%;
  text-align: center;
  z-index: 2;
}
.email{
  font-size: 13px;
}