.form-report .Dropdown-control,
.css-13cymwt-control {
  border: 1px solid #646464 !important;
  border-radius: 0.7vw !important;
  font-size: 1rem;
  font-family: "poppins-Regular";
  font-weight: 400;
  cursor: pointer !important;
}
.form-report .css-1dyz3mf{
  height: 5vh !important;
  overflow: auto !important;
  width: 14vw !important;

}
.form-report .css-3w2yfm-ValueContainer{
  height: 5vh !important;
  overflow: auto !important;
    width: 14vw !important;
    text-transform: uppercase !important;
}
.form-report .css-t3ipsp-control,
.css-t3ipsp-control:hover {
  border-color: #646464 !important;
  box-shadow: none !important;
  border-radius: 0.7vw !important;

  border: 2px solid #646464 !important;


}

.form-report .Dropdown-arrow {
  border-color: #D8D8D8 transparent transparent !important;
  top: 50% !important;
}

.form-report .css-1xc3v61-indicatorContainer {
  color: #D8D8D8 !important;
}

.css-1p3m7a8-multiValue {
  background-color: #F4F4F4 !important;

}
