.device-monitoring-inverter {
    width: 100vw;
    height: 95vh;
    padding: 10px;
    overflow-y: auto;
    background-color: #bed0e08c;
  
  
  }
  
  .device-monitoring-inverter::-webkit-scrollbar {
    display: none !important;
  }
  
  .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    color: white !important;
    background-color: #8b85857a !important;
  }
  
  .css-d9c359-MuiButtonBase-root-MuiToggleButton-root {
    padding: 0px 14px !important;
  }
  
  .list-selected {
    padding: 0px 10px !important;
    color: white !important;
    background-color: #8b85857a !important;
  }
  
  .css-ahj2mt-MuiTypography-root {
    margin-left: 0px !important;
    color: gray;
  }
 
  
  .headerCell-class {
    background-color: #8b85857a !important;
    border: 2px solid white;
    padding: 4px 8px !important;
  }
  
  .headerCell-class1 {
    padding: 4px 8px !important;
  
    border: none !important;
  }
  
  .yield-energy-container {
    display: flex;
    gap: 7px;
  }
  
  .yield-subcontainer {
    display: flex;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    background-color: white;
  
  }
  
  .weather-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .yield-divider {
    display: flex;
  }
  
  .inverter-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 12px;
    overflow: auto;
  }
  
  .panel-img {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 0px 12px;
  }
  
  .divider {
    display: flex;
    align-items: center;
  }
  
  .CircularProgressbar .CircularProgressbar-trail {
    stroke-linecap: butt !important;
  }
  
  .CircularProgressbar .CircularProgressbar-path {
    stroke-linecap: butt !important;
  }
  
  .semicircle-scale {
    transform: scale(1.3) !important;
  }
  .cum-energy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 12px;
  }
  .sub-energy {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  
  //WeatherDashboard style starts
  .orange-circle {
    // width: 6vw;
    // height: 6vw;
    border-radius: 5%;
    // background-color: orange;
    display: flex;
    flex-direction: column;
    gap:5px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .green_orange-circle {
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(119, 237, 105, 1) 39%, rgba(245, 150, 26, 1) 83%);
  }
  
  .dot-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
  }
  
  .forecast-container {
    border: 1px solid gray;
    // background-color: aliceblue;
    border-radius: 5px;
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
     background: linear-gradient(180deg, #111C43 0%, #00529A 77.96%);
  }
  
  .double-circle {
    width: 9vw;
    /* Adjust the size as needed */
    height: 9vw;
    /* Adjust the size as needed */
    position: relative;
  }
  
  .outer-circle,
  .inner-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }
  
  .outer-circle {
    background-color: #c2bcbc;
    border: 1px solid #7a7676;
  }
  
  .inner-circle {
    background-color: #fff;
    /* Color of the inner circle */
    border: 1px solid #7a7676;
    ;
    width: calc(100% - 20px);
    /* Adjust the gap size */
    height: calc(100% - 20px);
    /* Adjust the gap size */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .semi-gauge-chart {
    display: inline-block;
    margin: 20px;
  }
  
  /* RectangleComponent.css */
  
  .rectangle-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rectangle {
    position: relative;
    width: 10vw;
    height: 24vh;
    border-top: none;
    border-left: 3px solid #39a7bd;
    border-right: 3px solid #39a7bd;
    border-bottom: 3px solid #39a7bd;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  .center-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    border: 2px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .small-rectangle {
    position: relative;
    width: 7vw;
    height: 22vh;
    border-top: none;
    border-left: 3px solid #39a7bd;
    border-right: 3px solid #39a7bd;
    border-bottom: 3px solid #39a7bd;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  
  .small-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    border: 2px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .circle-progress {
    width: 10vw;
    height: 10vw;
  }
  
  .orange-fill {
    width: 6.5vw;
    height: 8vh;
    background-color: orange;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-bottom: 0.17vh;
  }
  
  @media screen and (min-width: 1900px) {
    .barometer{
      width: 300px !important;
      height: 200px !important
    }
  }
  @media screen and (max-width: 1348px) {
    .min-value{
      margin-right: -31px !important;
    }
  }
  @media screen and (max-width: 899px) {
    .forecast-paper {
      height: auto !important;
    }
  }
  @media screen and (min-width: 900px) and (max-width:1199px) {
    .forecast-paper {
      height: 365px !important;
    }
  }
  
  @media screen and (max-width: 992px) {
    .low-high {
      flex-direction: column !important;
    }
  
    .yield-subcontainer {
      flex-direction: column;
    }
  
    .yield-energy-container {
      flex-direction: column;
    }
  
    .divider {
      display: none;
    }
  
    .fault-probability {
      font-size: 65px !important;
    }
    .plant-info{
      align-items: center !important;
    }
    .yield-divider {
      align-items: center !important;
      justify-content: center !important;
    }
    .plant-description{
      text-align: center !important;;
    }
    .plant-list-container{
      flex-direction: column-reverse !important;
      gap: 5px !important;
    }
  }
  
  @media screen and (min-width: 375px) and (max-width: 390px) {
    .sort-by {
      padding-left: 17px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .semicircle-scale {
      transform: scale(1) !important;
    }
  
  }
  
  @media screen and (min-width: 1188px) and (max-width: 1625px) {
    // .inv-insight-one {
    //   max-width: 19.99% !important;
    // }
  
    // .inv-insight-two {
    //   max-width: 29.99% !important;
    // }
  
    // .inv-insight-three {
    //   max-width: 53.99% !important;
    // }
  
    // .semicircle-scale {
    //   transform: scale(1) !important;
    // }
  }
  
  
  //media query for weather dashboard
  @media screen and (min-width: 900px) and (max-width: 1200px) {
    .uv-index {
      display: grid !important;
      grid-template-columns: repeat(2, 2fr) !important;
    }
  }
  
  @media screen and (max-width: 1076px) {
    .weather-container{
      flex-direction: column !important;;
    }
  }
  
  @media screen and (max-width: 1200px) {
   
    .green_orange-circle {
      width: 150px;
      height: 150px;
    }
  
    // .orange-circle {
    //   width: 120px;
    //   height: 120px;
    // }
  
    .double-circle {
      width: 150px !important;
      height: 150px !important;
    }
  
    .circle-progress {
      width: 120px;
      height: 120px;
    }
  
    #font-15px {
      font-size: 15px !important;
    }
  
    .rectangle {
      width: 150px;
      height: 160px;
    }
  
    .center-circle {
      width: 70px;
      height: 70px;
    }
  
    .small-rectangle {
      width: 120px;
      height: 130px;
    }
  
    .small-circle {
      width: 80px;
      height: 80px;
    }
  
    .rainfall-container {
      display: grid !important;
      grid-template-columns: repeat(2, 2fr) !important;
    }
  
    .orange-fill {
      width: 118px;
      height: 59px;
    }
  }
  
  @media screen and (max-width: 491px) {
    .temp-grid {
      height: auto !important;
      ;
    }
  
    .temp-container {
      flex-direction: column !important;
      align-items: center;
      gap: 20px;
    }
  }
  
  @media screen and (max-width: 471px) {
    .forecast-blocks {
      flex-direction: column !important;
    }
  
    .min-value {
      margin-right: -47px !important;
    }
  }
  
  @media screen and (max-width: 450px) {
    .wind-gust {
      display: grid !important;
      grid-template-columns: repeat(2, 2fr) !important;
    }
  
    .uv-index {
      display: grid !important;
      grid-template-columns: repeat(2, 2fr) !important;
    }
  }