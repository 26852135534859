.icon_container{
    border-radius: 0.9375rem;
    border: 1px solid #D8D8D8;
    width: 3.27rem;
    padding: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.key_value_container{
    gap:1.5rem;
    border: 1px solid #D8D8D8;
    width: 100% !important;
    padding: 1rem;
    background-color: #ffff !important;
    box-shadow: none !important;
    display: flex;
    height: 7rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 0.625rem !important;
}
.value_container{
    display: flex;
    flex-direction: column;
}
.icon_3rem{
   width: 2.5rem;
}
#blue_value{
    color:#2F67F8;
}
.subtitle_value{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:10px
}
.metric-box{
    display: flex;
    gap:0.5rem;
    align-items: center;
    justify-content: flex-start;
}
.metric-icon {
    width: 2rem;
}

.mfm_grid_main{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0px !important;
    margin-left: 0px !important;
    gap: 0.5rem;
    position: relative;
}
#mfm-grid-one{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    border: none;
    flex-direction: column;
    gap: 0.625rem;
    max-height: 100%;
    overflow: auto;
}
.main_grid_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0px !important;
    margin-left: 0px !important;
    gap: 0.5rem;
}
.heading_grid {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: transparent !important;
}
.table_grid {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;
    padding: 0px !important;
    border-radius: 0.75rem !important;
    background-color: transparent !important;
}
.table_paper{
    width: 100% !important;
    display: flex;
    justify-content: center;
    box-shadow: none !important;
    border-radius: 0.75rem !important;
    border: 1px solid #D8D8D8;
}
.no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
    height: 71%;
}
.mfm_dataSet{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: transparent !important;
    gap: 0.5rem;
}
.four_grids_paper {
    width: 100% !important;
    padding: 1rem;
    background-color: #ffff !important;
    box-shadow: none !important;
    border: 1px solid #D8D8D8;
    border-radius: 1.875rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.four_grids_container {
    width: 100% !important;
    padding: 1rem;
    background-color: #ffff !important;
    box-shadow: none !important;
    border: 1px solid #D8D8D8;
    border-radius: 1.875rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}
.three-grids-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: transparent !important;
    border-radius: 1.875rem;
    width: 100%;
}
.mfm_data_grid {
    position: relative !important;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    background: #ffff !important;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    border-radius: 0.75rem;
    border: 1px solid #D8D8D8;
}
.mfm_grid_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0px !important;
    margin-left: 0px !important;
    gap: 1rem;
}
.mfm_grid_item{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: transparent !important;
    border-radius: 0.625rem;
    width: 100%;
}
.notification_grid {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: none !important;
    border-radius: 1.25rem !important;
    flex-direction: column !important;
    border: 1px solid #D9D9D9;
}
.notification_paper {
    height: 100%;
    padding: 0px !important;
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 1.25rem !important;
}
.imp_notification_block{
padding:1rem !important;
}
.divider_nomargin{
    border: 1px solid #D9D9D9 !important;
    width:100%;
}
.graph_grid {
    position:  relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;
    padding: 0px !important;
    border-radius: 0.625rem !important;
    border: 1px solid #D9D9D9;
    background-color: white !important;
}
.graph_paper {
    width: 100% !important;
    display: flex;
    justify-content: center;
    border-radius: 0.625rem !important;
    box-shadow: none !important;
}
.stickyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 1rem !important;
    right: 0;
    position: sticky;
    top: 0;
    z-index: 1;
}
.fullWidthContainer {
    width: 100% !important;
    background-color: transparent;
    box-shadow: none !important;
    border-radius: 1rem !important;
}
#table_cell_padding{
    padding: 0.4rem !important;
    text-align: center;   
}

.center_div_gap {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 0.3rem;
}
.grid-container-column{
    display: flex !important;
    // flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0rem !important;
    margin-left: 0rem !important;
    gap: 1rem;
}
.grid-container_center{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0rem !important;
    margin-left: 0rem !important;
    gap: 0.7rem;
}

//MFmDetails style...

.mfm-one-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%; 
}
.flex-start-nogap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.location-div{
    border: 1px solid #212121;
    padding: 1rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    align-self: center;
}
.redirect_icon{
    width: 1rem;
}
.active_power_svg-paper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3.4rem;
    background-color: transparent !important;
    box-shadow: none !important;
}
.mfm-names_div{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 1rem;  
}

@media screen and (max-width: 992px) {
.flex-start_space-between .Poppins_36px_rem {
    font-size: 20px !important;
}
.center_div_gap .raleway_32px_rem {
    font-size: 18px !important;
}
.flex-start-nogap .raleway_14px_rem {
    font-size: 12px !important;
}
.Poppins_32px_rem{
    font-size: 20px !important;
}
}