.about-main {
  display: flex;
  position: relative;
}

.about-submain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 1vh;
}
.aboutus-content {
  width: 43vw;
  font-weight: 700;
  text-align: center;
  word-break: normal;
  white-space: normal;
  hyphens: none;
  overflow-wrap: break-word;
}
.img-aboutus {
  position: absolute;
  width: 22vw;
  left: 55vw;
  top: -16vh;
}

.about-draw-line-one {
  top: 13vh;
  width: 48vw;
  position: absolute;
}

.about-draw-line-two {
  top: -22vh;
  left: 87vw;
  height: 42vh;
  position: absolute;
}
.about-our-story {
  background-color: #111;
  border-radius: 2vw;
  margin: 4vh 2vw;
  padding: 8vh 3vw;
}
.abt-img {
  display: flex;
  gap: 3vw;
  padding: 4vh 6vw;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 4vh;
  text-align: center;
}

.first-lastname-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-input {
  border-radius: 0.5vw;
  border: none !important;
  background: #fff;
  padding: 1vw;
  width: 100%;
  color: #161c2d;
  font-family: "Raleway" !important;
  font-size: 1.15vw !important;
  font-weight: 400 !important;
}

.about-form-container {
  gap: 3.2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-divs {
  position: absolute;
}
.first-name {
  width: 100%;
  margin-right: 1vw;
}

.last-name {
  width: 100%;
}

#about-checkbox {
  width: 3vw !important;
  height: 3vh !important;
  border-radius: 5px !important;
  background: #131313 !important;
  cursor: pointer !important;
}
.social-icons {
  width: 5vw;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
.react-tel-input .form-control {
  background-color: transparent !important;
  border: none !important;
  color: #8a8a8a !important;
  // font-size: 1.15vw !important;
  font-family: "Raleway" !important;
  font-feature-settings: "lnum", "tnum";
}
.react-tel-input .country-list {
  background-color: #fff !important;
  overflow-y: auto;
  width: auto !important;
  max-height: 20vh !important;
}
.react-tel-input .country-list::-webkit-scrollbar {
  display: none;
}
.flag-dropdown.open,
.selected-flag,
.selected-flag:focus,
.selected-flag:hover {
  background-color: transparent !important;
}
.react-tel-input .country-list .country {
  color: #161c2d !important;
}
.input:-internal-autofill-selected {
  background-color: #fff !important;
}
.react-tel-input .country-list .country .dial-code {
  color: #161c2d !important;
  font-family: "Raleway";
  font-feature-settings: "lnum", "tnum";
}

@media screen and (max-width: 992px) {
  //new class
  .width_45 {
    width: 100% !important;
  }
  #padding_rem {
    border-radius: 0.5rem !important;
    padding: 1rem !important;
  }
  #valData {
    border-radius: 0.5rem !important;
    padding: 1rem !important;
  }
  #valData_d {
    border-radius: 0.5rem !important;
    padding: 1rem !important;
  }
  ///
  .img-aboutus {
    width: 14rem !important;
  }
  .about-submain {
    padding: 1rem !important;
  }
  .about-our-story {
    padding: 1rem !important;
  }
  .about-form-container {
    width: 90% !important;
  }
  .img-aboutus {
    left: 0;
    top: 0;
    position: relative;
  }
  .img-divs {
    position: relative;
  }
  .about-main {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .first-name {
    margin-right: 10px !important;
  }
  .form-input {
    padding: 0.75rem !important;
    font-size: 0.75rem !important;
  }
  .bold-big-common-text {
    font-size: 31px !important;
  }
  #about-destn {
    width: auto !important;
  }
  .about-container {
    margin-bottom: auto !important;
    margin-top: 0vh !important;
  }
  .about-draw-line-two {
    display: none !important;
  }
}
// ///new about us
///

.about_first {
  width: 50%;
}
.width_45 {
  width: 45%;
}
.abour_mission_grid {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent !important;
  box-shadow: none !important;
  flex-shrink: 0;
  padding: 0vw !important;
  border-radius: 0.648vw !important;
  border: none !important;
}
.about_mission {
  border-radius: 0.648rem !important;
  border: none !important;

  height: 100% !important;
  width: 100% !important;
  padding: 1rem !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.svg_Num {
  position: relative;
}
.svg_text {
  position: absolute;
}
#valData {
  box-shadow: 0px 0px 40px 0px rgba(0, 82, 154, 0.8) !important;
  transition: background-color 1s ease;
}
#valData_d {
  box-shadow: 0px 0px 10px 0px #00529a !important;
  transition: background-color 1s ease;
}
#valData_h {
  box-shadow: 0px 0px 12px 0px #415da5 !important;

  transition: background-color 1s ease;
}
.gap_5vw {
  gap: 5vw !important;
}
#shadow_blue{
  box-shadow: 0px 0px 12px 0px #00529A !important;
  transition: background-color 1s ease;

}
#valData:hover {
  background-color: #fda737 !important;
  .Raleway_26px_500 {
    opacity: 1 !important; /* Maintain full opacity for the description */
  }
}
#valData_d:hover {
  background-color: #fda737 !important;
  .Raleway_26px_500 {
    opacity: 1 !important; /* Maintain full opacity for the description */
  }
}
.about_svg {
  background-color: #fff;
  filter: drop-shadow(0px 0px 11.908px rgba(253, 167, 55, 0.4));
  border-radius: 50%;
  padding: 2vw;
}
.about_contact {
  width: 100%;
  margin-top: 1vh;
}
.react-tel-input {
  text-align: left;
}
.form-input:focus {
  outline: none !important;
  border: none !important; /* Custom border when focused */
  box-shadow: none !important;
  background-color: #fff !important;
}
.form-input:-webkit-autofill {
  background-color: white !important; /* Sets background color to white */
  color: #000 !important; /* Ensures text color is black */
}
