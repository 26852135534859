.subscribe-container {
  padding: 30px;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.main-text {
  font-family: Poppins-SemiBold;
  font-size: 29px;
  color: rgb(0, 0, 0);
  padding: 15px;
}
.plans-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
