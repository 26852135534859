.alaram-check {
    padding: 4px !important;
  }
  #alarmCell .css-1howxi1-MuiTableCell-root{
    padding: 4px !important;

  }
  #alarmCell .css-2z1l6h-MuiTableCell-root {
    padding: 4px !important;
  }
  