.battery_shadow {
  box-shadow: 0px 0px 4px 0px #00529a;
  padding: 1rem 4rem;
  border-radius: 0.3rem;
}
#voltage_grid_bg {
  box-shadow: 1.28px 1.28px 15.98px 0px #00003314 !important;
  border: 1px solid #dbdbdb !important;
}
#elec_grid_shadow {

  box-shadow: 0px 0px 4px 0px #00000026 !important;
  border: 1px solid #dbdbdb !important;
}
//bioreactor
#bio_grid_shadow {
  box-shadow: 0px 0px 4px 0px #00529A80 !important;

}
.alert_style {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-10%, -50%);
  width: 50%;
  background-color: white;
  box-shadow: 0px 0px 4px 0px #00529A80 !important;
  padding: 20px;
  color: white;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  transition: opacity 0.5s ease-in-out;
}

.css-1fp32fp:focus {
  outline: none !important;
  border: none !important;
}

.notify_div{
  background: #DEE7F6;
  border-radius: 50%;
  padding: 0.4rem;

}
.bg_notify{
  background: #FDA737;
  border-radius: 20%;
  padding: 0.4rem;
}
/* CSS */
.button_fault {
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;

  border: 1px solid #dbdbdb !important;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-33 {
  background-color: #c2fbd7;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px,
    rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
}
.button-44 {
  background-color: #f0a618a8;
  box-shadow: rgba(251, 181, 46, 0.2) 0 -25px 18px -14px inset,
    rgba(251, 181, 46, 0.15) 0 1px 2px, rgba(251, 181, 46, 0.15) 0 2px 4px,
    rgba(251, 181, 46, 0.15) 0 4px 8px, rgba(251, 181, 46, 0.15) 0 8px 16px,
    rgba(251, 181, 46, 0.15) 0 16px 32px;
}
.button-44:hover {
  transform: scale(1.05) rotate(-1deg);

  box-shadow: rgba(251, 181, 46, 0.35) 0 -25px 18px -14px inset,
    rgba(251, 181, 46, 0.25) 0 1px 2px, rgba(251, 181, 46, 0.25) 0 2px 4px,
    rgba(251, 181, 46, 0.25) 0 4px 8px, rgba(251, 181, 46, 0.25) 0 8px 16px,
    rgba(251, 181, 46, 0.25) 0 16px 32px;
  transform: scale(1.05);
}
.button-33:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
#wid_50 {
  width: 50% !important;
}
#apex_pie {
    padding-bottom: 0rem !important;
  .apexcharts-legend.apx-legend-position-right {
    right: 4% !important;
    padding-left: 0px !important;
  }
  .apexcharts-legend-series {
    gap: 0.5rem !important;
  }
  .apexcharts-legend-text {
    font-size: 1rem !important;
  }
 
}
.tooltippie{
  padding: 0.625rem;
  border: 1px solid #2F67F8;
  border-radius: 0.3rem;
}
