.weatherDash-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0px !important;
  margin-left: 0px !important;
  gap: 0.4rem;
  position: relative;
}
.weather_grid_weather {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 0.7rem;
  right: 0;
  position: sticky !important;
  top: 0;
  z-index: 1;
  position: relative;

}

