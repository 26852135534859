#barplotforIotchart .apexcharts-toolbar {
  display: none !important;
}

#prediction-chart .apexcharts-toolbar {
  top: -20px !important;
  right: 90px !important;
}
.rmdp-container {
  width: 100% !important;
}
#custom_date_picker .rdrMonthAndYearWrapper{
  display: none !important;
}
.css-3w2yfm-ValueContainer {
  overflow: auto !important;
  height: 5vh !important;
  width: 14vw !important;
}
.apexcharts-menu-icon {
  display: none !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
  border-radius: 1vh !important;
  padding: 0.2rem  0.5rem  !important;
  border: 1px solid #2f67f8 !important;
  background: #f4f4f4 !important;
  top: 0 !important;
}
.dialog-corner {
  position: absolute;
  bottom: 0;
  left: -0.95rem;
  width: 1rem;
  height: 1rem;
  background-image: radial-gradient(
    circle at 0 0,
    transparent 0%,
    transparent 1rem,
    #488fdb 1rem
  );
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: transparent !important;
  border-bottom: none !important;
  margin-bottom: 0px !important;
}
.apexcharts-tooltip-text-y-label {
  color: #2f67f8 !important;
  font-weight: 700 !important;
}
.apexcharts-xaxistooltip.apexcharts-active{
  display: none !important;;
}
.Dropdown-menu::-webkit-scrollbar {
  display: none !important;
}
.scrollable_chat-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  cursor: pointer;
}
.scrollable_chat-container::-webkit-scrollbar {
  display: none !important;
}
@media screen and (max-width: 618px) {
  .rdrMonthAndYearPickers select {
    padding: 10px !important;
  }
  .Poppins_18px_rem {
    font-size: 14px !important;
  }
  .flex-start_gap {
    gap: 0.5rem !important;
  }
  #prediction-chart .apexcharts-toolbar {
    display: none !important;
  }
  #prediction-chart_two .apexcharts-toolbar {
    display: none !important;
  }
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
  .range_title {
    display: none !important;
  }
  .intervals_container {
    display: none !important;
  }
  .calender-container {
    flex-direction: column !important;
  }
  .Poppins_24px_black {
    font-size: 16px !important;
  }
}
