.home-container {
  width: 100vw;
  height: 92vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1vh;
  background-color: #f4f4f4;
}
.fault_ul_style{
  padding: 1rem ;
}
.home-container::-webkit-scrollbar {
  display: none !important;
}
.border_div{
  border: 1px solid #D8D8D8;
  border-radius: 0.5rem !important;
    padding: 1rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
.scrolling-grid-exc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  border: none;
  flex-direction: column;
  gap: 0.625rem;
  max-height: 100%;
  overflow: auto;
}

.scrolling-grid::-webkit-scrollbar {
  display: none !important;
}

.scrolling-grid-exc::-webkit-scrollbar {
  display: none !important;
}
//Mobile chatbot scss
.mobile_chatbot{
  position: absolute;
    bottom: 1rem;
    right: 1rem;
    background-color: rgba(0, 82, 154, 0.8980392157);
    padding: 0.7rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.popup_text{
  position: absolute;
    bottom: 1rem;
    right: 4.8rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 0.3rem;
    box-shadow: 6px 4px 16px 0px #00000040;
}
.popup_text::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  border: 8px solid transparent;
  border-left-color: white;
}
.show_chaticon{
  position: absolute;
  bottom: 0;
  right: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icons_wrapper{
  position: absolute;
  background-color: #ffff;
  bottom: 0;
  right: 0;
  width: 10rem;
  height: 9rem;
  border-radius: 80% 10% 0% 10%;
  box-shadow: 6px 4px 16px 0px rgba(0, 0, 0, 0.2509803922);
}

//donut tooltip css
.apexcharts-tooltip-text-y-value {
  color: black !important;
}

.apexcharts-tooltip-series-group.apexcharts-active {
  border-radius: 1vh !important;
  padding: 0rem !important;
  background: #f4f4f4 !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
  background: #f4f4f4 !important;
}

.blue_text {
  color: #2f67f8 !important;
}
#navy_blue_text .is-open .Dropdown-arrow {
  border-color: #00529A transparent transparent !important;
}
.circle_container {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 50% !important;
}

.power_analysis_grid_exc {
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  background: #ffffff !important;
  border-radius: 0.75rem !important;
  border: 0.0625rem solid #d8d8d8 !important;
}
.power_analysis_paper {
  background-color: white !important;
  box-shadow: none !important;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  padding: 1rem !important;
  border-radius: 0.75rem !important;
}
.analysis_div {
  background-color: transparent !important;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  cursor: pointer;
  padding-right: 1rem;
  width: 50%;
}
.donut_grid_exc {
  position: relative !important;
  background-color: white !important;
  box-shadow: none !important;
  border-radius: 0.75rem !important;
  border: 0.0625rem solid #d8d8d8 !important;
  padding: 1rem !important;
  flex-direction: column !important;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
}


.apexcharts-legend-text {
  font-family: "Poppins-Regular" !important;
  color: #212121 !important;
  font-size: 0.9rem !important;
}
.apexcharts-datalabels-group {
  transform: translate(0, -15px) !important;
  white-space: normal !important;
}

.tooltip_typography {
  color: white !important;
  display: flex !important;
  gap: 0.625rem !important;
  align-items: center !important;
  justify-content: flex-start !important;
}
.inv_grid {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  box-shadow: none !important;
  padding: 1rem !important;
  border-radius: 0.75rem !important;
}
.inv_paper {
  background-color: white !important;
  box-shadow: none !important;
  width: 100% !important;
  cursor: pointer !important;
}
.space-betwn_div{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.val_arrow{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 8px 0px #00529A40;
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  width: 11vw;
}
.custom-placeholder{
  color: #00529A;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.9375rem;
}
//old Inverter.js scss from old ExecutiveDashbrd.scss
.exceutive-dashboard {
  width: 100vw !important;
  height: 95vh !important;
  overflow-y: auto;
}

.MuiBackdrop-root .MuiModal-backdrop .css-919eu4 {
  background-color: transparent !important;
}

.exceutive-dashboard::-webkit-scrollbar {
  display: none !important;
}

.msgview::-webkit-scrollbar {
  display: none;
}

.tablescroll {
  overflow-y: auto;
  height: 50vh;
}

.tablescroll::-webkit-scrollbar {
  display: none;
}

.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 15px 30px;
  color: #2e2d36;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

.plant-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  justify-content: center;
  padding: 1vw;
}

#SvgjsText1057 {
  font-size: 0.7vw !important;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px;
  }

  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px;
  }

  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 2500px) {
  #SvgjsText1057 {
    font-size: 1vw !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1599px) {
  #SvgjsText1057 {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1299px) {
  #SvgjsText1057 {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #SvgjsText1057 {
    font-size: 10px !important;
  }

  #power_analysis_id {
    height: auto !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  #SvgjsText1057 {
    font-size: 17px !important;
  }
  #power_analysis_id {
    height: auto !important;
  }
}

@media screen and (max-width: 575px) {
  #SvgjsText1057 {
    font-size: 15px !important;
  }
  .medium_width_150 svg {
    width: 150px !important;
  }
}

@media screen and (max-width: 717px) {
  .gauge-container {
    grid-template-columns: auto !important;
  }
}

.grid-exc-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  gap: 0.5rem;
  position: relative;
}

.first-exc-grid {
  height: 18rem;
  padding: 1rem !important;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 0.7rem !important;
  border: 0.0625rem solid #d8d8d8;
}

.first-exc-paper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem !important;
  justify-content: space-between;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 100%;
}

.space-btn-center {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
}

.flex-start-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-start-column-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
}

.sec-grid-exc {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent !important;
  margin-bottom: 2rem !important;
}

.energy-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent !important;
  box-shadow: none !important;
}

#donut_paper{
  height: auto !important;

}
.energy-paper {
  height: 100%;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 0.625rem !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.divider-style {
  border: 0.0625rem solid #d9d9d9 !important;
  height: 10rem !important;
}

.power-plant-grid-exc {
  position: relative;
  // height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.7rem;
  border: 0.0625rem solid #d8d8d8;
  flex-direction: column !important;
  gap: 1rem;
}
.power-plant-acdc {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.7rem;
  border: 0.0625rem solid #d8d8d8;
  flex-direction: column !important;
  gap: 1rem;
  position: relative;
}
.power-plant-paper-exc {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 1rem;
  background-color: transparent;
  box-shadow: none !important;
}

.icon-exc-div {
  padding: 0.4rem;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 50%;
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
}

#circularBar .CircularProgressbar {
  height: 9rem !important;
  width : 9rem !important;

}
#circularBar .CircularProgressbar-path {
  stroke: green !important;
}
#circularBarTwo .CircularProgressbar-path {
  stroke: green !important;
}
.pdonut {
  position: absolute;
  top: 50%;
  left: 26%;
  transform: translate(0, -50%);
  text-align: center;
}
.powerplant-sec-paper {
  height: 100%;
  width: 100% !important;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  box-shadow: none !important;
  flex-direction: row;
}

.powerplant-div-exc {
  padding: 0.5rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: space-between;
  height: 5.5rem;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  overflow-y: auto;
  background-color: #f1f1f7;
}

.powerplant-div-exc::-webkit-scrollbar {
  display: none !important;
}

.exc-graph-grid {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  padding: 0rem !important;
  border-radius: 0.7rem !important;
  background-color: white;
  border: 0.0625rem solid #d9d9d9;
  width: 100% !important;
}

.exc-graph-paper {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  padding: 0rem !important;
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
}

.notification-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: none !important;
  gap: 1rem;
  padding: 0rem !important;
  border-radius: 0.7rem !important;
  flex-direction: column !important;
  border: 0.0625rem solid #d9d9d9;
}

.notification-paper {
  height: 100%;
  width: 100% !important;
  box-shadow: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.8rem 1.5rem;
  border-radius: 0.7rem !important;
}

.notification-paper-one {
  height: 100%;
  padding: 0rem !important;
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}

.inv-count-exc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: none !important;
  gap: 1rem;
  padding: 1.5rem !important;
  border-radius: 1rem !important;
  border: 0.0625rem solid #d9d9d9;
}

.component-grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-shadow: none !important;
  gap: 1rem;
  padding: 2rem !important;
  border-radius: 0.7vw;
  border: 0.0625rem solid #d9d9d9;
}

.component-paper {
  width: 100% !important;
  box-shadow: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  cursor: pointer;
}

.exc-chatbot-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 0.7rem;
  right: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.exc-chatbot-div {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  gap: 0.625rem;
  padding: 0.625rem;
  justify-content: space-between;
  border-radius: 0.625rem;
  height: 10vh;
}

.chatbot-icon {
  width: 49%;
}

.reportDownload {
  width: 100% !important;
  display: flex;
  background-color: #1565c0 !important;
  border-radius: 0.625rem !important;
  text-transform: none !important;
  padding: 0.3125rem 0.625rem !important;
  justify-content: space-around !important;
  align-items: center;
}

.gap-grid {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.exc-chatbot-div-sub {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
}

.write-some {
  padding: 0.8rem 0.5rem;
  border: 0.0625rem solid #d8d8d8;
  border-radius: 1rem;
  background-color: #ffffff;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.customPopoverPaper-exc {
  border-radius: 0.625rem !important; // Apply custom border-radius
  padding: 1rem !important;
  border: 0.0625rem solid #d8d8d8;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 15rem;
  margin-left: -4rem;
}

//centered classnames
.assignerpopup {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  flex-direction: column;
}

.green-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem !important;
}

.report-img-divone {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
}

.title_div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.center_div {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center !important;
}

.center_div_nogap {
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.space_btn_gap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  justify-content: space-between;
}

.smb_value_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.nogap {
  gap: 0rem !important;
}

.plant-table_paper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  box-shadow: none !important;
  padding: 1rem !important;
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
  flex-direction: column;
}
.chatbot_Paper {
  position: absolute;
  z-index: 5;
  top: 46%;
  left: auto;
  transform: translate(0%, -50%);
  width: -webkit-fill-available !important;
  height: 96%;
  background-color: white !important;
  display: flex;
  margin: 2rem 1rem 0rem 0rem !important;
  padding: 1rem !important;
  color: white;
  overflow-y: auto !important;
  border-radius: 1rem !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0rem 0.6875rem 0.9375rem -0.4375rem,
    rgba(0, 0, 0, 0.14) 0rem 1.5rem 2.375rem 0.1875rem,
    rgba(0, 0, 0, 0.12) 0rem 0.5625rem 2.875rem 0.5rem !important;
}
#chatbot_Paper_voice{
  width: 50% !important;
  right: 20% !important;
  top:45% !important;
}
.chatbot_Paper::-webkit-scrollbar {
  display: none !important;
}
.powerTooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 0.75vw;
  transform: translate(-50%, -50%);
  padding: 0.625rem;
  background-color: #f4f4f4;
  border: 0.0625rem solid #2f67f8;
}
.legend_power {
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
  gap: 0.325rem;
  align-items: flex-start;
  justify-content: flex-start;
}
#legend_power_inv{
  height: 27vh;
  overflow-y: auto;
}
#legend_power_inv::-webkit-scrollbar{
  display: none !important;

}
.circle_power {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  margin-right: 0.625rem;
}
.circular_main {
  display: flex;
  align-items: center;
  position: relative;
}
.circular_submain {
  width: fit-content;
  height: fit-content;
  position: relative;
}
#inv_grid_between{
  justify-content: space-between !important;
}
#chat-mic{
  height: 3rem !important;
  padding: 0.2rem !important;
}
#timeGap{
  gap: 0.5rem !important;
}

@media screen and (max-width: 840px){
  #media_flex_col {
    flex-direction: column !important;
    gap: 1rem !important;
  }
}
@media screen and (max-width: 992px)
{
  .divider-style {
    display: none !important;
  }
}
@media screen and (max-width: 1200px)
{
  .width_50{
    width: 50% !important;
  }
  .val_arrow {
    width: 50% !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1210px){
  .plant-info{
    padding: 0.5rem 2rem !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px)
{
  .medium_width_150 svg{
    width: 150px !important;
  }
}