:root {
  --white: #ffffff;
  --dark: #161c2d;
  --gray: #5f6368;
  --lightdrak: #202124;
  --blue: #00529a;
  --black: #000;
  --lightblue: #1e75e8;
  --lightgray: #5c616c;
  --yellow: #fda737;
  --darkblack: #252525;
  --darkBlack: #141414;
  --secBlack: #1e1e1e;
  --thirdBlack: #0d1317;
  --red:#FF4D50;
  --skyblue:#2F67F8;
}


html {
  scroll-behavior: smooth; /* Enable smooth scrolling */
}
.c_skyblue {
  color: var(--skyblue) !important;
}
.c_white {
  color: var(--white) !important;
}
.c_red {
  color: var(--red) !important;
}
.c_dark {
  color: var(--dark) !important;
}

.c_gray {
  color: var(--gray) !important;
}

.c_lightdrak {
  color: var(--lightdrak) !important;
}

.c_blue {
  color: var(--blue) !important;
}

.c_black {
  color: var(--black) !important;
}

.c_lightblue {
  color: var(--lightblue) !important;
}

.c_lightgray {
  color: var(--lightgray) !important;
}

.c_yellow {
  color: var(--yellow) !important;
}

.c_darkblack {
  color: var(--darkblack) !important;
}

.c_darkBlack {
  color: var(--darkBlack) !important;
}

.c_secBlack {
  color: var(--secBlack) !important;
}
.c_thirdBlack {
  color: var(--thirdBlack) !important;
}
.font_400 {
  font-weight: 400 !important;
}
.font_500 {
  font-weight: 500 !important;
}
.font_600 {
  font-weight: 600 !important;
}
.font_700 {
  font-weight: 700 !important;
}
.font_style_i{
  font-style: italic !important;
}
.Raleway_61px {
  font-family: "Raleway";
  font-weight: 700;
  color: var(--white);
  font-size: 3.05vw;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_15px {
  color: var(--white);
  font-family: "Raleway" !important;
  font-size: 0.75vw !important;
  font-weight: 400;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_17px {
  color: var(--white);
  font-family: "Raleway";
  font-size: 0.85vw;
  font-weight: 400;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_18px_500 {
  color: var(--black);
  font-family: "Raleway";
  font-size: 0.89vw;
  font-weight: 500;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_21px_500 {
  font-family: "Raleway";
  font-weight: 500;
  color: var(--secBlack);
  font-size: 1.05vw;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_21px_600 {
  font-family: "Raleway";
  font-weight: 600;
  color: var(--black);
  font-size: 1.05vw;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_22px {
  color: var(--white);
  font-family: "Raleway";
  font-size: 1.09vw;
  font-weight: 500;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_23px_dark {
  color: var(--black);
  font-family: "Raleway";
  font-size: 1.15vw;
  font-weight: 600;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_23px_700 {
  color: var(--darkBlack);
  font-family: "Raleway";
  font-size: 1.19vw;
  font-weight: 700;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_25px {
  font-family: "Raleway";
  font-weight: 400;
  color: var(--dark);
  font-size: 1.25vw;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_25px_600 {
  font-family: "Raleway";
  font-weight: 600;
  color: var(--blue);
  font-size: 1.25vw;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_26px_500 {
  color: var(--dark);
  font-family: "Raleway";
  font-size: 1.3vw;
  font-weight: 500;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_26px {
  font-family: "Raleway";
  font-weight: 600;
  color: var(--lightdrak);
  font-size: 1.3vw;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_28px {
  color: var(--black);
  font-family: "Raleway";
  font-size: 1.4vw;
  font-weight: 600;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_31px_dark {
  color: var(--dark);
  font-family: "Raleway";
  font-weight: 500;
  font-size: 1.55vw;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_31px_600 {
  font-family: "Raleway";
  font-weight: 600;
  color: var(--white);
  font-size: 1.55vw;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_40px {
  color: var(--black);
  font-family: "Raleway";
  font-size: 2vw;
  font-weight: 600;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_48px {
  font-family: "Raleway";
  font-weight: 500;
  color: var(--dark);
  font-size: 2.4vw;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_48px_600 {
  font-family: "Raleway";
  font-weight: 600;
  color: var(--darkblack);
  font-size: 2.4vw;
  font-feature-settings: "lnum", "tnum";
}
.Raleway_64px {
  font-family: "Raleway";
  font-weight: 600;
  color: var(--darkblack);
  font-size: 3.2vw;
  font-feature-settings: "lnum", "tnum";
}

.Raleway_45px {
  color: var(--blue);
  font-family: "Raleway";
  font-size: 2.25vw;
  font-weight: 600;
  font-feature-settings: "lnum", "tnum";
}
.opacity_7 {
  opacity: 0.7;
}
.opacity_8 {
  opacity: 0.8;
}
#text_left {
  text-align: left;
}
////////////////////////////////////////////////////////////////////
.connect-all {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh 0vh;
}

.connect_all {
  font-size: 1.25vw !important;
  font-family: "Raleway" !important;
  cursor: pointer !important;
  background-color: transparent !important;
  border-radius: 2.75vw !important;
  padding: 1.5vh 3vw !important;
  position: relative !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  color: var(--black) !important;
  width: fit-content !important;
  border: 1px solid var(--black) !important;
}
.connect_one {
  transition: background-color 0.5s ease-out, padding 0.5s ease-out !important;
}
.connect_two {
  transition: background-color 0.3s ease-in, padding 0.3s ease-in !important;
}
.connect_one:hover {
  background-color: var(--yellow) !important;
  border: none !important;
  padding: 1.5vh 4vw !important;
  transition: background-color 0.3s ease-in, padding 0.3s ease-in !important;
}
.connect_two:hover {
  background-color: var(--yellow) !important;
  border: none !important;
  padding: 1.5vh 2vw !important;
  transition: background-color 0.5s ease-out, padding 0.5s ease-out;
}
.proArrow {
  margin-left: 1vh;
}
.arrow_case {
  display: inline-flex;
  align-items: center;
  transition: transform 0.5s ease, padding 0.5s ease;
  padding: 1vh 1vw;
  border-radius: 2.75vw;
  transform-origin: left;
  margin-right: 1vw !important;
}

.arrow_case:hover {
  background-color: var(--yellow) !important;
  padding: 1vh 2vw 1vh 1vw !important;
  transform: scaleX(1.1) translateX(1vw);
}

.expert_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
#nav_new {
  margin-left: 17vw;
}
.email_div {
  width: 100%;

  background-color: var(--white);
  border-radius: 0.1vw;
  justify-content: flex-start !important;
  padding: 1vw !important;
}
.email_div input {
  width: 100%;
  border: none !important;
}
.email_div input::placeholder {
  color: var(--black) !important;
}
.email_div input:focus {
  outline: none !important;
  border: none !important; /* Custom border when focused */
  box-shadow: none !important;
  background-color: #fff !important;
}
.footer-container .email_div .MuiInputBase-input::placeholder {
  color: var(--black) !important;
  font-family: "Raleway";
  font-size: 0.8vw;
  font-weight: 400;
}
.submit_btn {
  padding: 1vh 3vw !important;
  border-radius: 2vw !important;
  border: none !important;
  background: var(--blue) !important;
  text-transform: capitalize !important;
  font-size: 1vw !important;
  font-family: "Raleway" !important;
  font-weight: 600 !important;
}
.expert_title {
  gap: 2vw;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
}
.gap_1vw {
  gap: 1vw;
}
.gap_3vw {
  gap: 3vw !important;
}
.active_ind {
  background: #fda737;
  cursor: pointer;
  border-radius: 50%;
  width: 4vw;
  height: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure fill effect stays within bounds */
  position: relative;
}
.expert_slide {
  cursor: pointer;
  width: 3vw;
}
.cursor_p {
  cursor: pointer;
}
.align_item {
  align-items: flex-start !important;
}
.sol_arrow {
  cursor: pointer;
  background-color: rgba(211, 226, 245, 0.35);
  border-radius: 50%;
  padding: 0.8vw;
  transition: background-color 0.1s ease;
}
.sol_arrow:hover {
  background-color: var(--blue);
}
#text_just {
  text-align: justify;
}
.product_arrow {
  cursor: pointer;
  width: 3.5vw;
  height: 3.5vw;
  background-color: #d3e2f5;
  border-radius: 50%;
  padding: 0.7vw;
  transition: background-color 0.1s ease;
}

.expertise_wrapper {
  margin: 4vw 5vw 0vw 5vw;
}
.expert_div_main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.expert_content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;
}
.our_solution_main {
  padding: 0vw 7vw;
}
.padding_s {
  padding: 1vw 0vw;
}
.padding_sides {
  padding: 0vw 1vw;
}
.margin_4vw {
  margin: 0vw 4vw;
}
.margin_bottom {
  margin-bottom: 3.5rem !important;
}
.div_width {
  width: 100%;
}
.slide_img_gradient {
  background-size: cover;
  background-position: center;
}
.sol_img {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    4px 4px 80px 0px rgba(0, 0, 0, 0.4);
}
.our_solution {
  flex-direction: column;
  text-align: center;
  width: 50%;
}
.flex_col {
  flex-direction: column;
}

.pro_img_div {
  height: 100%;
}
.text_cen {
  text-align: center !important;
}
.justify_start{
  justify-content: flex-start !important;
}
.justify_end{
  justify-content: flex-end !important;
}
#our_sol {
  margin-top: 2vw !important;
}
#margin_tb {
  margin: 2vw 0vw !important;
}
#margin_b {
  margin-bottom: -1vw !important;
}
#product_img {
  margin-top: -4vh;
}
#margin_8 {
  margin-right: 14.5vw;
}
.width_97 {
  width: 97% !important;
}
.border_5 {
  border-radius: 0.7rem !important;
}
.dots_container {
  align-self: center;
  margin-top: 1vh;
  transition: width 0.1s linear;
}
#secdot {
  align-self: flex-end;
}
.align_self {
  align-self: flex-start !important;
}
.align_self_center {
  align-self:center!important;
}
.dot {
  width: 0.9587vw;
  height: 1vw;
  flex-shrink: 0;
  margin: 0 0.25vw;
  background-color: transparent !important;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  position: relative; /* Positioning for the inner fill */
  overflow: hidden; /* Hide the overflow for the filling animation */
  border: 0.09585vw solid var(--black);
}
#dotWhite {
  border: 0.09585vw solid var(--white) !important;
}
#dotWhite.active {
  border: 0.09585vw solid white !important;
}
.dot.active {
  width: 1.9vw; /* Width when active */
  height: 0.4vw; /* Height when active */
  background-color: transparent; /* Keep the active dot's background transparent */
  border: 0.09585vw solid #00529a;
  border-radius: 0.5vw;
}
#dotWhite.active::before {
  background-color: white !important; /* Background color to fill */
}
.dot.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #00529a; /* Background color to fill */
  animation: fill 5s linear forwards; /* Animation to fill from left to right */
  width: 0; /* Start with 0 width */
}

@keyframes fill {
  0% {
    width: 0; /* Start with 0 width */
  }
  100% {
    width: 100%; /* Fill to 100% width */
  }
}

.home_grid_container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  gap: 1vw;
}
.home_paper_product {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 100%;
  flex-shrink: 0;
  gap: 0vw !important;
  padding: 0vw !important;
  border: none !important;
}
.home_paper_casestudy {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 100%;
  flex-shrink: 0;
  gap: 1vw !important;
  padding: 1vw 1.5vw !important;
  border: none !important;
}
.home_grid_product {
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
  cursor: pointer;
}
.gap_2vw {
  gap: 2vw !important;
}

.home_grid_product {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--white) !important;
  box-shadow: none !important;
  flex-shrink: 0;
  padding: 0vw !important;
  border-radius: 1.5728vw !important;
  border: 0.06555vw solid rgba(32, 33, 36, 0.15) !important;
}
.div_casestudy {
  width: 100%;
}
.div_product {
  background: #f1f3f4;
  width: 100%;
  height: 100%;
  border-top-left-radius: 1.5728vw !important;
  border-top-right-radius: 1.5728vw !important;
  padding: 0vw 1.5vw 0vw 1.5vw;
}
.padd_1vw {
  padding: 1vw !important;
}
.product_content {
  width: 100%;
  height: 47vh;
  cursor: pointer;
  padding: 1vw;
}
.div_img_height {
  height: 25vh;
}
.sol_desc {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.solar_divs {
  width: 100%;
  height: 100%;
}
.height_w {
  width: 100%;
  height: 100%;
}
.img_div_sol {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
  width: 100%;
  height: 100%;
}
#pro_hover:hover {
  .sol_desc {
    background-color: #fda737;
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
    .opacity_8 {
      opacity: 1 !important; /* Maintain full opacity for the description */
    }
  }
}
#pro_hover:hover {
  .product_content {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
    background-color: #fda737;

    .product_arrow {
      background-color: #1a73e8;
    }
  }
}

.div_foo {
  opacity: 0.1;
  margin: 2vh 0vh !important;
}
.yellow_line {
  width: 75%;
  margin: 1vh 0vh !important;
}
.foo_one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.foo_des {
  width: 35vw;
}
//our solution page
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
}
.expert_desc {
  width: 70%;
}
.sol_sec {
  width: 50%;
}
.sol_sec_c {
  width: 100%;
}
#border_none {
  border: none !important;
  background-color: transparent !important;
}
.height_6vw {
  height: 6vw;
}
.height_10vw {
  height: 10vw;
}
.height_15vw {
  height: 15vw;
}
.height_12vw {
  height: 12vw ;
}
.height_20vw {
  height: 20vw;
}
.height_18vw {
  height: 18vw;
}
.linear_expert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fda737;
}
.digi_vc {
  width: 80%;
  border-radius: 2rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (max-width: 901px) {
  
  .padding_s {
    padding: 0rem 1rem;
  }
  .padding_sides {
    padding: 0rem 1rem;
  }
  .our_solution_main {
    padding: 0rem 1rem;
  }
  .margin_4vw {
    margin: 0rem 1rem;
  }
  .margin_side {
    margin: 0rem 1rem;
  }
  .expert_desc {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  .about_first {
    width: auto !important;
  }

  .Raleway_61px {
    font-size: 1.5rem !important;
  }
  .Raleway_15px {
    font-size: 0.6rem !important;
  }
  .Raleway_21px_600 {
    font-size: 0.8rem !important;
  }
  .Raleway_25px_600 {
    font-size: 1.1rem !important;
  }
  .Raleway_64px {
    font-size: 1.25rem !important;
  }
  .Raleway_40px {
    font-size: 0.875rem !important;
  }
  .text_c {
    text-align: center !important;
    align-self: center !important;
  }
  .text_j {
    text-align: justify !important;
  }
  .Raleway_28px {
    font-size: 1.25rem !important;
  }
  .Raleway_26px_500 {
    font-size: 0.8rem !important;
  }
  .Raleway_48px_600 {
    font-size: 1.25rem !important;
  }
  .Raleway_17px {
    font-size: 0.875rem !important;
  }
  .Raleway_26px {
    font-size: 0.875rem !important;
  }
  .gap_1vw {
    gap: 0.8rem !important;
  }
  .gap_3vw {
    gap: 3rem !important;
  }

  .svg_Num {
    width: 3rem !important;
  }
  .Raleway_31px_600 {
    font-size: 1.125rem !important;
  }
  .Raleway_21px_500 {
    font-size: 0.75rem !important;
  }
  .home_grid_container {
    gap: 3vw !important;
  }
  .Raleway_23px_700 {
    font-size: 1.125rem !important;
  }
  .Raleway_18px_500 {
    font-size: 0.75rem !important;
  }
  .Raleway_22px {
    font-size: 0.75rem !important;
  }
  .Raleway_48px {
    font-size: 1.8rem !important;
  }
  .expert_content {
    .Raleway_31px_dark {
      font-size: 1.125rem !important;
    }
  }
  .Raleway_31px_dark {
    font-size: 0.8rem !important;
  }
  .Raleway_25px {
    font-size: 0.825rem !important;
  }
  .Raleway_23px_dark {
    font-size: 1.125rem !important;
  }
  .padd_1vw {
    padding: 1rem !important;
  }
  .Raleway_45px {
    font-size: 1.5625rem !important;
  }

  #our_sol {
    margin-top: 1rem !important;
  }
  .career-grid_container{
    padding: 0.5rem !important;

  }
  .filter_car {
    padding: 0.4rem !important;
  }
  #dot_car{
    padding-left: 1rem !important;
  }
  .margin_top_1vw{
    margin-top: 1rem !important;

  }
  #margin_tb {
    margin: 1rem 0rem !important;
  }

  .email_div {
    padding: 0.8rem !important;
  }
  .home_grid_product {
    border-radius: 1.5728rem !important;
  }
  .sol_desc .opacity_8 {
    opacity: 1 !important;
  }
  .dots_container {
    margin-top: 1rem;
  }
  .margin_1rem {
    margin-top: 1rem;
  }
  .height_6vw {
    height: auto !important;
  }
  .height_20vw {
    height: auto !important;
  }
  .height_18vw {
    height: auto !important;
  }
  .height_10vw {
    height: auto !important;
  }
  .height_12vw {
    height: auto !important;
  }
  .height_15vw {
    height: auto !important;
  }

  .dot {
    width: 0.9587rem;
    height: 1rem;
    margin: 0 0.25rem;

    border: 0.09585rem solid var(--black);
  }

  .dot.active {
    width: 1.9rem; /* Width when active */
    height: 0.4rem; /* Height when active */
    border: 0.09585rem solid #00529a;
    border-radius: 0.5rem;
  }
  .active_ind {
    width: 2.2rem;
    height: 2.2rem;
  }

  .expert_title {
    gap: 1rem !important;
  }
  .our_solution {
    width: 90% !important;
    .Raleway_31px_dark {
      font-size: 0.8rem !important;
    }
  }
  .our_experts {
    width: auto !important;
    .Raleway_31px_dark {
      font-size: 0.8rem !important;
    }
  }
  .div_product {
    border-top-left-radius: 1.5728rem !important;
    border-top-right-radius: 1.5728rem !important;
    padding: 1rem;
  }
  .product_content {
    height: 100%;
    padding: 1rem;
  }

  .product_arrow {
    width: 3rem;
    height: 3rem;

    padding: 0.7rem;
  }
  .home_paper_casestudy {
    gap: 1rem !important;
    padding: 1rem !important;
  }
}
@media screen and (min-width: 900px) {
  .home_grid_product.active {
    transform: scale(1.1);
    z-index: 1;
  }

  .home_grid_product.inactive {
    filter: blur(2px);
    opacity: 0.5;
    z-index: 0;
  }
}