.case-study-heading {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  height: 55vh;
}
.case-study-head-two {
  width: 46vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.archerry-img {
  width: 12vw;
  padding-bottom: 12vh;
  position: relative;
  z-index: 1;
  top: 9vh;
  left: 3vw;
}
.svg-arrow-left {
  top: -7vh;
  width: 39vw;
  position: absolute;
  left: 0;
}
.circle-arrow-casestudy {
  left: 40%;
  position: absolute;
  bottom: 9%;
}
.casestudy-search-bar {
  position: relative;
  z-index: 1;
  margin-top: -11vh;
  width: 50vw;
  height: auto !important;
  font-weight: 400 !important;
  padding: 0.5vw !important;
}
.casestudy-search-bar input::placeholder {
  color: #808080;
  font-family: "Raleway";
  font-size: 1.15vw;
}
.ForwardRef-iconButton-2 {
  background-color: #212121 !important;
  color: white !important;
}
.casestudy-grid {
  background-color: transparent !important;
  margin: 4vh 0vw 9vh 0vw;
}
.vectorAnime-one {
  height: 50vh;
  position: absolute;
  left: 88vw;
  top: 0;
}
.archerry-shadow {
  width: 4vw;
  margin-left: -3vw;
}
.ForwardRef-searchContainer-7 {
  width: calc(100% - 80px);
  margin: auto 16px;
  align-items: center !important;
  display: flex !important;
}

.search-box {
  font-size: 1.2vw !important;

  border: 1px solid #cacaca96 !important;
  border-radius: 1.25vw !important;
  color: #808080 !important;
  background-color: #fff;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3vw 0.8vw;
}
.star_usecase{
  background-color: white;
  padding: 0.3rem 0.2rem;
  border-radius: 0.625rem;
}
.cursor_none{
  cursor: auto !important;
}

.search-icon {
  width: 2.8vw;
}

@media screen and (max-width: 992px) {
  .case-study-heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    height: 41vh !important;
  }
  .casestudy-grid {
    padding: 13px !important;
  }
  .case-study-cards {
    padding: 20px !important;
  }
  .case-study-head-two {
    width: auto;
    padding-top: 69px;
  }
  .vectorAnime-one {
    height: 37vh;
    left: 77vw;
  }
  .archerry-img {
    display: none;
  }
  .svg-arrow-left {
    left: -176vw;
    top: -9vh;
  }
  .casestudy-search-bar {
    width: auto;
    height: 6vh !important;

    font-size: 16px !important;
  }
  .case-study-stars {
    display: none !important;
  }

  .search-icon {
    width: 47px;
  }
}

@media screen and (min-width: 500px) and (max-width: 899px) {
  .circle-arrow-casestudy {
    left: 44% !important;
    bottom: 8% !important;
  }
}
@media screen and (max-width: 1200px) {
  .case-study-cards {
    border-radius: 1rem 1rem 5rem 5rem !important;
  }
  .case_study_imgs {
    border-radius: 1rem 1rem 5rem 5rem !important;
  }
}

////recent one
.digital_keys{
  background-color: #706fe563;
  border-radius: 0.75rem;
  padding: 0.5rem;
}
.digital_keys img{
  width: 70%;

}
.circle-arrow-casestudy {
  left: 40%;
  position: absolute;
  bottom: 9%;
}

.casestudy-grid {
  background-color: transparent !important;
  margin: 4vh 0vw 9vh 0vw;
}
.width_casestudy {
  width: 55%;
}
.width_40 {
  width: 40%;
}
.width_20 {
  width: 20%;
}
.width_90 {
  width: 90%;
}
.width_60_m {
  width: 60%;
}

.width_60 {
  width: 60%;
}

#search-input {
  background-color: transparent;
  border: 0px solid;
  width: 100% !important;
  padding: 0.7vw;
  font-weight: 400 !important;
}
#search-input:focus {
  outline: none;
}

.search-icon {
  width: 2.8vw;
}

//new design

.case_arrows {
  border-radius: 50%;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: var(--DarkOverlayElement-DO40, rgba(255, 255, 255, 0.4));
  transition: padding 0.3s ease, background-color 0.3s ease,
    border-radius 0.3s ease;
  padding: 0.3vw;
}
.case_arrows:hover {
  padding: 0.3vw 2vw !important;
  background-color: #fda737;
  border-radius: 2.75vw !important;
}
.search_case {
  padding: 0.6vw;
  border-radius: 0.629vw;
  background: #00529a;
}
.result_down {
  z-index: 0;
}
.result_up {
  width: 100%;
  z-index: 1;
  border-radius: 0.7rem;
  margin-top: -5rem;
  background: var(--Neutral-Silver, #f5f7fa);
  box-shadow: 0px 9.251px 18.502px 0px rgba(0, 82, 154, 0.4);
}
.justify_space {
  justify-content: space-between !important;
}
.box_shade {
  border-radius: 1rem !important;
  box-shadow: 3.179px 3.179px 63.576px 0px rgba(0, 82, 154, 0.8);
}
.life_gene {
  filter: drop-shadow(0px 4px 40px #00529a);
}
@media screen and (max-width: 992px) {
  .case_arrows {
    padding: 0.1rem;
  }
  .case_arrows:hover {
    padding: 0.1rem 2rem !important;
  }
  .casestudy-grid {
    padding: 13px !important;
  }

 
  .search_case {
    padding: 0.6rem;
    border-radius: 0.629rem;
  }
  .search-icon {
    width: 47px;
  }
}

@media screen and (min-width: 500px) and (max-width: 899px) {
  .circle-arrow-casestudy {
    left: 44% !important;
    bottom: 8% !important;
  }
}
@media screen and (min-width: 340px) and (max-width: 600px) {
  .width_casestudy {
    width: 100% !important;
  }

  .width_40 {
    width: 100%;
  }
  .width_90 {
    width: 100%;
  }
  

  .width_60 {
    width: 100%;
  }
  .bg_blues{
    padding: 1.2vw !important;

  }
  .casestudy-grid {
    margin: 0rem 0rem 2rem 0rem !important;
  }
}
