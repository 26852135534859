.protein-analysis-container{
    width: 100%;
    height: 100%;
}
.protein-subcontainer {
    padding-top: 20px;
    margin: 20px;
}
.fasta-container{
    border: 1px solid black;
    height: 36vh;
    padding:20px;
    border-radius: 7px;
}
.analyse-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.analyse-subcontainer {
    margin: 20px;
}
.input-field {
    padding: 10px;
    border-radius: 5px;
    width: 81%;
    margin-top: 10px;
}
.prediction-results {
    width: 100%;
    height: 80vh;
    background-color: #D5E2EE;
    margin-top: 20px;
    border-radius: 10px;
}
.MuiSlider-root {
    color: #1C629E !important;
}