.green-status {
  border-radius: 1.5vw;
  padding: 0.3vw 0.6vw;
  background-color: #04724d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.table_data_scroll {
  overflow-x: auto !important;
  border-radius: 0.75rem !important;
}

.table_data_scroll::-webkit-scrollbar {
  display: none;
}
.sticky_class {
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  background-color: rgb(244, 244, 244);
  position: sticky;
  top: 0px;
  z-index: 10;
  padding-top: 1vh;
}
.padding_top_0vh {
  padding: 0vh 1vh 1vh 1vh !important;
}
.table-scrollbar {
  height: 50vh;
  overflow-x: auto !important;
  border: 0.0625rem solid #e4e4e4 !important;
  border-radius: 0.75rem !important;
  box-shadow: none !important;
}

.table-scrollbar::-webkit-scrollbar {
  display: none;
}

.compare_button {
  width: 10.6875rem;
  background-color: #2f67f8;
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inv_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invstatus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1rem;
}

.invTemp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
}

//Comapare Inv style
.download-btn {
  background-color: #bbd6ff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  width: 55%;
}

.download_icon {
  width: 1rem;
}

.inverter_icon {
  width: 2.5rem;
}

.add_icon {
  width: 4rem;
}

.playground_heading {
  background-color: #bbd6ff;
  border: 0.0625rem solid #f5fcf6;
  padding: 0.5rem 1rem;
  width: 100%;
}

.compareInvgraph-container {
  width: 100%;
  border-radius: 1.25rem;
  background: white;
}

.compare-inv-subcontainer {
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  padding-bottom: 2rem;
  width: 100%;
  padding: 1rem;
}

.compare_invGraphs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

//InverterDashboard Style:
.inv-dashbrd-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0px !important;
  margin-left: 0px !important;
  gap: 0.5rem;
  position: relative;
}

.scrolling-grid-two {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  border: none;
  flex-direction: column;
  gap: 0.625rem;
}

.scrolling-grid-two::-webkit-scrollbar {
  display: none !important;
}

.inner-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0px !important;
  margin-left: 0px !important;
  gap: 0.5rem;
}

.total_power_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 0.75rem !important;
  border: 0.0625rem solid #d8d8d8 !important;
}

.inv_total_paper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  justify-content: space-between;
  background-color: transparent;
  box-shadow: none !important;
  height: 100%;
  border-radius: 0.75rem !important;
}

.inv_total_paper {
  .Poppins_24px_rem {
    color: #2f67f8;
  }
}

.total_power_yield {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.justify_space_betwn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column_flex-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}
.inv_table_max{
  overflow-y: auto;
height: 100%;
max-height: 50vh;
}
.inv_table_max::-webkit-scrollbar {
  display: none;
}
.rul_table_max{
  overflow-y: auto;
height: 100%;
max-height: 35vh;
min-height: 35vh;


}
.rul_table_max::-webkit-scrollbar {
  display: none !important;
}
#rul_tables{
  border-radius: 0.75rem !important;
  border: 0.0625rem solid #d8d8d8 !important;
}
.rul-scrollbar-abt{
  height:100% !important;
 
  border-radius: 0.75rem !important;
  box-shadow: none !important;
}
.center_center_column {
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.75rem;
  border: 0.0625rem solid #d8d8d8;
  flex-direction: column !important;
  align-items: center;
  gap: 1rem;
}

.paper_inv {
  height: 100%;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: none !important;
  border-radius: 0.7rem !important;
  padding: 0rem !important;
  border: 0.0625rem solid #969696;
  background: transparent !important;
  flex-direction: column !important;
}

.div_parameter {
  background-color: #FCD303;
  padding: 0.5rem;
  width: 100%;
  border-radius:
    0.7rem 0.7rem 0rem 0rem;

}

.center_center_flex_gap {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  // gap: 0.5rem;
}

.flex-center_transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent !important;
  margin-bottom: 0.5rem;
}

.gen-power_paper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  background-color: transparent;
  box-shadow: none !important;
}

.gen-power_paper {
  .Poppins_24px_rem {
    color: #212121;
  }
}

.center_center_flex {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent !important;
}

.column_gap_transparent {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  background-color: transparent;
  box-shadow: none !important;
}

.divider_14rem {
  border: 0.0625rem solid #d9d9d9 !important;
  height: 16.5rem !important;
  margin: 0rem 0.4rem !important;
}

.imp_notificatn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  box-shadow: none !important;
  gap: 0.9375rem !important;
  padding-bottom: 1rem !important;
  border-radius: 0.75rem !important;
  flex-direction: column !important;
  border: 0.0625rem solid #d9d9d9;
}

.imp_note_paper {
  height: 100%;
  padding: 0px !important;
  width: 100% !important;
  box-shadow: none !important;
  border-radius: 0.75rem !important;
}

.imp_note_padding {
  padding: 1rem 2rem 0.5rem 1rem;
}

.imp_note_padding {
  .Poppins_24px_rem {
    color: #212121;
  }
}

.divider_mt_border {
  margin-top: 0.5rem !important;
  border: 0.0625rem solid #d9d9d9 !important;
}

.flex-start_baseline {
  height: 100%;
  width: 100% !important;
  box-shadow: none !important;
  display: flex;
  justify-content: baseline;
  align-items: flex-start;
}

.flex-start_baseline {
  .Poppins_18px_rem {
    padding: 0.5rem 2rem;
  }
}

.flex_border_borderRadius {
  display: flex;
  border-radius: 0.75rem !important;
  border: 0.0625rem solid #d8d8d8 !important;
}

.column_gap_radius_padding {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100% !important;
  box-shadow: none !important;
  border-radius: 0.75rem !important;
  padding: 1rem;
}

.inv_list {
  .raleway_18px_rem {
    font-weight: 700;
  }
}

.compare_button {
  .raleway_12px_rem {
    color: white;
  }
}

.sticky_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 0.75rem;
  right: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.full_width {
  width: 100% !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

//Inverter.js style
.inv_table_container {
  position: relative !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  padding: 0rem !important;
  border: none !important;
  margin: 1vh 0vh !important;
}

.inv_names_container {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  gap: 0.4rem;
  background-color: white !important;
}

.only_flex-start {
  display: flex;
  justify-content: flex-start;
}

.inv_time {
  font-family: "Poppins";
  color: #097844;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: #F7FAFF;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inv-names_grid {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  background-color: transparent !important;
  overflow-x: auto;
  flex-wrap: nowrap !important;
}
.inv-names_grid::-webkit-scrollbar{
  display: none !important;
}
.flex-start_gap {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
}

.inv-names_paper {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  width: 100%;
  border-radius: 0.5625rem !important;
  padding: 0.28125rem 0.84375rem;
}
.table-text{
  cursor: pointer;
  font-weight:400;
  font-family: "Poppins";
  color: #212121;
  font-size: 1rem;
}
.inv-names_div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 1rem;
}

.ideal_inv_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 94%;
}

.flex_container {
  display: flex;
  justify-content: space-between;
  width: 78%;
  align-items: stretch;
  display: flex;
  height: 100%;
}

.capacity_paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  width: 100%;
  border-radius: 1rem !important;
  padding: 1.44rem 2.4rem;
  background-color: #ffff;
  border: 0.0625rem solid #d8d8d8;
}

.flex-start_space-between {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.inv_genPower {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.smb-one-div_padding {
  padding: 0.4rem !important;

}

.only_column_gap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
}

.only_column_gap {
  .raleway_16px_rem {
    color: #212121;
  }
}

.flex_baseline {
  display: flex;
  gap: 0.54rem;
  justify-content: flex-start;
  align-items: baseline;
}

.inv_param_div {

  padding: 0.625rem;
}

.dmy_grid_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px !important;
  margin-left: 0px !important;
  box-shadow: none !important;
  width: 100%;
  border-radius: 1rem !important;
  padding: 1.2rem 1rem;
  background-color: #ffff;
  border: 0.0625rem solid #d8d8d8;
}

//InverterDetails.js style
.fullWidthCenteredFlexStart {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0px !important;
  margin-left: 0px !important;
  gap: 0.625rem;
  padding-top: 0.625rem;
}

.center_center_grid_p0 {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  padding: 0px !important;
  background-color: transparent !important;
}

.center_flex-start_gap_bg {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between;
  align-items: stretch !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  background: transparent !important;
}

.inv-insight-one {
  width: 100% !important;
  justify-content: space-between !important;
  height: 100% !important;
  display: flex;
  border-radius: 0.75rem !important;
  flex-direction: column;
  align-items: center;
  background-color: transparent !important;
  gap: 0.5rem;
  padding: 0rem 1rem;
  box-shadow: none !important;
}

.progressBarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.progressBarWrapper {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-bottom: 1rem;
}

.semicircle-scale {
  .Poppins_36px_rem {
    position: absolute;
    color: #2f67f8;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
  }
}

.forecasted_paper {
  width: 100% !important;
  border-radius: 0.75rem !important;
  box-shadow: none !important;
}

//CompareInverters.js style
.center_center_grid-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
  border: none;
  padding: 0rem 1rem;
}

.flex-column-paper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: transparent !important;
  box-shadow: none !important;
}

.flex-scroll-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
  border: none;
  overflow-x: auto;
  white-space: nowrap;
  padding: 1rem 0rem;
}

.flex-scroll-container::-webkit-scrollbar {
  display: none !important;
}

.inline_block {
  display: inline-block;
  margin-right: 1rem;
}

.column-center-paper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: #fee4c1 !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
  padding: 2.4375rem 2.8125rem;
  width: 100%;
}

.grid-item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: transparent !important;
  border: none !important;
}

.paper-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  border-radius: 0.43375rem !important;
  border: 1.39px solid #646464 !important;
  box-shadow: none !important;
  padding: 1.692rem 1.952rem;
}

.fault_grid {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: transparent !important;
  border: none !important;
}

.fault-paper {
  display: flex;
  flex-direction: column;
  gap: 1rem !important;
  align-items: center;
  justify-content: flex-start !important;
  width: 100% !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.fault-ul-style {
  margin-top: 0.625rem;
  padding: 0.625rem;
  cursor: pointer;
}

.center_center_full-width {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form-group {
  margin-top: 0.625rem !important;
  text-align: center !important;
  padding-left: 0.9375rem !important;
  overflow: auto !important;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 0.625rem !important;
}

.formControlLabel {
  width: 10.6875rem;
  height: 2.625rem;
  font-size: 0.75rem;
  font-family: "Poppins" !important;
  border: 0.0625rem solid black;
  border-radius: 2.4375rem;
  display: flex !important;
  flex-direction: row;
  padding: 0px 10px;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center !important;
  text-transform: capitalize;
}


//rul 
.equipment_rul{
  box-shadow: 0px 0px 4px 0px #1A141440;

}
.border_20{
    border-radius: 20%;
}
.height_100{
  height: 100% !important;
}
.timeframe_cal{
  border: 1px solid #646464 !important;
    border-radius: 0.7vw !important;
    padding: 0.5rem;
    min-width: 14rem;
    width: fit-content;
  
}
@media screen and (max-width: 992px) {
  .capacity_paper {
    padding: 1rem !important;
  }
  .ideal_inv_box .Poppins_32px_rem{
    font-size: 1rem !important;
  }
  .only_column_gap .raleway_16px_rem{
    font-size: 14px !important;
  }
  .flex_baseline .Poppins_36px_rem{
    font-size: 18px !important;
  }
  .flex_baseline .Poppins_18px_rem{
    font-size: 14px !important;
  }
  .status_temp_container{
    flex-direction: column !important;
  }
  .inv_genPower{
    gap: 0.9rem !important;
  }
}

@media screen and (min-width: 829px) and (max-width: 1350px) {
  .compare-inv-subcontainer {
    flex-direction: column !important;
  }
}