.services-draw-line-two {
  top: -22vh;
    left: 86vw;
    height: 38vh;
}
.service-draw-line-one{
  top: 13vh;
  width: 42vw;
}
.service_imgs{
  width: 100%;
  border-radius:2.5vh 2.5vh 27vh 27vh;
  margin-top: 4vh;
}
//EachService.js style starts
.service-draw-line {
  top: -17vh;
  left: 0;
  width: 43vw;
}

.our-services-draw-line-two {
  left: 85vw;
  height: 46vh;
}

.our-service-img {
  border-radius: 3vw;
}

.feature-headings {
  width: 33%;
  padding: 5vh 2.3vw;
}

.feature-details {
  display: flex;
  flex-direction: column;
  padding: 5vh;
  gap: 2vw;
  width: 65%;
}

.service-feature-container {
  display: flex;
  margin-bottom: 12vh;
}

.service-fea-img {
  border-radius: 1.5vw 1.5vw 10vw 1.5vw;
}

.why-choose {
  display: flex;
  gap: 9vw;
}

.why-container {
  padding: 3.5vw;
  border-radius: 4vw;
  background: #111;
  width: 95%;
  margin-bottom: 12vh;
}
.why-img-block{
  border-radius: 2.2vw 2.2vw 10vw 2.9vw;
    background: #FFEBCD;
}
.why_img{
  border-radius: 1vw 1vw 10vw 1vw;
    width: 35vw;
}

@media screen and (max-width: 992px) {
  .services-draw-line-two{
    display: none !important;
  }
  .our-service{
    display: none !important;
  }
  .service-box{
    margin-top: 8vh !important;
    margin-bottom: 0vh !important;
  }
  .services-cards{
    padding:20px !important;
    border-radius: 1rem 1rem 5rem 5rem !important;
  }
  .service_imgs{
    border-radius: 1rem 1rem 5rem 5rem !important;
  }
  .our-services-draw-line-two {
    display: none !important;
  }
  .each-service-box{
    margin-top: 10vh !important;
  }
  .service-margin{
    margin-bottom: 4vh !important;
  }
  .feature-headings {
    display: flex !important;
    gap: 15px !important;
}
.feature-details {
  margin-top: 24vh !important;
  margin-left: -60vw !important;
}
#fea-descriptn{
  width: auto !important;
  margin-left: -40vw !important;
  text-align: justify !important;
}
#fea-title{
  margin-left: -40vw !important;
}
.feature-headings {
  width: 100% !important;
  overflow-x: auto !important;
}
.why-choose {
  flex-direction: column !important;
}
.why_img {
  width: 100% !important;
}
.service-draw-line {
  display: none !important;
}
.service-fea-img {
  width: 88vw !important;
  margin-left: -40vw !important;
}
}

