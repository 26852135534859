.failure_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.3rem;
  padding: 1rem 0.5rem;
}
.open-close-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  cursor: pointer;
}
.notes-container {
  background-color: #e6f1ed;
  border-radius: 1.25rem;
  border: 0.0625rem solid #d8d8d8;
  padding: 0rem 1rem 1rem 0rem !important;
  height: 30vh;
  overflow-y: auto !important;
}
.notes-container::-webkit-scrollbar{
  display: none !important;
}
.expanded_container {
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.failure-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: none !important;
}
.failure-data-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem !important;
    background-color: #ffff;
    box-shadow: none !important;
  border: 0.0625rem solid #d8d8d8 !important;

  }
 .report_img{
    width: 2.5rem;
     border-radius: 50%;
  }
.notes_bg {
  background-color: #fff6eb;
  border-radius: 1.25rem 0rem 1.25rem 0rem;
  padding: 0.5rem 1.875rem 0.5rem 1.875rem;
  width: max-content;
}
.expanded_paper {
  border-radius: 0rem 0rem 0.625rem 0.625rem !important;
  box-shadow: none !important;
  border: 0.0625rem solid #d8d8d8;
  padding: 1rem !important;
}
#search-input_report {
  background-color: transparent !important;
  width: 100% !important ;
  border: 0.0625rem solid #969696 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem !important;
  border-radius: 1.2rem !important;
}
.border_padding{
 border: 1px solid #E2E8F0;
 padding: 1rem;
 border-radius: 0.5rem;
 width: 100%;
 height:100%;
 display: flex;
 gap: 1rem;
 align-items: center;
 justify-content: center;
}
.insight_button{
  background-color: #2F67F8;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 0.4rem;
  width: 100%;
}
.kpi-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  margin: 0.5rem 0rem;
}
.flex_gap{
  display: flex;
  gap: 1rem;
  margin-left: 1.5rem;
  height: 100%;
}
.flex_element{
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.column_element{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.blue_line {
  height: 4vh;
  border-left: 2px solid #2F67F8;
  margin: 0 auto;
}
.cause_container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
  border: 1px solid #E2E8F0;
  box-shadow: 0px 0px 4px 0px #00529A;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  height: 100%;
  width:100%;
}
.align_self_center{
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 2rem;
}
.space-around_div{
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.expected_div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 82, 154, 0.2509803922);
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  width: 11vw;
}
.gap_point_3rem{
gap: 0.3rem;
}
.padding_point_5rem{
  padding: 0.5rem !important;
}
@media screen and (max-width:600px){
.media_600_flex_col{
  flex-direction: column !important;
}
}
@media screen and (min-width:800px) and (max-width:1200px){
  .report_img{
    width: 1.5625rem !important;
  }
  .report_400{
    font-size: 0.7rem !important;
  }
}

@media screen and (max-width:452px){
  .align_self_center {
    padding: 2rem !important;
  }
}
@media screen and (max-width:600px){
  .media_600_height_auto {
    height: auto !important;
  }
}
@media screen and (max-width:992px){
.mobile_hide{
  display: none !important;
}
.mobile_flex_fullwidth{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.mobile_flex_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}
.failure-Container{
  justify-content: flex-start !important;
}
.mobile_nogap{
  gap: 0rem !important;
}
.failure_title{
  align-items: center !important;
  justify-content: center !important;
}
.mobile_flex-col {
  flex-direction: column !important;
}
#search-input_report{
  padding: 0.5rem !important;
}
.expanded_container{
  align-items: stretch !important;
}
.mobile_height_overflow {
  height: 20vh !important;
  overflow-y: auto !important;
}
.flex_col_paper{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;
}
.flex_div{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem !important;
}
.media_992px_width_80 {
  width: 80% !important;
}
.open-close-btn{
  justify-content: flex-end !important;
}
.media_overflow-y{
  height: 3rem !important;
  overflow-y: auto !important;
}
}