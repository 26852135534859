.predict-container {
  width: 100%;
  max-width: 100vw;
  overflow-y: auto;
  padding: 20px;
}
.predict-searchbar {
  width: 300px;
  margin-top: 60px;
}

.ForwardRef-root-8 {
  background-color: #daeeff;
}

.model-container {
  margin-top: 32px;
}
.nomodels h1 {
  align-items: center;
  margin: 10% 0%;
  padding: auto;
}
.display-container {
  max-width: 100%;
  max-height: 10%;
  min-height: 50px;
  background-color: #daeeff;
}
.content-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-dropbox{
  background-color: #daeeff;

border-style: dotted;
border-color:  gray ;
max-height: 60%;
text-align: center;

}
.imgvector{
    padding: 60px 20px;
}
.content-img{
  width: 60px;
  height: 60px;
  border: 1px solid gray;
  margin:10px;
  border-radius: 20%;
}