.chat-sidebar {
  background-color: #d9e5f0;
  width: 18%;
  height: 100%;
  border-radius: 1rem;
  overflow-y: auto;
  padding: 2rem 0rem 1rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: relative;
}

.chat-sidebar::-webkit-scrollbar {
  display: none;
}
.history_two::-webkit-scrollbar {
  display: none;
}
.history_two {
  padding: 0.6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 90%;
  overflow-y: auto;
}
.customPopoverPaper {
  border-radius: 0.625rem !important; // Apply custom border-radius
  left: 12.8vw !important; // Set left position
  box-shadow: none !important;
  padding: 0.625rem 0rem !important;
  gap: 0.625rem;
}

.message-box {
  border-radius: 1.25rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  border: 0.0625rem solid #04724d !important;
}

.refresh-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.0625rem solid #04724d;
  border-radius: 1.25rem;
  padding: 0.625rem;
}

.home-container-chatbot {
  width: 100%;
  overflow-y: auto;
  background-color: white;
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
}

.home-container-chatbot::-webkit-scrollbar {
  display: none;
}
.history_one {
  display: flex;
}
.history_one_text {
  padding: 0rem 0.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: 100%;
}
.border-right {
  border-left: 0.25rem solid #00529a;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
.history-div {
  display: flex;
  align-items: center;
  padding: 0.625rem;
  gap: 0.625rem;
  justify-content: space-between;
  border-radius: 0.625rem;
  cursor: pointer;
}

.image-div-chat {
  background-color: #fff;
  padding: 0.625rem;
  border-radius: 0.625rem;
}

.popover-menu {
  display: flex;
  gap: 0.9375rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.handleclose-div {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
}

.handleclose-div::-webkit-scrollbar {
  display: none !important;
}

.handleclose-subdiv {
  position: relative;
  right: 0rem;
  top: 0rem;
  display: flex;
  cursor: pointer;
  background-color: #d8d8d8;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  align-self: end;
  margin: 0.5rem;
}

.chat-gif-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-div-chatbot {
  align-items: center;
  justify-content: space-around;
  display: flex;
  gap: 1.25rem;
  width: 92%;
}

.search-div-two {
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
  padding: 0.9375rem;
  cursor: pointer;
  width: -webkit-fill-available !important;

  background-color: #ffff;
  /* Default background color */
  border-radius: 0.625rem;
}
.send-arrow {
  margin: 0.3125rem;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  margin-right: -0.625rem;
}
.arrow-bg {
  background-color: #b1d3c8;
  cursor: pointer;
  opacity: 1;
  border: none;
  margin-top:0.25rem;
}
.speaker-bg{
  background-color:rgb(235 235 235);
  opacity: 1;
  border: none;
  padding: 0.5rem !important;
  width: 2rem !important;
  height: 2rem !important;
  cursor: pointer;

}
.arrow-div {
  padding: 1rem;
  border-radius: 0.3125rem;
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
}

.main-message-box {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 1rem;
}

.text-field-chatbot {
  font-weight: 400 !important;
  font-style: italic !important;
  color: #00000080 !important;
  padding: 0rem !important;
}

.msg-send-chatbot {
  cursor: pointer;
  width: 2rem !important;
  height: 2rem !important;
}

.chat-middle-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95%;
}
.history-title {
  display: inline-block;
  white-space: nowrap; /* Prevents wrapping */
}

.scroll-container {
  max-width: 7vw; /* Adjust this value to show the desired initial number of characters */
  overflow-x: scroll; /* Adds horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping */
}

.scroll-container::-webkit-scrollbar {
  display: none !important;
}
.css-igs3ac {
  border-color: transparent !important;
}
#text-chat {
  padding: 0.9rem 0.625rem !important;
}

.full_btn{
  cursor: pointer;
  border:none !important;
  background: none !important;
  align-self: flex-end;
}




@media screen and (max-width: 992px) {
  // .chat-sidebar {
  //   display: none !important;
  // }
  .handleclose-div {
    width: auto !important;
  }
  .chat-gif-div .Poppins_16px_400{
    text-align: center !important;
    font-size: 12px !important;
  }
  .search-div-chatbot{
    flex-direction: column !important;
    gap: 0.5rem !important; 
  }
  .scroll-container {
    max-width: 29vw !important;
  }
  .history_two {
    height: 97% !important;
  }
  .chatbot_Paper{
    top: 45% !important;
    height: 97% !important;
    margin: 2rem 0.5rem 0rem 0rem !important;
  }
  .chat-gif-div img {
    width: 7rem !important;
  }
  #welcome_text {
    font-size: 16px !important;
  }
  .search-div-two {
    padding: 0.5rem !important;
  }
  .arrow-div {
    width: auto !important;
    height: auto !important;
    padding: 0.5rem !important;
  }
  .Poppins_15px_black {
    font-size: 12px !important;
  }
  .handleclose-subdiv {
    margin: 0.5rem !important;
    padding: 0.5rem !important;
  }
  .msgview{
    width: 100% !important;
    padding: 1rem 0rem !important; 
  }
  #chatbot_Paper_voice {
    width: 96% !important;
    right: auto !important;
}
#chat-mic {
  height: 2rem !important;
}
.chatbot-icon {
  width: 1.9rem !important;
}
.divider_fullwidth{
  width: 100% !important;
  margin: 0.8rem 0rem !important;
  height: auto !important;
}
.flex_colmn{
  flex-direction: column !important;
  align-items: flex-start !important;
}
.min_width_11rem{
  min-width: 11rem !important;
}
.min_width_4rem{
  min-width: 4rem !important;
}
.icon-css{
  width: auto !important;
  width: 1.5rem !important;
}
.css-17v15rn{
  width: 91% !important; 
}
}

@media screen and (max-width: 700px) {
  .rdrMonth{
    width: 100% !important;
  }
  .calender-container {
    width: 100% !important;
    overflow-x: auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1299px) {
  #text-chat {
    padding: 0.65rem 0.5rem !important;
  }

  .image-div-chat {
    padding: 0.35rem;
  }
  .arrow-div {
    width: 2rem !important;
    height: 2rem !important;
  }
  .customPopoverPaper {
    left: 21.8vw !important;
  }
  .msg-send-chatbot {
    width: 1.4rem !important;
    height: 1.4rem !important;
  }
  .text-field-chatbot {
    padding: 0rem !important;
  }
  .scroll-container {
    max-width: 5vw;
  }
  .chat-middle-div {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .chat-middle-div {
    height: auto !important;
  }

  // .history-title {
  //   display: none;
  // }
}
///voice note
#div_data{
  width:100% !important;
}
.recording-animation {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.2) 75%);
  background-size: 200% 100%;
  animation: wave 1.5s infinite;
}

@keyframes wave {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.mic-animation {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.text-field-wrapper {
  position: relative;
}

.mic-circle {
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: .5s;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
  
  i {
    color: #b2b1b1;
    font-size: 23px;
    transition: .9s;
  }
      
  &:before {
    content: '';
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    opacity: .2;
    z-index: -1;
    position:absolute;
  }
}

.mic-circle.active {
  
  &:before {
      background: gray;
      animation: bounce .8s ease-in-out infinite .5s;
    }
    
    i {
      color: #ffffff;
    }
}


@keyframes bounce {
0% {
  transform: scale(1);
};
25% {
  transform: scale(1.4);
}
75% {
  transform: scale(1);
}
100% {
  transform: scale(1.3);
}


}

.audiobtn{
  width: 2.5rem !important;
  height: 2.5rem !important;
  background-color: #ff5500;
}
.audioNew{
  cursor: pointer;
  background-color: #aab4c9 !important;
  border-radius: 50%;
  padding: 0.25rem !important;
  border: none !important;
}
.loaderdiv{
  width: fit-content !important;
  margin-left: 0.625rem;
}