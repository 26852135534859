.pdfchat {
  width: 100%;
  height: 100%;
  display: flex;
    flex-direction: column;
    align-items: center;
  overflow-y: auto;
  padding-bottom: 10px;
  background: linear-gradient(
    168deg,
    #101b2d 0%,
    #224356 28.13%,
    #203c48 56.25%,
    #1d3841 81.25%,
    #14202e 100%
  );
}
/* to hide the textlayer in the pdf */
.react-pdf__Page__annotations.annotationLayer {
  opacity: 0;
  pointer-events: none;
  display: none;
}
.react-pdf__Page__textContent {
  pointer-events: none;
  display: none;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.chat-response {
  margin: 10px;
  width: 95%;
  max-height: 100%;
  min-height: 100%;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pclass {
  font-family: "Montserrat";
  line-height: 23px;
  font-style: normal;
  display: flex;
  align-items: center;
}
.fleximg {
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: center;
}
//drag and drop styles
.css-cxfgs5 {
  padding: 10vh 8vw !important;
  border-radius: 5px;
  left: 57% !important;
}
// .react-pdf__Page__canvas {
//   height: 90vh !important;
//   width: 50vw !important;
// }
.chat-gpt-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filename{
  overflow-x: auto;
  display: flex;
  margin-top: -10px;

}
.filename::-webkit-scrollbar {
  display: none;
}