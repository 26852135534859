.containerdata {
  margin: 40px;
  padding: 20px;
  align-items: center;
  max-width: 100vw;
  width: 100%;
  overflow-y: auto;
}
.contentone {
  display: flex;
  justify-content: space-between;
}
.searchbar {
  align-items: flex-start;
}
.filterbar {
  display: flex;
  align-items: center;
  background-color: #daeeff;
  border-radius: 6%;
}
.modals {
  margin-top: 40px;
  padding: 0px 10px;
  padding-bottom: 0px;
  max-width: 100%;
  background-color: #f8f8f8;
  border-radius: 1%;
  // box-shadow: 0px 5px 5px;
  border: 1px solid #bebebe;
}
.contentwo {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.subcontent {
  display: flex;
  justify-content: space-between;
}
.cardcls {
  height: 3rem;
}
.h55 {
  font-size: small;
  font-weight: normal;
  color: gray;
}

.config-parameter-container {
  display: flex;
  background-color: #f5f5f5;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 0px 20px 40px 20px;
  margin: 40px 0px 0px 0px;
}
.parameter-input {
  height: 40px;
  width: 70%;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0px 0px 0px 10px;
  font-family: "Poppins-Regular";
  margin: 5px 0px 0px 0px;
}
.stepper {
  margin: 30px;
  padding: 5px;
}
.menubar {
  position: absolute;
  top: 40px;
}

.row-aligned {
  display: flex;
  flex-direction: row;
}

.column-aligned {
  display: flex;
  flex-direction: column;
}
.button-container {
  display: flex;
  padding: 15px;
  background-color: #1c629e;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 15rem;
}
.your-models-drawer-container {
  padding: 50px 20px 20px 40px;
  background-color: #f5f5f5;
  border: 1px solid #bebebe;
  border-radius: 10px;
  margin: 60px 30px 20px 30px;
}

.your-modals-hyper-parameter-container {
  margin: 30px 0px 0px 0px;
}

.evaluate-model-graphs-container {
  width: 40%;
  border: 1px solid #bebebe;
  border-radius: 10px;
  margin-left: 33px;
  background-color: #f5f5f5;
  padding: 15px 15px 20px 15px;
}

.evaluate-model-accuracy-container {
  display: flex;
  flex-direction: row;
  width: 93%;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #bebebe;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  justify-content: space-between;
  margin: 10px 2px 19px 33px;
}

.css-10mg1vw-MuiStepper-root {
  padding-top: 60px;
}

.graphs-container {
  display: flex;
}
.confusionGraph {
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #bebebe;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  margin: 10px;
}
.nomodels h1{
  
  align-items: center;
  margin: 10% 0%;
  padding: auto;
}