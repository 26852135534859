.grid_border {
  border-radius: 0.625rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  border: 0.0625rem solid #d8d8d8;

  background: #ffffff;
}
.grid-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  gap: 0.4rem;
}
.grid-container_inv{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
}
.paper_borderless {
  height: 100%;
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: none !important;
  border-radius: 0rem !important;
  padding: 0rem !important;
  border: none !important;

  background: transparent !important;
}
.paper_borderless_center {
  height: 100%;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: none !important;
  border-radius: 0rem !important;
  padding: 0rem !important;
  border: none !important;

  background: transparent !important;
}
.paper_padding_one {
  height: 100%;
  background-color: #ffffff;
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  box-shadow: none !important;
  gap: 1rem;
  align-items: flex-start;
  padding: 1rem !important;
  border: 0.0625rem solid #d8d8d8;

  border-radius: 0.625rem !important;
}
.paper_padding_one_center {
  height: 100%;
  background-color: transparent !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  box-shadow: none !important;
  gap: 1rem;
  align-items: center;
  padding: 1rem !important;
  border: 0.0625rem solid #d8d8d8;
  flex-direction: column !important;

  border-radius: 0.625rem !important;
}
.paper_padding_nogap {
  height: 100%;
  background-color: white !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  box-shadow: none !important;
  align-items: center;
  padding: 1rem !important;
  border: 0.0625rem solid #d8d8d8;
  flex-direction: column !important;

  border-radius: 0.625rem !important;
}
.paper_padding_one_override {
  height: 100%;
  width: 100% !important;
  display: flex;
  box-shadow: none !important;
  gap: 1rem;
  border: 0.0625rem solid #d8d8d8;

  border-radius: 0.625rem !important;
  justify-content: space-between !important;
  align-items: center !important;
  background: transparent !important;
  padding: 0.4rem 0.6rem !important;
}

.paper_column {
  background: transparent !important;
  flex-direction: column;
  gap: 1.5rem;
  border: none !important;
  height: 100%;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
  align-items: center;
  padding: 0rem !important;

  border-radius: 0rem !important;
}
.abt-first-grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent !important;
  border: none !important;
  height: 100%;
  width: 100% !important;
  flex-direction: column !important;
  gap: 0.75rem;
}
.flex_start_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: transparent !important;
}

.icon_div {
  border: 0.0625rem solid #d8d8d8;
  border-radius: 0.9375rem;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solar_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  border: 0.0625rem solid #d8d8d8 !important;
  border-radius: 0.625rem;
  padding: 0.5rem;
  gap: 1rem;
  height: 8.5rem;
}
.grid_padding {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 1rem !important;
  border-radius: 0.75rem !important;
  border: 0.0625rem solid #d8d8d8 !important;
  box-shadow: none !important;
  width: 100% !important;

  gap: 1rem;
}
.table_data_scroll-abt {
  overflow-x: auto !important;
  background-color: white;
  border-radius: 0.75rem !important;

}
.gauge-values{
  padding:0.5rem 1rem;
  background-color: rgb(47, 103, 248);
  border-radius: 0.325rem;
  text-align: center;
  
}
.table_data_scroll-abt::-webkit-scrollbar {
  display: none;
}
.table-paper-abt {
  height: 100%;
  width: 100% !important;
  display: flex;
  justify-content: center;
  box-shadow: none !important;
  border-radius: 0.5rem !important;
  padding: 0rem !important;
  border: 0.0625rem solid #d8d8d8;
}

.cummulative_paper {
  height: 100%;

  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;
  background-color: transparent !important;
  box-shadow: none !important;
}

.div_info {
  padding: 0.8rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: space-between;
  height: 8.5rem;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
}

.center_paper {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 100%;

  width: 100% !important;

  background: transparent !important;
}
.image_circle {
  padding: 0.375rem;
  border: 0.0625rem solid #d9d9d9 !important;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active_green {
  display: flex;
  gap: 0.5rem;
  background: #b1d3c8;

  border: 0.0625rem solid #04724d;
  padding: 0.5rem 1rem;
  border-radius: 1.625rem;
}
.metrix-grid {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100% !important;

  background: transparent !important;
}
.metrix-paper {
  height: 100%;

  width: 100% !important;

  display: flex;
  justify-content: flex-start;
  box-shadow: none !important;
  border: 0.0625rem solid #d8d8d8 !important;
  border-radius: 0.625rem !important;
  padding: 0.625rem;
}
.metrix_div {
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.raleway_28px_abt {
  font-family: "Raleway";
  font-size: 1.5rem;
  font-weight: 700;
  color: #212121;
}

.Poppins_32px_abt {
  font-family: "Poppins-Regular";
  color: #212121;
  font-size: 2rem;
  font-weight: 700;
}
.abt-tableText {
  font-family: "Poppins-Regular";
  color: #534D59;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0.8rem ;


}
.abt-tableText-val {
  font-family: "Poppins-Regular";
  color: #1B2128;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.8rem 0.6rem;
}


.table-scrollbar-abt{
  height:100% !important;
 
  border: 0.0625rem solid #e4e4e4 !important;
  border-radius: 0.75rem !important;
  box-shadow: none !important;
}
.abt_table_scrol{
  overflow-x: auto !important;

}
.abt_table_scrol::-webkit-scrollbar {
  display: none;
}
