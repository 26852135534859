.first_grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border: none !important;
  border-radius: none !important;
  height: 100%;
}
.surges-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem !important;
  border: none !important;
  background-color: #2f67f8 !important;
}
.flex_col_gap{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
}
.surges-grid-drawer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  border-radius: none !important;

  background-color: #fef1b1 !important;
}
.first_paper {
  height: 100%;
  width: 100% !important;
  box-shadow: none !important;
  background: transparent !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  border-radius: none !important;
  padding: 0.5rem !important;
  padding-bottom: 0rem !important;
}
.second_paper {
  height: 100%;
  width: 100% !important;
  box-shadow: none !important;
  background: transparent !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: none !important;
  border-radius: none !important;
  padding: 0rem !important;
}
.second_paper_smb {
  height: 100%;
  width: 100% !important;
  box-shadow: none !important;
  background: transparent !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: none !important;
  border-radius: none !important;
  flex-direction: column !important;
  padding: 0rem !important;
  gap:1rem;
}
.transparent-grid {
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column !important;
  gap: 1rem;
  box-shadow: none !important;
  background: transparent !important;
}
.severity-paper {
  background: #ffffff;
}
.severity-paper-drawer {
  background: #ffffff;
  box-shadow: none !important;
}
.main-drawer-grid {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0 !important;
  margin-left: 0 !important;
  gap: 1rem;
  padding: 1rem 2rem !important;
}
.border_none_transparent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent !important;
  border: none !important;
  border-radius: 0.635rem !important;
  height: 100%;
}

.severity-div {
  display: flex;

  align-items: center;
  justify-content: space-between;
  box-shadow: 0rem -0.0625rem 0rem 0rem #e1e1e1 inset;
  padding: 1.25rem 1.25rem 1.3125rem 1.25rem;
}
.severity-div-high {
  display: flex;

  align-items: center;
  justify-content: space-between;
  box-shadow: 0rem -0.0625rem 0rem 0rem #e1e1e1 inset;
  padding: 1rem 1.25rem 1.1rem 1.25rem;
}
.table-grid {
  height: 100%;
  display: flex;

  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border: none !important;

  flex-direction: column !important;
  padding: 2rem;
  gap: 1.2rem;
  border-radius: 1.25rem;
  box-shadow: 0px 2px 10px 0px #0000001a;
}
.table-paper {
  // height: 100%;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  padding: 0rem !important;
  border: none !important;
}

.tabTextColor {
  font-family: "Poppins-Regular" !important;
  color: gray !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
.css-1aquho2-MuiTabs-indicator {
  height: 5px !important;
}

.typeText {
  font-family: "Poppins-Regular";
  color: black;
  font-size: 1rem;
  font-weight: 400;
}
.full-width {
  display: flex;
  width: max-content;
}
.div-border {
  border: 1px solid #212121;
  padding: 0.5rem 1.25rem;
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-borderText {
  color: #111111;
  font-family: "Poppins-Regular";
  font-weight: 400;
  font-size: 0.75rem;
}
.table-headText {
  color: #111111;
  font-family: "Poppins-Regular";
  font-weight: 700;
  font-size: 0.75rem;
}
.alram-btn_style {
  padding: 0.625rem !important;
  border-radius: 0.625rem !important;
  background: #2f67f8 !important;
}
.assign-btn_style {
  width: 20vw !important;
  padding: 0.625rem !important;
  border-radius: 0.625rem !important;
  background: #00529a !important;
}
.alram-table {
  overflow-x: auto;

  border: 1px solid #e4e4e4 !important;
  border-radius: 0.75rem !important;
  box-shadow: none !important;
}
.alram-table::-webkit-scrollbar {
  display: none;
}
.checkbox-alram {
  padding: 1rem !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: transparent !important;
  color: black !important;
}
.css-1xo9jt7-MuiTableRow-root.Mui-selected {
  background: #fffbe6 !important;
}
.surge-text {
  color: white;
  font-family: "Poppins";
  font-size: 2rem;
  font-weight: 600;
  padding: 0.8rem;
}
.surge-text-drawer {
  color: black;
  font-family: "Poppins";
  font-size: 2rem;
  font-weight: 600;
  padding: 2rem;
}
.Poppins_secondText {
  font-family: "Poppins";
  color: #1b2128;
  font-size: 0.875rem;
  font-weight: 400;
}
.high-p {
  font-family: "Poppins";
  color: white;
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  background: #ff472e;
  align-items: center;
  justify-content: center;
  box-shadow: 0rem -0.0625rem 0rem 0rem #e1e1e1 inset;
  padding: 1.25rem 1.25rem 1.3125rem 1.25rem;
  text-transform: capitalize;
  padding: 0.3rem 1rem;
  border-radius: 1.625rem;
}
.acknowedgeText {
  font-family: "Poppins";
  color: rgba(0, 0, 0, 0.5);

  font-size: 0.75rem;
  font-weight: 400;
}
.aknowedlge-paper {
  width: 100% !important;
  height: 100%;
  box-shadow: none !important;
  display: flex;
  background: linear-gradient(223.5deg, #fee4c1 2.43%, #ffffff 98%) !important;
  border-radius: 1.25rem !important;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(216, 216, 216, 1);
  padding: 1rem !important;
}
.acknowedgeGrayText {
  font-family: "Poppins";
  color: rgba(0, 0, 0, 0.5);

  font-size: 1.25rem;
  font-weight: 400;
}
.acknowedgeGrayHead {
  font-family: "Poppins";
  color: rgba(0, 0, 0, 1);

  font-style: italic;
  font-size: 1rem;
  font-weight: 400;
}
.div-acknowedge {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  flex-direction: column;
}
.chatbot-paper {
  box-shadow: none !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: none !important;
  background-color: #ffffff;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.625rem !important;
  cursor: pointer;
  position: fixed;
  right: 0;
  bottom: 0;
}
.main-grid-surge {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  gap: 1rem;
  position: relative;
  height: 100%;
  overflow-y: auto;
}
.main-grid-surge::-webkit-scrollbar {
  display: none !important;
}


.css-12q85cb-MuiTableCell-root {
  padding: 4px !important;
}
.css-dsuxgy-MuiTableCell-root {
  padding: 4px !important;
}
.chatText {
  font-family: "Poppins-Regular";
  color: black;
  font-size: 0.85rem;
  font-weight: 400;
  padding-left:1rem;
  text-align: left;
  
}
#usermsg{
  padding: 0rem !important;
}
.chatText img {
  display: flex;
  justify-content: flex-start;
}
.editChat{
  padding:0.625rem;
  cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center
}
.editChat:hover{

  background-color: #ECF5FF;
  border-radius: 50%;
}
.chatTextField {
  display: flex;
  gap: 1.25rem;
  width: 100%;
  margin-top: 0.625rem;
  align-items: center;
  justify-content: center;
}
.text-field {
  border: 0.125rem solid white;
  border-radius: 0.625rem;
  background-color: #fef1b1;
}

.alarm-tableText {
  font-family: "Poppins-Regular";
  color: #534D59;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0.2rem;

}
.alarm-tableText-val {
  font-family: "Poppins-Regular";
  color: #1B2128;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.2rem;
  height: 2.55rem;
  overflow-y: auto;
}
.alarm-tableText-val::-webkit-scrollbar {
  display: none !important;
}
@media (max-width: 768px) {
  .alarm-tableText-val {
    font-size: 0.9rem;
  }

  .high-p {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  .first_paper {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .green-tab svg {
    width: 1.8rem !important;
  }
  .media_width_auto{
    width: auto !important;
  }
  #media_grid{
    display: grid !important;
    grid-template-columns: repeat(2, 2fr) !important;
  }
  .media_flex_col{
    flex-direction: column !important;
    gap: 1rem !important;
  }
  .table-grid{
    padding: 0.5rem !important;
  }
  .media_font_14px{
    font-size: 14px !important;
  }
}
.table-padding{
  padding: 0rem !important;

}