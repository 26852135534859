.toolBar {
  display: flex;
  justify-content: flex-end;
  border-bottom: 0.03125rem solid #fff;
  background-color: #fff;
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(250, 173, 67, 0.4);
  position: absolute;
  z-index: 2;
  min-height: 8vh !important;
  height: 8vh !important;
}
.profile_items_container{
    margin-left: 1rem;
    border-radius: 1rem;
    display: flex;
    padding: 0.1rem;
    gap: 0.5rem;
    z-index: 1;
    flex-direction: column;
}
.avatar_div{
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1565c0;
  padding: 0.5rem;
  cursor: pointer;
  border: 0.0625rem solid #d9d9d9;
}
.profile_items_div {
  background-color: transparent;
  border-radius: 1rem;
  display: flex;
  padding: 0.5rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.profile_dropdown {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0rem 1rem;
  align-items: center;
}
.profile_container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.profile_data{
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.plantdropdown_two{
    display: flex;
    gap: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.tabs_container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  height: 100%;
  overflow-y: auto;
}
.sidebar_tabs{
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0.5rem 1rem;
  align-items: center;
}
.upperbar_main {
  min-width: 100%;
  height: 8vh;
  position: relative !important;
  z-index: 0 !important;
}
.dropdown-div{
  padding: 0.625rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.icon-container {
  gap: 1rem;
  font-size: 1.25rem;
  margin-right: 0.75rem;
  color: #000000;
  display: flex;
  align-items: center;
}
.plantdropdown {
  border-radius: 1rem;
  border: 0.0625rem solid #d9d9d9;
  display: flex;
  gap: 0.625rem;
  padding: 0.3125rem 0.625rem;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
///////
.userData {
  border: 1px solid #cbbdbd;
  background-color: #fff;
  box-shadow: 0rem 0.25rem 0.25rem 1px rgba(126, 126, 125, 0.4);
  height: 104px;
  text-align: left;
  width: 202px;
  padding: 0.75rem 1.5rem;
  font-size: 0.9375rem;
  font-family: "Poppins";
  position: absolute;
  left: 80%;
  z-index: 2;
}
.upperbar-logout {
  border-radius: 0.625rem;
  border: 0.03125rem solid #faad43;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  gap: 0.625rem;
  color: #faad43;
  font-family: "Poppins";
}
.menu_container {
  display: none;
}
.css-hyum1k-MuiToolbar-root {
  min-height: 5vh !important;
}
.css-ank3r8-MuiBadge-badge {
  min-width: 0.3125rem !important;
  height: 0.3125rem !important;
}
@media screen and (max-width: 992px) {
  .icon-container{
    display: none !important;
  }
  .sidebar_logo {
    width: 8rem;
    height: auto;
}
}
@media screen and (min-width: 1200px) {
  .formated-time {
    font-size: 0.7vw !important;
  }
}
@media screen and (max-width: 992px) {
  .menu_container {
    display: block !important;
  }
}
