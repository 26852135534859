.title-description_container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 17rem;
}
.power_grid {
  position: relative; /* Make the grid container relative */
  z-index: 1; /* Keep grid items on top of the SVG background */
}

.power_grid::before {
  content: ""; /* Necessary for the pseudo-element */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="285" viewBox="0 0 1920 285" fill="none"><path opacity="0.8" d="M-273.5 139.343H388.866C388.866 139.343 388.866 259.203 388.866 271.407C388.866 283.611 402.363 283.175 402.363 283.175H744.794C744.794 283.175 758.291 283.611 758.291 271.407C758.291 259.203 758.291 23.1982 758.291 12.9448C758.291 1.17676 771.788 1.17676 771.788 1.17676H1063.23C1063.23 1.17676 1076.23 1.17676 1076.23 12.509C1076.23 22.3851 1076.23 259.203 1076.23 271.407C1076.23 283.611 1089.72 283.175 1089.72 283.175H1423.66C1423.66 283.175 1437.15 283.611 1437.15 271.407C1437.15 259.203 1437.15 139.343 1437.15 139.343H2193.5" stroke="%23FDA737" stroke-width="2"/></svg>');
  background-repeat: no-repeat;
  background-position: top; /* Adjust to your needs */
  background-size: contain;
  position: absolute; /* Position it absolutely within the grid */
  top: -6%; /* Start at the top of the grid */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  height: 100%; /* Take full height of the grid */
  z-index: 0; /* Send it behind the grid content */
}

.power_subG {
  position: relative;
  background-image: url("https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/ychoosePower.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.key_fea_po {
  padding: 2vw 0vw !important;
  border-radius: 1.3rem;
  background-color: #ecf2f7;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
}

.slide_po {
  height: fit-content;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.svg_powers {
  position: relative;
  background-image: url("https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/keyfeatureSmart.png");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.feature-cards {
  background-color: #768898 !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1.5rem !important;
  padding: 1.5rem !important;
  position: relative;
}

.feature-cards:hover {
  #hover_title,
  #hover_black {
    color: #333333 !important;
  }
}

.feature_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.feature-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.power_gridImg {
  width: 100%;
  border-radius: 1rem;
  height: 13vw;
}
.margin_top_1vw{
  margin-top: 1vw !important;
}
.highlight_box {
  padding: 3vw;
  background-image: url('https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/highlightBox.png'); /* Replace with your image path */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  border-radius: 0.6vw;
}

.reason_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #01325c;
  padding: 1rem;
}

.reason-cards {
  border-left: 4px solid #f47920;
  border-radius: 1.25rem !important;
  padding: 1.5rem !important;
}

.add_cards {
  border: 0.25px solid #015399;
  padding: 1rem;
  border-radius: 0.3rem;
  background-color: #ffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add_cards_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1vw 6vw;
  background-color: #ececec;
  gap: 0.1vw;
}

.powerOptix_overlay {
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  width: 51%;
}

.flex-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  background-color: #ffff;
}

.expanded_content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  width: 75%;
  align-items: center;
}
.bg_blues{
  border-radius:50%;
  padding: 0.4rem;
  background-color:#00529A;
  
}
.demo_btn {
  cursor: pointer !important;
  font-family: "Raleway" !important;
  background-color: #415DA5 !important;
  text-transform: capitalize !important;
 

  margin-top: 1vh !important;
  box-shadow: none !important;
}

.card_image {
  width: 24vw;
}

.bullet_points {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}

.left_margin {
  margin-left: 1vw;
}

.reason-cards:hover {
  border-left: 4px solid #608788;
}

.title-description_container p {
  text-align: center !important;
}

//Products Page css
.products_overlay {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  width: 66%;
}

.cards_container {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  width: 90%;
  border-radius: 1rem;
}

.pro-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  border-radius: 1rem;
  padding: 1.5rem;
}

.key_point_grid {
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: transparent !important;
  padding: 1rem 0rem !important;
  width: 100% !important;
}
.key_cards {
  background-color: transparent !important;
  border-radius: 1rem !important;
  box-shadow: none !important;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.key_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 12rem;
  text-align: center;
}
.svg_ben {
  position: absolute;
  top: 30%;
  left: 85%;
}
.infoGraph {
  width: auto;
  height: 90vh;
}
.infoImg {
  margin-top: 1rem;
  border-radius: 1rem;
  background: linear-gradient(
    180deg,
    rgba(253, 167, 55, 0.3) 0%,
    rgba(0, 82, 154, 0.3) 100%
  );
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  height: auto;
  width: 70%;
}
.product_slide {
  border-radius: 1.591vw;
  background: rgba(56, 127, 245, 0);
  box-shadow: 0px 0px 40px 0px #00529a;
  margin: 2vw;
  height: 70vh;
  width: auto;
}
.powerOptix_img {
  border-radius: 1.591vw;

  height: 70vh;
  width: 100%;
}
.img_power {
  width: 50%;
}
.card_power {
  width: 70%;
}
.infoGraph_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent !important;
  box-shadow: none !important;
}

@media screen and (max-width: 801px) {
  .power_slide.active {
    transform: none !important;
  }
  .key_fea_po.slick-list {
    margin: 0.5rem !important;
  }
  .power_slide.nearby {
    transform: none !important;
  }

  .power_slide.inactive {
    transform: none !important;
    height: 0px !important;
  }

  .power_slick {
    .slick-slide {
      margin: 0vh 0vh !important;
    }
  }

  #hover_black {
    height: auto !important;
  }

  .feature-cards {
    gap: 1rem;
    border-radius: 4vw !important;
    padding: 1rem !important;
  }

  .add_cards_container {
    padding: 1rem;
    gap: 0.1rem;
  }

  .add_cards {
    padding: 0.5rem !important;
  }

  .flex-card .Poppins_20px_rem {
    font-size: 1rem !important;
  }

  .Poppins_35px_rem {
    font-size: 1.5rem !important;
  }
  .Poppins_16px_rem {
    line-height: normal !important;
  }
  .infoGraph {
    width: 94% !important;
    height: auto !important;
  }
  .power_gridImg {
    height: auto !important;
  }
  .cards_container {
    width: 100% !important;
    padding: 0rem !important;
    margin-top: 1rem !important;
  }
  .key_point_grid {
    width: 100% !important;
    padding: 0rem !important;
  }
  .Poppins_48px_rem {
    font-size: 2rem !important;
  }
  .Poppins_24px_rem {
    font-size: 1rem !important;
  }
}

@media screen and (min-width: 340px) and (max-width: 992px) {
  .key_fea_po {
    padding: 1rem 0rem !important;
  }
  .pro-details {
    flex-direction: column !important;
    padding: 0rem !important;
  }
  .plus_minus {
    width: 0.8rem !important;
  }
  .img_power {
    width: 100%;
  }
  .card_power {
    width: 100%;
  }
  .card_image {
    width: 100% !important;
  }

  .expanded_content {
    flex-direction: column-reverse !important;
  }

  .powerOptix_img {
    height: 60vh !important;
    object-fit: cover !important;
  }
  .product_slide {
    height: 60vh !important;
  }
  .infoImg {
    width: 100%;
  }
  .title-description_container {
    padding: 1rem !important;
  }

  .Poppins_45px_rem {
    font-size: 2rem !important;
  }

  .powerOptix_overlay {
    top: 47% !important;
    left: 54% !important;
    width: 81% !important;
  }

  .reason-cards .Poppins_18px_rem {
    height: auto !important;
  }

  .reason-cards {
    padding: 1rem !important;
  }

  .reason_icons {
    width: 5rem !important;
  }
  .infoGraph_container p {
    text-align: center !important;
  }
  .soft_prods_div {
    padding: 0.5rem !important;
  }
  .soft_prods_div p {
    font-size: 0.8rem !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1150px) {
  .reason-cards .Poppins_18px_rem {
    height: 10rem !important;
  }

  //   .powerOptix_img {
  //     height: 100vh !important;
  //     object-fit: cover !important;
  //   }
}

@media screen and (max-width: 1200px) {
  .Poppins_45px_rem {
    font-size: 2rem !important;
  }
}
@media screen and (max-width: 700px) {
  .pro_image {
    width: 100% !important;
  }
  .key_title {
    height: auto !important;
  }
  .Poppins_20px_rem {
    font-size: 0.8rem !important;
  }
}
@media (max-width: 901px) {
  .power_grid::before {
    content: none;
  }
}

.border_five{
  border-radius: 0.5rem;
}
.power_slide {
  opacity: 0.7;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white) !important;
  flex-shrink: 0;
  padding: 0vw !important;
  border-radius: 1.5rem !important;
  border: none !important;
  box-shadow: 0px 0px 20px 0px #00529a !important;
}
.power_paper_slide {
  background-color: transparent !important;
  border-radius: 1.5rem !important;
  border: none !important;
  box-shadow: none !important;
}
.power_slide.active {
  transform: scale(1);
  opacity: 1;
  z-index: 2;
}

.power_slide.nearby {
  transform: scale(0.95);
  opacity: 0.85;
}

.power_slide.inactive {
  transform: scale(0.9);
  filter: blur(1.5px);
}

.power_slick {
  .slick-slider {
    width: 100% !important;
    height: 100% !important;
  }
  .slick-slide {
    margin: 2vh 0vh;
  }
  .slick-slide img {
    width: 100% !important;
    height: 100% !important;
  }
}
