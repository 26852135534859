.container {
  display: flex;
  height: 100vh;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 0;
  align-items: center;
  padding: 0;
}

.login-form {
  width: 50%;
  justify-content: center;
  display: flex;
}

.form-container {
  width: 23rem;
  padding:20px;
  background: #d5e2ee;
  box-shadow: 0.125rem 0.5rem 1.625rem rgba(136, 136, 136, 0.25);
  border-radius: 0.625rem;
  text-align: center;
}
.form-data {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background {
  flex: 1;
  background-color: #1c629e;
}

.up-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.3125rem;
}

.email-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  gap: 0.5294rem;
  width: 17.319rem;
  height: 2.8325rem;
  margin-top: 1.1875rem;
  background: #ffffff;
  border-radius: 0.5294rem;
  border: none;
  padding: 1.25rem 1.25rem;
}

.password-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Poppins";
  align-items: center;
  padding: 0.6375rem 10.89rem 0.6375rem 1.7081rem;
  width: 17.3469rem;
  height: 2.8325rem;
  padding: 1.25rem 1.25rem;
  border: none;
  background: #ffffff;
  border-radius: 0.5294rem;
}

.forgot-pass {
  width: 7.5625rem;
  height: 1.1875rem;
  cursor: pointer;
  margin-left: 12.1875rem;
  margin-bottom: 0.5rem;
  margin-top: -1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7938rem;
  line-height: 1.1875rem;
  color: #000000;
  justify-content: center;
  opacity: 0.3;
}

.half-screen-slide-container {
  height: 86vh;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5294rem 1.0529rem;
  gap: 0.7941rem;
  width: 17.3rem;
  height: 2.8325rem;
  background: #1c629e;
  border-radius: 0.5294rem;
  border: none;
  cursor: pointer;
}

.btn-text {
  height: 1.563rem;
  font-family: "Poppins";
  font-style: normal;
  font-size: 0.9rem;
  line-height: 1.563rem;
  color: #ffffff;
}

.login-option {
  width: auto;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7936rem;
  line-height: 1.188rem;
  color: #000000;
  margin-bottom: 0.5rem;
  margin-top: -1rem;
}

.register {
  height: 1.188rem;
  width: auto;
  text-align: center;
  margin-top: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7936rem;
  line-height: 1.188rem;
  color: #000000;
}

.sec-title {
  // width: 50%;
  margin-top: 6.42%;
  text-align: center;
  // font-family: "Poppins";
  // font-style: normal;
  // font-weight: 500;
  // font-size: 1rem;
  // line-height: 1.875rem;
  color: #ffffff;
  padding-top: 1.688rem;
}

.sub-title {
  margin-top: -0.26%;
  text-align: center;
  // font-family: "Poppins";
  // font-style: normal;
  // font-weight: 400;
  // font-size: 0.8125rem;
  // line-height: 1.3352;
  padding: 2rem 9rem;
  color: rgba(255, 255, 255, 0.75);
}

.img1 {
  height: 13rem;
}

.half-screen {
  width: 50%;
  height: 100vh;
  padding: 2.313rem;
  background-color: #1c629e;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.313rem;
  margin-left: 2.875rem;
}

@media screen and (max-width: 991px){
  .half-screen{
    display: none;
  }
}
