.vcb_details{
    border: 0.0625rem solid #111111;
    border-radius: 0.625rem;
    padding: 1rem;
}
.vcb_gap{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    width: 100%;

}
.status-on{
    width: 0.5rem;
     height: 0.5rem;
     border-radius: 50%;
}
.icon_div_border{
    border: none !important;;
}