.career-grid_container {
  justify-content: flex-start !important;
  display: flex;
  align-items: center;
  align-items: flex-start !important;
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 0.5rem !important;
  padding: 1vw;
  box-shadow: none !important;
}
#viewjob {
  background-color: #00529a !important ;
  align-self: flex-end;
}
.filter_car {
  background-color: #e7ebf1;
  padding: 0.4vw;
  border-radius: 0.2rem;
}
#joblist {
  box-shadow: 0px 4px 4px 0px #fda7374d !important;
}
#job_apply {
  border-radius: 0.5rem !important;
  background-color: #fda737 !important;
}
#dot_car {
  padding-left: 1.5vw;
}
.border_car {
  border: 1.5px solid #575757;
  border-radius: 0.2rem;
}
#mail_shadow {
  box-shadow: 0px 0px 20px 0px #00529a66 !important;
}
