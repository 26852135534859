.main-details {
  background: #ffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  color: #0000;
}

//modalcalernder
// .rdrDefinedRangesWrapper{
//   display: none !important;
//   width: 100% !important;
// }
//calender css
.calender-container {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
}

.intervals_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  top: 2rem;
  height: 94%;
}
.interval_filter_combined {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}
.btn_style {
  display: flex;
  align-self: end;
  border-radius: 0.5rem;
  text-transform: none;
  padding: 0.1875rem 0.625rem !important;
}

.rdrInputRanges {
  display: none !important;
  // width: 100% !important;
}

.css-17v15rn {
  width: auto !important;
  border-radius: 0.625rem !important;
  box-shadow: none !important;
  padding: 2rem !important;
  border-color: transparent !important;
}

.rdrDateRangePickerWrapper {
  gap: 1rem;
}

.rdrDateDisplayWrapper {
  background-color: transparent !important;
}

.rdrMonthAndYearPickers select {
  background: no-repeat !important;
}

#custom_date_picker .rdrDefinedRangesWrapper {
  border: none !important;
  top: 0rem !important;
  border-radius: 0.625rem !important;
  position: relative !important;
  background: transparent !important;
}
#range_dis .rdrDefinedRangesWrapper {
  display: none !important;
}
#range_vis .rdrDefinedRangesWrapper {
  border: none !important;
  border-radius: 0.625rem !important;
  position: relative !important;
  top: 2rem !important;
  background: transparent !important;
}
.rdrStaticRanges {
  padding: 1rem 1rem 0rem 0rem !important;
  gap: 1rem;
}

.rdrStaticRange {
  border-radius: 0.625rem !important;

  border: 0.0625rem solid #d8d8d8 !important;
}

.chatai {
  margin: 10px;
  overflow: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.react-chatbot-kit-chat-container {
  overflow: auto;
  width: 350px !important;
  flex-wrap: wrap !important;
  display: flex !important;
}

.react-chatbot-kit-chat-message-container {
  width: 350px !important;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  display: none;
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 0px !important;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  color: transparent !important;
  background-image: url("https://s3.amazonaws.com/smarttrak.co/images/https://s3.amazonaws.com/smarttrak.co/images/toggle-logo.png") !important;
  background-size: cover !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

.filter_container {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  gap: 1rem;
  width: 226px;
}

.filter_button {
  border-radius: 0.625rem;
  border: 0.0625rem solid #d8d8d8;
  cursor: pointer;
  background: #fff;
}

.rdrStaticRangeLabel {
  font-family: "Poppins-Regular" !important;
  color: black !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
}

.rdrDateRangePickerWrapper {
  font-family: "Poppins-Regular" !important;
  color: black !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
}

.rdrDateDisplayItem {
  border-radius: 0.625rem !important;
  font-family: "Poppins-Regular" !important;
  color: black !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;

  border: 0.0625rem solid #d8d8d8 !important;
  box-shadow: none !important;
}

.rdrDateDisplayItemActive input {
  border: 1px solid black !important;
  border-radius: 0.625rem !important;
}

.raleway_20px_rem {
  font-family: "Raleway";
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  text-transform: none;
}
#prediction-chart .apexcharts-toolbar {
  position: absolute;
  z-index: 5;
  max-width: 176px;
  text-align: right;
  border-radius: 3px;
  padding: 0 6px 2px;
  display: flex;
  margin-top: -2.7vw;
  margin-right: 3.2vw;
  gap: 0.625rem;
  justify-content: space-between;
  align-items: center;
}
#prediction-chart_two .apexcharts-toolbar {
  position: absolute;
  z-index: 5;
  max-width: 176px;
  text-align: right;
  border-radius: 3px;
  padding: 0 6px 2px;
  display: flex;
  top: -3.9vw !important;
  right: 9vw !important;
  gap: 0.625rem;
  justify-content: space-between;
  align-items: center;
}
#prediction-chart .apexcharts-menu-icon,
.apexcharts-pan-icon,
.apexcharts-selection-icon,
.apexcharts-toolbar-custom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  transform: scale(0.9) !important;
}

#prediction-chart .apexcharts-zoom-icon,
.apexcharts-reset-icon {
  transform: scale(1.1) !important;
}
#prediction-chart_two .apexcharts-menu-icon,
.apexcharts-pan-icon,
.apexcharts-selection-icon,
.apexcharts-toolbar-custom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  transform: scale(0.9) !important;
}

#prediction-chart_two .apexcharts-zoom-icon,
.apexcharts-reset-icon {
  transform: scale(1.1) !important;
}
.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: rgb(47, 103, 248) !important;
}
.apexcharts-zoom-icon.apexcharts-selected svg {
  fill: rgb(47, 103, 248) !important;
}
.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  background-color: rgb(222 231 246) !important;
}
.icon-css {
  color: #6e8192 !important;
  width: 1.6vw !important;
  height: fit-content !important;
  cursor: pointer !important;
}
.icon-css:hover {
  color: black !important;
}
