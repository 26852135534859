//SidebarIot.scss
.css-i4bv87-MuiSvgIcon-root {
  color: white;
}

.css-i4bv87-MuiSvgIcon-root {
  color: black;
}

.custom-drawer-iot {
  height: 90vh;
  max-width: 100%;

  .css-h4y409-MuiList-root {
    padding-top: 0px;
  }

  .css-10hburv-MuiTypography-root {
    font-family: "Poppins-SemiBold";
    font-size: 1rem;
  }

  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    background-color: #2A3568;
    color: #ffffff;
  }

  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background-color: #ffffff !important;
    color: black;
  }

  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
    display: flex;
    padding: 0.5rem;
  }
}

.css-1g0icdw-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar {
  display: none !important;
}

.css-ikkcja-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar {
  display: none !important;
}

.css-ftiju0-MuiButtonBase-root-MuiListItemButton-root {
  padding: 0.5rem !important;
}

.sidebar-selected-iot {
  padding: 0.625rem;
  color: #000000;
  background-color: #ffffff !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: none !important;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 1.6875rem !important;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.625rem;
  color: #fff;
  font-weight: bold;
}
.nav_list{
  display: flex !important; 
  gap: 1rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  overflow-y: auto !important; 
}
.nav_list::-webkit-scrollbar{
  display: none !important;
}
.sc-gLfKCG {
  padding-bottom: 0.3125rem;
}

.Mui-selected {
  background-color: #F9F9FF !important;
}

.sidebar_logo {
  width: 10vw;
  height: auto;
}

.css-1l8j5k8 {
  border-right: none !important;
}

.listItem_icon {
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 0.625rem;
}

.monitoring-div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.monitoring-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.3125rem 0.625rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.sidebar-main-drawer {
  width: 3.5rem;
  margin-top: -9vh;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container {
  margin-top: -6.5vh;
  position: absolute;
  left: 1rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-arrow-div {
  display: flex;
  align-self: end;
  cursor: pointer;
}

.left-right-arrow {
  color: black;
  width: 1.9rem !important;
  height: fit-content !important;
}
.display_block{
  display: block !important;
}
@media screen and (max-width: 992px) {
  .display_none{
    display: none !important;
  }
  
}