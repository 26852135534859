.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon path {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000000"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"/></svg>') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%; /* Adjust the size as needed */
}
.report-table{
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #FFFFFF;
  border: none;
  border-radius: 0.7rem !important;
  height: 100%;

}
.report-table::-webkit-scrollbar {
  display: none;
}
// .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon path {
//   d: path(
//     "M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
//   );
//   // fill: %23000000;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: 80%; /* Adjust the size as needed */
// }

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.5rem 0.625rem 0.5rem 0.625rem !important;
}
.border-class {
  border: 0.0625rem solid #646464 !important;
  border-radius: 0.7rem !important;
}
.border-class-input {
  border: 0.0625rem solid #646464 !important;
  border-radius: 0.7rem !important;
  font-size: 1rem;
  font-family: "poppins-Regular";
  font-weight: 400;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem !important;
}
.scrollingbar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 90%;
  overflow-y: auto;
}
.report-history-title{
    background-color: white;
    padding: 1rem;
    width: 100%;
}
#reportdownload{
  left: auto !important;
  right: 0.5rem !important;
  padding: 0.225rem 0rem !important;
}
.customPopoverReport {
  border-radius: 0.625rem !important; // Apply custom border-radius
  left: 87.8vw !important; // Set left position
  box-shadow: none !important;
  padding: 0.625rem 0rem !important;
  border: 0.0625rem solid #0000001a !important;
}
.scrollingbar::-webkit-scrollbar {
  display: none !important;
}

.date-calender {
  display: flex;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1199px) {
  .absolute_top_right_0{
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    padding: 1rem !important;
  }
}
@media screen and (max-width: 992px) {
  .date-calender {
    width: auto !important;
  }
  #scrollingHistory{
    width: 10rem !important;
  }
  #scrollname{
    width: 10rem !important;
  }
}
@media screen and (max-width: 450px) {
  .media_width_80 {
    width: 80% !important;
  }
  .media_full_width{
    width: 100% !important;
  }
  .report-btn-div {
    min-width: 12rem !important;
  }
}
@media screen and (min-width: 451px) and (max-width: 750px) {
  .media_tab_width_80 {
    width: 80% !important;
  }
}
/* styles.css */
.report-first-paper {
  padding: 2rem 0rem !important;
  height: 100% !important;
  width: 100% !important;
  box-shadow: none !important;
}
.report-sec-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  gap: 2rem;
}
.report-img-paper{
    border-radius: 0.7rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100% !important;
    background-color: transparent;
    box-shadow: none !important;
    background-color: transparent !important;
  
}
.report-img-grid{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 0.7rem !important;
  border: none !important;
  position: relative;
}
.Poppins_report-text {
  font-family: "Poppins";
  font-size: 0.875rem;
  font-weight: 400;
  color: black;
  text-align: center;
  width: 40rem;
}
.report-sub-select{
  border-radius: 0.7rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.submit-paper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  box-shadow: none !important;
}
.report-btn-div{
  display: flex;
  align-items: center;
  min-width: 23rem;
  text-align: center;
  padding: 0.3125rem;
  cursor: pointer;
  color: white;
}
.report-btn-btn{
  display: flex !important;
  width: 100% !important;
  font-family: "Raleway" !important;
  font-weight: 700 !important;
  border-radius: 0.625rem !important;
  padding: 0.4rem !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem !important;
}
.report-grid-history{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #FFFFFF;
  flex-direction: column !important;
  border-radius: 0.7rem !important;
  height: 100%;
  padding: 1rem 0rem;
}
#scrollingHistory{
  width: 12vw;
  overflow-x: scroll;
    white-space: nowrap;
    cursor: pointer;
}

#scrollingHistory::-webkit-scrollbar {
  display: none !important;
}
.report-paper-history{
height: 100%;
border-radius: 0.7rem !important;
padding: 0rem 1rem !important;
overflow-y: auto;
width: 100% !important;
background-color: transparent !important;
box-shadow: none !important;
overflow-x: hidden;
}
.report-paper-history::-webkit-scrollbar{
  display: none !important;
}
.report-history-map {
  display: flex;
  align-items: center;
  padding: 0.625rem;
  gap: 0.625rem;
  justify-content: space-between;
  border-radius: 0.625rem;
  cursor: pointer;
}

.report-icon-img{
  background-color: transparent !important;
padding: 0.625rem;
border-radius: 0.625rem;
width: 3rem;
}
.css-1dyz3mf{
  height: 5vh !important;
  overflow: auto !important;
  width: 14vw !important;

}
//MultiCheckbox css
.searchWrapper {
  border: 0.0625rem solid #646464 !important;
  border-radius: 0.7rem !important;
  font-size: 1rem;
  font-family: "Poppins-Regular";
  font-weight: 400;
  // padding: 0.5rem 0.625rem 0.5rem 0.625rem !important;
  overflow-y: auto !important;
  height: 2.5rem !important;
  overflow-x: hidden !important;
}
.searchWrapper::-webkit-scrollbar{
  display: none !important;
}
.highlightOption {
  background: transparent !important;
  color: black !important;
}
.select-all-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin: 0.3rem 0rem;
}
