// px to rem conversion
// 1px = 0.0625rem
// 2px = 0.125rem
// 3px = 0.1875rem
// 4px = 0.25rem
// 5px = 0.3125rem
// 6px = 0.375rem
// 7px = 0.4375rem
// 8px = 0.5rem
// 9px = 0.5625rem
// 10px = 0.625rem
// 11px = 0.6875rem
// 12px = 0.75rem
// 13px = 0.8125rem
// 14px = 0.875rem
// 15px = 0.9375rem
// 16px = 1rem
// 17px = 1.0625rem
// 18px = 1.125rem
// 19px = 1.1875rem
// 20px = 1.25rem
// 21px = 1.3125rem
// 22px = 1.375rem
// 23px = 1.4375rem
// 24px = 1.5rem
// 25px = 1.5625rem
// 26px = 1.625rem
// 27px = 1.6875rem
// 28px = 1.75rem
// 29px = 1.8125rem
// 30px = 1.875rem
// 31px = 1.9375rem
// 32px = 2rem
// 33px = 2.0625rem
// 34px = 2.125rem
// 35px = 2.1875rem
// 36px = 2.25rem
// 37px = 2.3125rem
// 38px = 2.375rem
// 39px = 2.4375rem
// 40px = 2.5rem

// Screen Sizes
// 1366x768 (HD)
// 1440x900
// 1536x864
// 1600x900
// 1920x1080 (Full HD)
// 1920x1200
// 2160x1440 (3:2 aspect ratio)
// 2560x1440 (QHD)
// 2880x1800
// 3200x1800 (QHD+)
// 3840x2160 (4K UHD)

//weatherBoard
.raleway_16px_rem {
  font-family: "Raleway";
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
}
.raleway_14px_rem {
  font-family: "Raleway";
  color: #212121;
  font-size: 0.875rem;
  font-weight: 600;
}
.apexcharts-radialbar-label{
  font-family: "Poppins-Regular" !important;
  font-size: 0.9vw;
  margin-bottom: 10px !important;

}
.Poppins_11px_rem {
  font-family: "Poppins";
  color: black;
  font-size: 0.6875rem;
  font-weight: 400;
}
.Poppins_12px_rem {
  font-family: "Poppins-Regular";
  color: #212121;
  font-size: 0.75rem;
  font-weight: 400;
}
.Poppins_13px_rem {
  font-family: "Poppins";
  color: black;
  font-size: 0.8125rem;
  font-weight: 700;
}
.Poppins_12px_500 {
  font-family: "Poppins";
  font-size: 0.75rem;
  font-weight: 500;
  color: #000000;

}
.Poppins_12px_Bold {
  font-family: "Poppins-Bold";
  color: #2a3568;
  font-size: 0.75rem;
  text-transform: capitalize;
}
.Poppins_14px_regular {
  font-family: "Poppins-Regular" !important;
  color: #212121 !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}
.Poppins_14px_rem {
  font-family: "Poppins";
  color: #212121;
  font-size: 0.875rem;
  font-weight: 600;
}

.Poppins_28px_rem {
  font-family: "Poppins";
  color: #212121;
  font-size: 1.75rem;
  font-weight: 600;
}
.raleway_28px_rem {
  font-family: "Raleway";
  color: #2f67f8;
  font-size: 1.75rem;
  font-weight: 700;
}
.Poppins_51point45px_rem {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 3.22rem;
  color: #fda737;
}
.Poppins_64px_rem {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 4rem;
  color: white;
}
.raleway_64px_rem{
  font-family: "Raleway";
  font-weight: 700;
  font-size: 3.6rem;
  color: white;

}
.Poppins_20px_rem {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.25rem;
  color: white;
}
.raleway_20px_reg {
  font-family: "Raleway";
  font-weight: 400;
  font-size: 1.25rem;
  color: #000000;
  font-feature-settings: 'lnum', 'tnum';

}
.raleway_20px_semi {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 1.25rem;
  color: #2f67f8;
}
.raleway_pri_semi {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 1.5rem;
  color: #2f67f8;
  font-feature-settings: 'lnum', 'tnum';

}
.raleway_32px_rem {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.9rem;
  font-weight: 700;
}
.search_Text {
  font-family: "Poppins-Regular";
  color: #00000080 !important;

  font-size: 1rem;
  font-weight: 400;
}
.poppins_21px_italic {
  font-size: 1.3125rem;
  font-style: italic;
  color: #000000;
  font-family: "Poppins-Regular";
}
.apexcharts-datalabel-value {
  color: #212121 !important;
  font-family: "Poppins" !important;
  font-size: 1rem !important;
  font-weight: 600;
}
.Poppins_8px_rem {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 0.5rem;
  color: #212121;
}
.Poppins_9px_rem {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 0.5625rem;
  color: #757575;
}
.Poppins_16px_rem {
  font-family: "Poppins";
  color: #212121;
  font-size: 1rem;
  font-weight: 600;
}
.Poppins_bold {
  font-family: "Poppins-Bold";
  color: #212121;
  font-size: 1.1rem;
  font-weight: 700;
}
.Poppins_16px_400 {
  font-family: "Poppins-Regular" !important;
  color: #212121;
  font-size: 0.9rem !important;
  font-weight: 400;
}
.report_400 {
  font-family: "Poppins-Regular";
  color: #212121;
  font-size: 1rem;
  font-weight: 400;
}
.c_graymix{
  color: #151515CC !important;
  
}
.Poppins_16px_green {
  font-family: "Poppins-Regular";
  color: #30b900;
  font-size: 1rem;
  font-weight: 600;
}
.Poppins_32px_rem {
  color: #212121;
  font-family: "Poppins";
  font-size: 2rem;
  font-weight: 600;
}
.Poppins_36px_rem {
  color: #212121;
  font-family: "Poppins";
  font-size: 2.25rem;
  font-weight: 600;
}
.raleway_36px_rem {
  color: #212121;
  font-family: "Raleway";
  font-size: 2.25rem;
  font-weight: 600;
}
.Poppins_18px_rem {
  color: #212121;
  font-family: "Poppins";
  font-size: 1.125rem;
  font-weight: 600;
}
.Poppins_18px_blue {
  color: #2f67f8;
  font-family: "Poppins";
  font-size: 1.125rem;
  font-weight: 600;
}
.raleway_18px_rem {
  color: #212121;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 400;
}
.raleway_12px_rem {
  font-family: "Raleway";
  font-size: 0.75rem;
  font-weight: 700;
  color: #212121;
}
.raleway_14px_blue {
  font-family: "Raleway";
  font-size: 0.875rem;
  font-weight: 600;
  color: #2f67f8;
}
.raleway_12px_white {
  font-family: "Raleway";
  font-size: 0.75rem;
  font-weight: 400;
  color: white;
}

.Poppins_10px_rem {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 0.625rem;
  color: white;
}
.Poppins_24px_rem {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
}
.Poppins_24px_black {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: #212121;
}
.raleway_24px_rem {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 1.5rem;
  color: black;
}
.raleway_8px_rem {
  font-family: "Raleway";
  color: #ffffff;
  font-size: 0.5rem;
  font-weight: 600;
}
.Poppins_24px_black {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: black;
}
.c_mixGray{
  color: #00000099 !important;
}
.Poppins_15px_black {
  font-family: "Poppins-Bold";
  font-size: 0.9375rem;
  color: #212121;
}
.raleway_18px_abt {
  color: #212121;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 700;
}
.Poppins_16px_white {
  font-family: "Poppins-Regular";
  color: white;
  font-size: 1rem;
  font-weight: 700;
}
.Poppins_30px_rem {
  font-size: 1.875rem;
  font-weight: 600;
  font-family: "Poppins";
}
.Poppins_48px_rem {
  font-size: 3rem;
  font-weight: 500;
  font-family: "Poppins";
}
.Poppins_35px_rem {
  font-size: 2.1875rem;
  font-weight: 600;
  color: #333333;
  font-family: "Poppins";
}
.Poppins_40px_rem {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffff;
  font-family: "Poppins";
}
.Poppins_45px_rem {
  font-size: 2.8125rem;
  font-weight: 700;
  color: #ffff;
  font-family: "Poppins";
}

@media screen and (min-width: 992px) and (max-width: 1299px) {
  .list_italic li{
    font-size: 1rem;

  }
  .raleway_20px_reg  {
    font-size: 1rem;
  }
  .raleway_20px_semi {
    font-size: 1rem;
  }
.raleway_pri_semi{
  font-size: 1rem;

}
  .Poppins_14px_rem {
    font-size: 0.6rem;
  }
  .Poppins_12px_rem {
    font-size: 0.6rem;
  }
  .Poppins_12px_500 {
    font-size: 0.6rem;

  }
  .Poppins_15px_black {
    font-size: 0.6rem;
  }
  .Poppins_10px_rem {
    font-size: 0.5rem;
  }

  .Poppins_24px_black {
    font-size: 1.3rem;
  }
  .Poppins_16px_400 {
    font-size: 0.75rem !important;
  }
  .Poppins_18px_rem {
    font-size: 1rem !important;
  }
  .apexcharts-legend-text {
    font-size: 0.7rem !important;
  }
  .Poppins_14px_regular {
    font-size: 0.625rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  
  .Poppins_14px_rem {
    font-size: 0.5rem;
  }
  .Poppins_12px_rem {
    font-size: 0.5rem;
  }
.Poppins_12px_500 {
  font-size: 0.6rem;

}

  .raleway_16px_rem {
    font-size: 0.8rem;
  }
  .Poppins_10px_rem {
    font-size: 0.5rem;
  }
  .Poppins_18px_rem {
    font-size: 1rem;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  
  
  .Poppins_14px_rem {

    font-size: 0.5rem;
  }
}
