
//ProductDetails style start
.products-container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.model-cards-container {
  margin: 10vh 0vw 0vh 0vw;
}

.arrow-circle {
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.discrptionImage{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.keydiscrptionImage{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15vw;
}

.key-cards-container{
  padding: 7vh 4vw;
}

//SmartIQEdge style starts
.smart-heading {
  padding: 1vh 2.3vw;
}

.img-block {
  display: flex;
  padding: 8vh 2.4vw;
  gap: 4vw;
  margin-bottom: 8vh;
}

.smartiq-img-container {
  border-radius: 3vw 3vw 3vw 11vw;
  background: #F1C0B2;
  margin-right: 5vw;
  position: relative;
}

.smartiq_img {
  border-radius: 2vw 2vw 2vw 11vw;
  width: 41vw;
}

#smartiq-description {
  margin-top: 6vh;
}

.key-features-data {
  padding: 2.4vh 9vw;
  margin-bottom: 12vh !important;
}

.feature-description {
  display: flex;
  flex-direction: column;
  gap: 4vh;
}

.feature-img {
  // border-radius: 1vw 1vw 1vw 6vw;
  width: 39vw;
}
.product-video-container {
  position: relative;
  width: 95%;
  height: 95vh;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.demo-text{
  padding: 1vh 2.3vw;
  margin: 4vh 0vw;
}
.product-draw-line2 {
  width: 50vw;
  position: absolute;
  left: -45vw;
}
.pro-soln-line-one{
  width: 48vw;
  position: absolute; 
  left: -50vw;
  top: -2vh;
}
.pro-soln-line-two{
  height: 24vw;
    position: absolute;
    right: 8vw;
}

//CommonTexts
.bold-big-common-text {
  text-transform: capitalize;
  font-family: "Raleway";
  font-size: 2.26vw;
  font-weight: 700;
}

.bold-big-text {
  font-family: "Raleway";
  color: #252525;
  font-weight: 700;
  font-size: 4.15vw;
}

.semibold-18px_600 {
  font-family: 'Raleway';
  font-size: 1.1vw;
  font-weight: 600;
}

.semibold-24px_500 {
  color: #fff;
  font-family: 'Raleway';
  font-size: 1.59vw;
  font-weight: 500;
}


@media screen and (max-width: 992px) {
  .grid-one{
    flex-direction: column !important;
  }
  .grid-two{
    flex-direction: column-reverse !important;
  }
  #hand-bricks{
    display: none !important;
  }
  .star-container-two{
    display: none !important;
  }
  #circle-img{
    top: 17vh !important;
  }
  #third-img{
    top: -7vh !important;
    right: 46vw !important;
  }
  .semibold-24px_500{
    font-size: 20px !important;
  }
  #auto-width{
    width: auto !important;
  }
  .keydiscrptionImage {
    flex-direction: column !important;
  }
  .key-picture{
    width: 79vw !important;
  }
  .insight-container{
    flex-direction: column !important;
  }
  .insight-img{
    width: 100% !important;
  }
  .discrptionImage {
    gap: 10px !important;
    flex-direction: column-reverse !important;
}
#auto-width-margin{
  width: auto !important;
  margin-top: auto !important;
}
#big_text{
  font-size: 19px !important;
}
}

@media screen and (min-width: 993px) and (max-width: 1190px){
.circle-arrow-container{
  right: 9.5vw !important;
}
}

//only xs screen 
@media screen and (min-width: 340px) and (max-width: 600px){
  .smartiq_img, .feature-img{
    width: 100% !important;
  }
  .img-block{
    padding: 1rem !important;
    flex-direction: column !important;
  }
  .feature-container {
    flex-direction: column !important;
  }
  .svg_line{
    display: none !important;
  }
  .img-block{
   margin-bottom: 1rem !important;
  }
  .service-heading{
    padding: 0rem 1.5rem !important;
  }
  .key-features-data{
    margin-bottom: 0rem !important;
  }
  #smartiq-description {
    margin-top: 0vh !important;
}
}
@media screen and (min-width: 601px) and (max-width: 900px){
  .smartiq-img-container {
    height: 20vh !important;
  }
  .img-block {
    padding: 2rem !important;
    margin-bottom: 2rem !important;
}
#smartiq-description {
  margin-top: 0vh !important;
}
.draw_line_svg{
  top: 12vh !important;
}
}