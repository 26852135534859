.main {
  display: flex;
  flex-direction: column;
  padding: 30px 7vh 0px 7vh;
  box-sizing: border-box;
  overflow-y: auto;
}

.create-ai-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-btn-group {
  display: flex;
  flex-direction: row;
}

.preview-container {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 25px 15px;
  background-color: #daeeff;
  width: 100%;
  height: 71%;
  margin: 22px 0px 0px 0px;
}

.table-container {
  display: flex;
  justify-content: center;
  height: 90%;
  margin: 20px 0px 20px 10px;
  overflow-x: auto;
  padding-right: 15px;
  width: 100%;
}

.custom-table-header-cell {
  background-color: #1c629e !important;
  color: #fff !important;
  font-family: "Poppins-SemiBold" !important;
  border: 1px solid #d0d4e2;
}

.custom-body-cell {
  font-family: "Poppins-Regular" !important;
  border: 1px solid #d0d4e2;
}

.custom-table-header {
  .css-y8ay40-MuiTableCell-root {
    background-color: #1c629e;
    color: #fff;
  }
}

.train-model-drawer-container {
  padding: 30px 50px 0px 40px;
  border: 1px solid green;
  flex-grow: 1;
}

.config-parameter-container {
  display: flex;
  background-color: #f5f5f5;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 0px 20px 40px 20px;
  margin: 40px 0px 0px 0px;
}

.parameter-input {
  height: 40px;
  width: 70%;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0px 0px 0px 10px;
  font-family: "Poppins-Regular";
  margin: 5px 0px 0px 0px;
}

.train-ai-button-container {
  display: flex;
  margin: 30px 0px 19px 0px;
  justify-content: flex-end;
}

.file-format-container {
  display: flex;
  flex-direction: row;
  margin: 20px 20px 0px 30px;
}

.file-type-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
  background-color: #daeeff;
  padding: 20px 0px 0px 0px;
  border-radius: 10px;
  margin: 0px 30px 20px 0px;
}

.upload-file-button {
  display: flex;
  background-color: #1c629e;
  width: 100%;
  padding: 10px 30px 10px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 15px 0px 0px 0px;
  cursor: pointer;
}

.upload-data-button-wrapper {
  display: flex;
  margin: 30px 0px 0px 0px;
  justify-content: flex-end;
}

.data-upload-chart-container {
  height: 38rem;
  width: 33rem;
  border: 1px solid gray;
  margin: 30px 0px 30px 0px;
  padding: 30px;
}

.close-btn {
  color: red;
  position: absolute;
  right: 5px;
  top: 3px;
}

.close-btn2 {
  color: red;
  position: absolute;
  right: 12px;
  top: 9px;
}

.close-btn2:hover {
  color: white;
  background-color: red;
}

.close-btn:hover {
  color: white;
  background-color: red;
}

.evaluate-model-graphs-container {
  width: 40%;
  border: 1px solid #bebebe;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 15px 15px 20px 15px;
}

.graphs-container {
  display: flex;
}
.confusionGraph {
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #bebebe;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  margin: 10px;
}

.evaluate-model-accuracy-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #bebebe;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  justify-content: space-between;
  margin: 10px 0px 20px 0px;
  .left {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

.checkbox {
  margin-bottom: -15px;
}
.search-container {
  display: flex;
}

// .highlight {
//   background-color: yellow !important;
// }

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}

.classes-list {
  margin: 10px;
}
.css-rvv586-MuiImageList-root {
  overflow-y: auto;
}
