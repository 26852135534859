//NewLanding styles
//navbar styles
.maintool-Bar {
  display: flex;
  justify-content: space-between;
  padding: 1vh 1vw !important;
}
.nav-item {
  display: flex;
  gap: 2vw;
  align-items: baseline;
  color: black;
  position: relative;
}

.nav-elements {
  font-family: "Raleway" !important;

  color: #000;
  font-size: 1.25vw !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}
.product-elements {
  font-family: "Raleway" !important;
  color: #252525;
  font-size: 14px !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  display: flex !important;
  align-self: flex-start !important;
  padding: 0rem 1rem !important;
}
.selected-nav-element {
  position: relative;
  font-size: 1.25vw;
  cursor: pointer;
  font-family: "Raleway" !important;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00529a;
}

.blink-green-star {
  transition: width 2s;
  animation: transformInOut 0.5s ease-in-out 1;
}
//Slider Style
.slider-container {
  height: fit-content;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.slick-dots li button:before {
  color: #fff;
}

.slick-prev:before,
.slick-next:before {
  color: #fff;
}
.slick-prev {
  // left: 38px !important;
  // position: absolute !important;
  // z-index: 1 !important;
  display: none !important;
}
.slick-next {
  // right: 38px !important;
  display: none !important;
}
.left-arrow,
.right-arrow {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
.slide {
  position: relative;
}
.contact-btn {
  border: 2px solid #608788;
  padding: 8px 18px;
  border-radius: 5px;
  text-align: center;
  width: 34%;
  cursor: pointer;
}
.contact-btn:hover {
  background-color: #608788;
  .Poppins_18px_rem {
    color: #ffff !important;
  }
}
.overlay {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  padding: 1vw 2vw;
  border-radius: 10px;
  width: 99%;
}
.slider-texts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  width: 30%;
  margin-left: 10%;
  margin-top: -6%;
  position: absolute;
}
@keyframes transformInOut {
  5%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }
}

.text-container {
  position: relative;
  display: inline-block;
}

.smaller-crossed-text {
  font-size: 4vw;
  position: relative;
  display: inline-block;
  padding: 1vh 1vw;
  color: white;
}

.text-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #90e900;
  transform: rotate(1.5deg);
  border-radius: 1vw;
  animation: moveAndRotate 0.2s ease-in-out 4;
  transform-origin: center center;
}

@keyframes moveAndRotate {
  0%,
  100% {
    transform: translateY(-3px) rotate(3deg);
  }

  50% {
    transform: translateY(3px) rotate(-3deg);
  }
}
/* Increase specificity example */
.expertise_image {
  width: 100% !important;
  margin-top: 1vh;
  border-radius: 1rem;
}

//home.js styles
.landing-container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}
.bg_home {
  background: #ecf2f7;
}
.landing-heading {
  font-family: "Raleway";
  color: #252525;
  font-weight: 700;
  font-size: 1.8vw;
}

.big-heading {
  font-size: 4vw;
}
.dropdown-pro-mobile {
  background: #eee;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  margin-top: -1rem;
}
.width_flex_45 {
  flex: 1 1 45%; 
  max-width: 100%;
  width: 100%;

}
.video-container_digi {
  width: 100%;
  flex: 1 1 45%; 
  max-width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
  height: 100%; 
  max-width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 0px 100px 0px #00529acc;
  border-radius: 2rem !important;
}

video, media-player {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video scales to fill the container */
}

.video-container {
  position: relative;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 100px 0px #00529acc;
  border-radius: 2rem !important;
}
// :where(media-buffering-indicator) {
//   display: none !important;
// }
:where(
    media-community-skin[data-video] div[part="controls-group"]:first-child
  ) {
  display: none !important;
}
:where(media-community-skin[data-video] [part="time-group"]) {
  display: none !important;
}

.our-expertise-container {
  width: 95%;
  margin-bottom: 2vh;
  border-radius: 2vw;
  background: #111;
  padding: 4vh 2vw;
}

@keyframes double-rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(720deg);
  }
}

.rotate-on-scroll {
  transition: transform 0.5s;
  cursor: pointer;
}

.double-rotation {
  animation: double-rotation 0.6s linear;
  /* Rotate 720 degrees (two full rotations) */
}

.hover-svg {
  width: 2vw;
}

.hover-svg:hover {
  transform: rotate(43deg);
}

.expertise-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-heading {
  display: flex;
  justify-content: space-between;
  padding: 1vh 2.3vw;
  align-items: center;
}

.gen-ai-conatiner {
  padding: 4vh 2.3vw;
}

.fault-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5vw;
  margin-top: 7vh;
}

.case-arrow {
  cursor: pointer;
}

.product-details {
  width: 100%;
  margin-top: 4vh;
  border-radius: 1.5vh 1.5vh 20vh 1.5vh;
  background: #2f67f8;
  position: relative;
  padding: 1.5vh 8.5vh 1.5vh 2vh;
}

.view-all {
  margin-bottom: 7vh;
}

.pscroll {
  color: #252525;
  font-family: "Raleway";
  font-size: 1.3vw;
  font-weight: 500;
  height: 18vh;
  overflow: auto;
}

.pscroll::-webkit-scrollbar {
  display: none !important;
}

@keyframes moveRightLeft {
  0% {
    transform: translateX(0%); // Start position, move to the right
  }

  50% {
    transform: translateX(-50%); // Middle position, move to the left
  }

  100% {
    transform: translateX(0%); // End position, move to the right again
  }
}

@keyframes right {
  0% {
    transform: translateX(50%); // Start position, move to the right
  }

  50% {
    transform: translateX(-0%); // Middle position, move to the left
  }

  100% {
    transform: translateX(50%); // End position, move to the right again
  }
}

@keyframes topBottom {
  0%,
  100% {
    transform: translateY(0); // Start and end position, no vertical movement
  }

  50% {
    transform: translateY(-50%); // Middle position, move to the bottom
  }

  50% {
    transform: translateY(50%); // End position, move to the right again
  }
}

@keyframes moveRightLeft {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes moveTopBottom {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes moveRightLeftAndTopBottom {
  0% {
    transform: translateX(0) translateY(0);
  }

  25% {
    transform: translateX(100%) translateY(0);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateX(0) translateY(100%);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

.casestudy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}

.case-studies {
  width: 95%;
  height: 40%;
  background-color: #111;
  border-radius: 50px;
  padding: 2vw;
  margin-bottom: 5vh;
}

////
:root {
  --cards: 4;
  --cardHeight: 61vh;
  --cardTopPadding: 4.5em;
  --cardMargin: 4vw;
}

.cards-container {
  width: 75%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#cards {
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--cards), var(--cardHeight));
  gap: var(--cardMargin);
  padding-bottom: calc(var(--cards) * var(--cardTopPadding));
}

#card1 {
  --index: 1;
}

#card2 {
  --index: 2;
}

#card3 {
  --index: 3;
}

.card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--cardTopPadding));
}

#card1 .card-body:hover,
#card2 .card-body:hover {
  background-color: #2f67f8;
}

#card3 .card-body {
  background-color: #2f67f8;
}

#card1:hover ~ #card3 .card-body,
#card2:hover ~ #card3 .card-body {
  background-color: #212121; /* Change the background color for card3 when card1 or card2 is hovered */
}

.card-body {
  background-color: #212121;
  border-color: 1px solid #545454;
  box-sizing: border-box;
  padding: 2vw;
  gap: 2vw;
  border-radius: 2vw 2vw 14vw 2vw;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  height: var(--cardHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

////

@keyframes moveDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(20px);
    /* Move the card down by 20px */
  }
}

section {
  // position: relative;
  z-index: -1;
  padding: clamp(3em, 6vw, 5em);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  gap: 2em;
  background: radial-gradient(var(--body-bg), #000);
  overflow-x: hidden;
}

.testimonials-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.testimonials-arrow {
  border: 1px solid #111;
  background: rgba(69, 69, 69, 0.8);
  backdrop-filter: blur(2px);
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.testimonials-arrow.left {
  left: -24px;
}

.testimonials-arrow.right {
  right: -24px;
}

.subscribe-news {
  width: 95%;
  border-radius: 50px;
  background: #111;
  padding: 3vw;
}

.submit-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
#email-input {
  background-color: transparent;
  border: 0px solid;
  width: 22vw !important;
}
#email-input:focus {
  outline: none;
}
.submit-box {
  width: 90vw;
  padding: 8vh;
  border-radius: 1vw 1vw 9vw 1vw;
  background: #2f67f8;
  margin-top: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expertise-cards-container {
  position: sticky;
}
.expertise-cards {
  border-radius: 1rem 1rem 5rem !important;
  background: #212121 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
.expertise-cards:hover {
  background-color: #2f67f8 !important;
  color: #ffff !important;
}
.submit-btn {
  display: flex !important;
  width: 48vw !important;
  padding: 0.5vh 0.2vw 0.5vh 1.5vw !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 2vw !important;
  border: 1px solid #808080 !important;
  background: #fff !important;
}
.folder-text {
  color: #fff;
  font-family: "Raleway" !important;
  font-size: 1.15vw !important;
  font-weight: 400 !important;
  overflow-y: auto;
  height: 20vh;
  padding: 3rem 1rem;
}
.folder-text::-webkit-scrollbar {
  display: none;
}

@keyframes slideRight {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.marquee-container {
  display: flex;
  animation: scrolling 20s linear infinite;
  /* Adjust the duration as needed */
  width: fit-content;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.marquee-item {
  flex: 0 0 16vw;
  margin: 0 1vw;
  display: inline-block;
}

.marquee-item img {
  display: block;
}

@keyframes scrolling {
  0% {
    transform: translateX(100%);
    /* Start from the right edge */
  }

  100% {
    transform: translateX(-100%);
    /* End at the left edge */
  }
}

.vectorAnime {
  stroke: #e6e6e6;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  /* Start from the top (length of the path) */
  animation-name: vectorAnime;
  animation-duration: 10s;
  animation-iteration-count: 1;
  /* Run the animation once and stop */
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  stroke-width: 1px;
}

@keyframes vectorAnime {
  0% {
    stroke-dashoffset: 2000;
    /* Start from the top (length of the path) */
  }

  100% {
    stroke-dashoffset: 0;
    /* Reveal the entire path */
  }
}

.draw-line2 {
  width: 50vw;
  position: absolute;
  left: -45vw;
}

.product-linedraw {
  height: 53vh;
  left: 2vw;
  top: 0;
  position: absolute;
  stroke: #353535 !important;
}

.product-linedraw-2 {
  top: 6vh;
  right: 0;
  width: 20vw;
  position: absolute;
  stroke: #353535 !important;
}

.footer-social {
  width: 2vw;
}
.footer-svg {
  width: 1.5vw;
}

.line-draw {
  height: 55vh;
  position: absolute;
  right: 5vw;
}

//CommonTexts
.bold-common-text {
  color: #fff;
  font-family: "Raleway";
  font-size: 2vw;
  font-weight: 700;
}

.regular-common-text {
  color: #fff;
  font-family: "Raleway" !important;
  font-size: 1.15vw !important;
  font-weight: 400 !important;
}

.semibold-commontext {
  color: #252525;
  font-family: "Raleway";
  font-size: 1.5vw;
  font-weight: 600;
}
.semibold-folder-text {
  color: #252525;
  font-family: "Raleway";
  font-size: 1.5vw;
  font-weight: 600;
  padding: 1rem;
}
.semibold-smalltext {
  color: #252525;
  font-family: "Raleway";
  font-size: 1.3vw;
  font-weight: 500;
}

.mobile-menu-icon {
  display: none;
}

.circle-arrow-container {
  cursor: pointer;
}
.slider-container .slick-slide img {
  width: 100%;
}
// footer style start
.footer-container {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 1vw;
  align-items: baseline;
  justify-content: flex-start;
}

.footer-sec-container {
  padding: 3vh 4vw;
  display: flex;
  background-color: #1e2833;
  flex-direction: column;
}

.contact-us-footer {
  display: flex;
  align-items: flex-start;
  gap: 1vw;
  justify-content: flex-start;
}

.progress-linewidth {
  width: 150px;
  text-align: left;
}
.dropdown-pro {
  background-color: #ccddeb;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  left: -1vw;
  top: 3vw;
  z-index: 1;
  width: 7vw;
}
.software_prods_container {
  position: absolute;
  background-color: #3475ae;
  border-radius: 1rem;
  top: 3vw;
  left: 6vw;
  display: flex;
  width: 15rem;
  padding: 0.1rem;
  z-index: 1;
  flex-direction: column;
}
.soft_prods_div {
  background-color: #3475ae;
  padding: 0.6rem 1rem;
  border-radius: 1rem;
}
.soft_prods_div p {
  color: #ffff;
}
.soft_prods_div:hover {
  background-color: #ccddeb;
}
.soft_prods_div:hover p {
  color: #015399;
}

//footer dropdown style
.footer_software_prods_container {
  position: absolute;
  background-color: #bdbdbd;
  border-radius: 0.3rem;
  top: 4vh;
  left: 7vw;
  display: flex;
  width: 15rem;
  padding: 0.1rem;
  z-index: 1;
  flex-direction: column;
}
.footer_soft_prods_div {
  background-color: #bdbdbd;
  padding: 0.4rem;
  border-radius: 0.3rem;
  cursor: pointer;
}
.footer_soft_prods_div:hover {
  background-color: #000000e0;
}
.footer_soft_prods_div:hover p {
  color: rgb(217, 217, 217);
}

.dropdown_main_container {
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
}
.menu-pro {
  padding: 0.625vw;
  text-decoration: none;
  display: block;
  background-color: transparent;
  transition: background-color 0.3s ease;
  text-align: center;
}
.menu-pro-mobile {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.menu-pro p:hover {
  color: #00529a;
  font-weight: 600 !important;
}
.soft_prods_div:hover{
  p{
  color: #00529a !important;
  font-weight: 600 !important;

  }
}
.pro_arr{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.footer_product {
  display: flex;
  flex-direction: column;
  position: relative;
}
.footer_dropdown-pro {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.125vw;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  width: 7vw;
  position: relative;
}
.pro-elements {
  font-family: "Raleway" !important;
  color: rgb(76, 76, 76);
  font-size: 1.1vw !important;
  cursor: pointer !important;
  font-weight: 400 !important;
}
.footer-pro {
  padding: 0.625vw;
  text-decoration: none;
  display: block;
  background-color: transparent;
  transition: background-color 0.3s ease;
}
.footer-pro:hover {
  background-color: #747070;
}
.footer-pro:hover p {
  color: rgb(217, 217, 217);
}
.expertise-box {
  margin-top: 4rem;
}
.our_experts {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 70%;
}
.marque-main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3vw 0vw;
  overflow: hidden;
  width: 100%;
}
//footer style ends

//mobile responsive

@media screen and (max-width: 901px) {
  #viewjob {
    align-self: flex-start !important;
  }
  .apexcharts-radialbar-label{
    font-size: 0.675rem !important;
  }
  .pro-elements {
    font-size: 13px !important;
  }
 
  //navbar
  .nav-item {
    display: none !important;
  }
  #nav_new{
margin-left: 0vw !important;
  }
  .footer_dropdown-pro {
    width: 100% !important;
  }
  .nav-items-mobile {
    position: absolute;
    list-style: none;
    background: #ffff;
    top: 46px;
    height: max-content;
    padding: 1rem 0rem;
    z-index: 2;
    left: 0;
    transition: all 0.5s ease-out;
    width: 100%;
    color: black;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  .expertise-cards-container {
    position: static !important;
  }
  .mobile-menu-icon {
    display: block;
    position: absolute;
    background: none;
    font-size: 30px;
    padding: 3px;
    border: none;
    outline: none;
    right: 25px;
  }
  .mobile-menu-icon img {
    width: 48px !important;
    height: 48px !important;
  }
  .home,
  .casestudy,
  .services,
  .contactus,
  .aboutus,
  .ourvalues,
  .leadership,
  .careers,
  .login {
    padding: 10px;
    text-align: center;
    transition: all 0.5s ease;
    font-family: "POPPINS-REGULAR";
  }

  //home style
  .bold-common-text {
    font-size: 24px !important;
  }

  .regular-common-text {
    font-size: 15px !important;
  }

  .semibold-commontext {
    font-size: 20px !important;
  }
  .semibold-folder-text {
    font-size: 14px !important;
    padding: 10px !important;
  }
  .semibold-smalltext {
    font-size: 16px !important;
  }

  .selected-nav-element,
  .nav-elements {
    font-size: 16px !important;
  }

  .smarttrak-logo {
    width: 160px !important;
    height: 48px !important;
  }

  .login-btn {
    font-size: 16px !important;
    border-radius: 22px !important;
    padding: 10px 40px !important;
  }
  .demo_btn{
    font-size: 16px !important;
    border-radius: 15px !important;
    padding: 10px 40px !important;
  }

  #common-btn {
    font-size: 16px !important;
    border-radius: 51px !important;
    padding: 8px 20px !important;
    width: fit-content !important;
  }
  .video-container{
width: 90% !important;
  }

  .purple-star {
    width: 10px !important;
  }
  #small-star {
    width: 15px !important;
  }
  #big-star {
    width: 30px !important;
  }
  .purple-star-container {
    margin-bottom: -10px !important;
  }

  .landing-heading {
    font-size: 21px !important;
    position: relative;
    z-index: 1;
  }

  .big-heading {
    font-size: 30px !important;
  }

  .smaller-crossed-text {
    font-size: 30px !important;
  }

  .hand-shake-conatiner {
    position: relative !important;
  }

  .hand-shake {
    position: absolute !important;
    width: 200px !important;
    top: 30px !important;
  }

  .hand-bulb {
    position: absolute !important;
    width: 200px !important;
    top: -29px !important;
  }

  .hand-bulb-container {
    position: absolute !important;
    bottom: 123px !important;
  }

  .line-draw {
    right: 9vw !important;
    height: 34vh !important;
  }

  .laptop {
    width: 328px !important;
  }

  .dashboard-lap {
    width: 260px !important;
    height: 156px !important;
  }

  .dash-lap-container {
    top: auto !important;
  }

  .draw-line2 {
    width: auto !important;
    top: 24vh !important;
  }

  .video {
    border-radius: 32px !important;
  }

  .lap-container {
    top: -6vh !important;
  }

  .star-container {
    top: 6vh !important;
    right: -61vw !important;
  }

  .star-box {
    right: -42.5vw !important;
    top: 3vh !important;
  }

  .expertise-heading {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .service-heading {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  #expertise-description {
    width: auto !important;
  }

  .our-expertise-container {
    padding: 24px 10px !important;
    border-radius: 30px !important;
  }

  .expertise-cards {
    padding: 20px !important;
  }

  .expertise-box {
    margin-top: 3rem !important;
  }

  .rotate-on-scroll {
    width: 70px !important;
    height: 70px !important;
    margin-top: 0vh !important;
    margin-right: 0vw !important;
  }

  .case-arrow {
    position: absolute !important;
    bottom: 5vh !important;
    right: 4vw !important;
  }

  .rotate-arrow-width {
    width: 70px !important;
    height: 70px !important;
  }

  .circle-arrow {
    bottom: 6vh !important;
    right: 11vw !important;
    top: auto !important;
  }

  .hover-svg {
    width: 20px !important;
  }

  .expertise-grid {
    gap: 15px !important;
  }

  .folder {
    width: 166px !important;
  }

  .folder-container {
    gap: 10px !important;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }

  .img-three-container {
    top: 2vh !important;
    right: 39vw !important;
  }

  .img-three {
    width: 73px !important;
  }

  .img-one-container {
    top: -8vh !important;
    left: 3vw !important;
  }

  .img-one {
    width: 61px !important;
  }

  .img-two-container {
    top: 26vh !important;
    right: -43.5vw !important;
  }

  .img-two {
    width: 37px !important;
  }

  .pscroll {
    font-size: 14px !important;
  }

  .circle-arrow-container {
    right: 11.5vw !important;
  }

  .product-container {
    padding: 5px 20px;
  }

  .pro-subcontainer {
    border-radius: 20px !important;
  }

  #cards {
    display: flex !important;
    overflow-x: auto !important;
    --cardTopPadding: auto !important;
  }

  .cards-main-container {
    flex-direction: row !important;
    overflow-x: auto !important;
  }

  .cards-container {
    width: auto !important;
    overflow-x: auto !important;
  }

  .card-body {
    flex-direction: column !important;
    width: auto !important;
    height: auto !important;
    padding: 17px !important;
    border-radius: 20px 20px 120px 20px !important;
    justify-content: flex-start !important;
  }

  #width-auto {
    width: 81% !important;
  }

  .case-studies {
    padding: 24px 10px !important;
    border-radius: 30px !important;
  }

  .cardimg-three,
  .cardimg-two,
  .cardimg-one {
    border-radius: 12px !important;
    width: 267px !important;
    height: 156px !important;
  }
  .folder-text {
    font-size: 12px !important;
    height: 93px;
  }
  .view-all {
    margin-top: 30px !important;
  }

  .testimonial-subcontainer {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
  }

  .testimonials-container {
    overflow-y: auto !important;
    padding: 0px !important;
    gap: 10px !important;
  }

  .testimonials-arrow {
    display: none !important;
  }

  .testimonial-paper {
    width: 76vw !important;
    border-radius: 20px 20px 75px 20px !important;
    padding: 20px !important;
  }

  .profile-pic {
    border-radius: 15px !important;
    width: 75px !important;
  }

  .partners-img {
    width: 45px !important;
  }

  .subscribe-news {
    padding: 30px 15px !important;
    border-radius: 30px !important;
  }

  .submit-box {
    border-radius: 15px 15px 75px 15px !important;
    padding: 4vh !important;
  }

  .submit-btn {
    width: auto !important;
    border-radius: 18px 18px 51px 18px !important;
    padding: 10px !important;
    gap: 10px;
    flex-direction: column;
  }
  #email-input {
    width: 63vw !important;
  }
  .marque-main {
    margin: 30px 0px !important;
  }

  .product-linedraw-2 {
    display: none !important;
  }

  .footer-container {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 20px !important;
  }
  .footer-logo {
    width: 154px !important;
  }
  .footer-social {
    width: 37px !important;
  }
  .nav-stuff {
    padding-left: 0vw !important;
  }
  .footer-svg {
    width: 20px !important;
  }
  .purple-star-container {
    display: none !important;
  }
  .selected-nav-element {
    align-self: flex-start !important;
    padding: 0rem 1rem !important;
    flex-direction: row !important;
  }
  #composition-button svg {
    margin: 0rem 1rem !important;
  }
  #composition-button {
    display: flex;
    align-items: initial;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  .nav-elements {
    display: flex !important;
    align-self: flex-start !important;
    padding: 0rem 1rem !important;
  }
 
}

//only xs screen
@media screen and (min-width: 340px) and (max-width: 601px) {
  .media_width_auto{
    width: 100% !important;
  }
  .timeframe_cal{
    min-width: 10rem !important;
  }
  #equipment_width{
    width: 100%;
  }
  #justify_ends{
    justify-content: flex-start !important;
  }
  .search-box {
    border-radius: 1rem !important;
    padding: 0.625rem !important;
    height: 8vh !important;
    width: 90% !important;
  }
  .alert_style{
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 90% !important;
    
  }
 .core_top{
  margin-top: 1rem !important;
 }
  #overlay_c{
    top: 55% !important;
  }
  .dot {
    width: 0.9587rem;
    height: 1rem;
    margin: 0 0.25rem;

    border: 0.09585rem solid var(--black);
  }
  #margin_b{
    margin-bottom: 1rem !important;
  
  }
  .contact-btn {
    width: 56% !important;
    margin: 0rem 1rem !important;
  }
  .expertise-cards-container .regular-common-text {
    height: auto !important;
  }
  .expertise-cards {
    border-radius: 1rem 1rem 5rem 5rem !important;
  }
  .expertise_image {
    border-radius: 1rem !important;
    height: auto !important;
  }
  .left-arrow img,
  .right-arrow img {
    display: none !important;;
  }
  .sol_sec{
    width: 100% !important;
  }
  .div_img_height{
    height: 100% !important;
  }
  .home_grid_product.active,
  .home_grid_product.inactive {
    transform: none !important;
    filter: none !important;
    opacity: 1 !important;
    z-index: auto !important;
  }
  .flex_crct{
    
    flex-direction: column !important;
  }
  .flex_reverse{
    
    flex-direction: column-reverse !important;
  }
  .foo_des{
    width: auto !important;
    }
    .footer-container {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 20px !important;
    }
    .sol_sec_c{
      width: 100% !important;
    }
    #battery_width{
      width: 100%;
    }
}

@media screen and (min-width: 600px) and (max-width: 990px) {
  .search-box{
    border-radius: 1rem !important;
    padding: 0.625rem !important;
    width: 90% !important;
    height: fit-content !important;
  }
  #dotWhite{
    width: 0.5rem !important;
    height: 0.6rem !important;
  }
  #dotWhite.active {
    width: 1.9rem !important; /* Width when active */
    height: 0.4rem !important; /* Height when active */
  }
  .home_paper_casestudy{
    .Raleway_31px_600 {
      font-size: 0.75rem !important;
    }
  }

  #margin_8{
    margin-right: 35vw !important;
  }
  .contact-btn {
    width: 37% !important;
    margin: 0rem 1rem !important;
  }
  #case_imges{
    width: 20rem !important;
  }
  .sol_sec{
    width:50% !important;
  }
  .sol_sec_c{
    width:100% !important;


  }

}
@media screen and (min-width: 991px) and (max-width: 1338px) {
 
  .contact-btn {
    width: 54% !important;
  }
  .overlay {
    top: 40% !important;
  }
  .stack_progess {
    margin-top: -11% !important;
  }
}
//mobile xs to sm screen
@media screen and (min-width: 340px) and (max-width: 992px) {
 
  :where(media-community-skin[data-video][data-mobile] media-play-button){
    width: var(--video-mobile-play-button-size, 48px) !important;
    height: var(--video-mobile-play-button-size, 48px) !important; 
    background-color: var(--video-mobile-play-button-bg, #B0B0B3) !important;
  }
  :where(media-community-skin[data-video]) {
    --media-controls-color: var(--video-controls-color, #1C1C1D) !important;
  }
  .css-1be0hn1-JoyLinearProgress-root {
    --LinearProgress-thickness: 13px !important;
  }
  .react-tel-input .form-control {
    font-size: 0.75rem !important;
  }
  .raleway_64px_rem {
    font-size: 1.5rem;
  }
  .privacy_text {
    text-align: left !important;
  }
  .privacydata {
    margin: 3rem 1rem !important;
  }
  .raleway_20px_reg {
    font-size: 1rem;
  }
  .raleway_20px_semi {
    font-size: 1rem;
  }
  .raleway_pri_semi{
    font-size: 1rem;
  
  }
  .list_italic li {
    font-size: 1rem;
  }
  .slick-slide img {
    width: auto !important;
    height: 90vh !important;
  }
  .overlay {
    top: 47% !important;
    left: 50% !important;
    width: 90% !important;
  }

  .stack_progess {
    margin-top: -11% !important;
    left: 5% !important;
  }
  .progress-linewidth {
    width: 48px !important;
  }
  .service-img {
    width: 94vw !important;
    height: auto !important;
    border-radius: 22.225px 22.225px 129.643px 22.225px !important;
  }
  .fault-container {
    flex-direction: column !important;
  }

  .ourservice-container {
    border-radius: 22.225px 22.225px 129.643px 22.225px !important;
    height: auto !important;
  }
 
  .progress-linewidth p {
    display: none !important;
  }
 
  .left-arrow img,
  .right-arrow img {
    width: 2rem !important;
  }

}
//medium screen
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .slick-slide img {
    // width: auto !important;
    height: 100% !important;
  }
}
//macbook media query
@media screen and (width: 1920px) and (height: 1080px) {
  .dash-lap-container {
    top: 4vh !important;
  }

  #dashboard-img {
    height: 58vh !important;
  }

  #home-circle {
    top: 50vh !important;
  }

  #dashboard-img2 {
    height: 28vh !important;
  }

  .dash-lap {
    height: 52vh !important;
  }

  .card-body {
    height: auto !important;
  }

  .about-draw-line-two {
    top: -64.5vh !important;
  }
  #card-arrow-one {
    margin-top: 27vh !important;
  }
  #card-arrow-two {
    margin-top: 26vh !important;
  }
  #card-arrow-three {
    margin-top: 25vh !important;
  }
}

@media screen and (min-width: 778px) {
  .react-tel-input .form-control {
    font-size: 1vw !important;
  }
  .folder-container {
    gap: 10px !important;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
.privacy_link_foo {
  text-decoration: none !important;
  color: rgb(217, 217, 217) !important;
}
